<template>
 <div class="widget-section">
   <!-- agent-added-section start -->
   <div class="global-configuration-section mb-30">
     <div class="container-fluid">
       <div class="row">
         <div class="col-xl-12">
           <div class="admin-card-wrap">
             <div class="admin-card-heading config-heading">
               <h5 class="title">新增代理商账户 <span><b>*</b> 为必填项</span></h5>
             </div>
             <div class="admin-card-body">
               <div class="agent-added-form">
                 <div class="row justify-content-center mb-30">
                   <div class="col-xl-10">
                     <h3 class="title">基本信息</h3>
                   </div>
                 </div>
                 <div class="row justify-content-center">
                   <div class="col-xl-6">
                     <div class="g-input-group">
                       <label for="input-1"><span>*</span> 代理账号:</label>
                       <div class="input-wrap">
                         <input type="text" id="input-1" placeholder="代理账号">
                       </div>
                     </div>
                     <div class="g-input-group">
                       <label for="input-2"><span>*</span> 登录密码:</label>
                       <div class="input-wrap">
                         <input type="text" id="input-2" placeholder="登录密码">
                       </div>
                     </div>
                     <div class="g-input-group">
                       <label for="input-3"><span>*</span> 确认密码:</label>
                       <div class="input-wrap">
                         <input type="text" id="input-3" placeholder="确认密码">
                       </div>
                     </div>
                     <div class="g-input-group">
                       <label for="input-4"><span>*</span> 渠道名称:</label>
                       <div class="input-wrap">
                         <input type="text" id="input-4" placeholder="渠道名称">
                       </div>
                     </div>
                     <div class="g-input-group">
                       <label for="input-5"><span>*</span> 结算比例:</label>
                       <div class="input-wrap">
                         <input type="text" id="input-5" placeholder="结算比例">
                       </div>
                     </div>
                     <div class="g-input-group">
                       <label for="input-6"><span>*</span> 收款地址:</label>
                       <div class="input-wrap">
                         <input type="text" id="input-6" placeholder="收款地址">
                       </div>
                     </div>
                     <div class="g-button-group">
                       <button class="g-primary">提交</button>
                       <button class="g-danger">重置</button>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   <!-- agent-added-section end -->
 </div>
</template>

<script>
export default {
  name: "AgentAdded"
}
</script>

<style scoped>

</style>
