<template>
  <div class="widget-section">

    <!-- ticket-list-section start -->
    <div class="ticket-list-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h4 class="title">工单管理</h4>
              </div>
              <div class="admin-card-body">
                <div class="ticket-list-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="email">邮箱</th>
                        <th scope="col" class="content">工单内容</th>
                        <th scope="col" class="clint">提交客户端</th>
                        <th scope="col" class="software">提交软件版本号</th>
                        <th scope="col" class="status">状态</th>
                        <th scope="col" class="s-time">提交时间</th>
                        <th scope="col" class="p-time">处理时间</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text success">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn success" v-b-modal:order-message-replay-done>
                            <i class="fa-regular fa-envelope"></i> 已处理
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text danger">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn danger" v-b-modal:order-message-replay>
                            <i class="fa-regular fa-pencil"></i> 待处理
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text danger">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn danger" v-b-modal:order-message-replay>
                            <i class="fa-regular fa-pencil"></i> 待处理
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text danger">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn danger" v-b-modal:order-message-replay>
                            <i class="fa-regular fa-pencil"></i> 待处理
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text danger">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn danger" v-b-modal:order-message-replay>
                            <i class="fa-regular fa-pencil"></i> 待处理
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text danger">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn danger" v-b-modal:order-message-replay>
                            <i class="fa-regular fa-pencil"></i> 待处理
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text danger">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn danger" v-b-modal:order-message-replay>
                            <i class="fa-regular fa-pencil"></i> 待处理
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text danger">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn danger" v-b-modal:order-message-replay>
                            <i class="fa-regular fa-pencil"></i> 待处理
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text danger">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn danger" v-b-modal:order-message-replay>
                            <i class="fa-regular fa-pencil"></i> 待处理
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>sadsafg@gmail.com</td>
                        <td>已经购买了，但是服务没有生效，我已经很着急了........</td>
                        <td>HuaWei Android</td>
                        <td>Android V1.1.3</td>
                        <td>
                          <span class="status-text danger">完成</span>
                        </td>
                        <td>2020-11-25 23:26:08</td>
                        <td>2020-11-25 23:26:08</td>
                        <td>
                          <button class="action-btn danger" v-b-modal:order-message-replay>
                            <i class="fa-regular fa-pencil"></i> 待处理
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="daily-table-pagination mt-30 mb-20">
                      <ul>
                        <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><span>...</span></li>
                        <li><a href="#">50</a></li>
                        <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                      </ul>
                      <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                        <select>
                          <option value="10">10 / Page</option>
                          <option value="10">20 / Page</option>
                          <option value="10">30 / Page</option>
                          <option value="10">40 / Page</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OrderMessageReplayDone/>
        <OrderMessageReplay/>
      </div>
    </div>
    <!-- ticket-list-section end -->

  </div>
</template>

<script>
import OrderMessageReplayDone from "@/components/Modals/OrderMessageReplayDone";
import OrderMessageReplay from "@/components/Modals/OrderMessageReplay";

export default {
  name: "TicketList",
  components: {OrderMessageReplay, OrderMessageReplayDone}
}
</script>

<style scoped>

</style>
