<template>
  <transition>
    <b-modal
        id="announcement-details"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="order-message-modal"
        hide-footer
        centered
    >
      <template #modal-header="{ close }">
        <h5>公告详情</h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="announcement-details">
          <div class="title">
            announcement title
          </div>
          <div class="meta">
            <span>发布人员：<b>admin</b></span>
            <span>发布时间：<b>2020-12-23 22:31</b></span>
            <span>接收人员：<b>免费用户</b></span>
            <span>浏览次数：999</span>
            <span>评论数量：999</span>
          </div>
          <div class="content">
            <p>
              announcement titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannounceme
            </p>
            <p>
              announcement titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannouncement titleannouncement
              titleannouncement titleannouncement titleannouncement titleannounceme
            </p>
          </div>
        </div>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "AnnouncementDetails",
  mixins: [toaster],
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
