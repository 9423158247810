<template>
  <div class="footer-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="copyright-text">
            <p>Copyright © www.google.com, All Rights Reserved.</p>
            <p>Google company</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer"
}
</script>

<style scoped>

</style>
