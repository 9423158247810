<template>
  <transition>
    <b-modal
        id="node-list-key-success"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal"
        centered
    >
      <template #modal-header="{ close }">
        <h5>操作确认</h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="online-box-info px-3 py-3">
          <h5><i class="fa-regular fa-circle-exclamation success"></i> abcdfefdsfdssdfsagdfsa</h5>
        </div>
      </template>

      <template #modal-footer="{ hide }">
        <b-button variant="modal-f-primary" @click="hide()">
          确定
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
export default {
  name: "NodeListKeyBox",
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
