<template>
  <div class="inner-header">
    <!-- header start -->
    <div class="header-area">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-4 col-6 d-block d-xl-none">
            <div class="d-flex align-items-center">
              <!-- h-logo start -->
              <div class="h-logo">
                <router-link to="/">
                  <img src="@/assets/img/logo.svg" alt="logo">
                </router-link>
              </div>
              <!-- h-logo end -->

              <!-- h-menu-open start -->
              <div class="h-menu-open">
                <button @click="$emit('openSidebar')">
                  <i class="fa-solid fa-bars-staggered"></i>
                </button>
              </div>
              <!-- h-menu-open end -->
            </div>
          </div>
          <div class="col-xl-12 col-lg-8 col-md-8 col-6 text-right">
            <!-- h-dropdown-wrap-notification start -->
            <div class="h-dropdown-wrap notification">
              <a href="#">
                <i class="fa-regular fa-volume"></i>
                <span class="text">Pending Tickets</span>
                <span class="notify">2</span>
              </a>
              <div class="h-dropdown">
                <router-link :to="{ name: 'WorkOrderProcessing'}">
                  <i class="fa-regular fa-envelope"></i> Pending work order
                  <span class="notify">2</span>
                </router-link>
              </div>
            </div>
            <!-- h-dropdown-wrap-notification end -->

            <!-- h-dropdown-wrap start -->
            <div class="h-dropdown-wrap">
              <a href="#">
                <i class="fa-solid fa-circle-user"></i> <span class="text">Super administrator</span>
              </a>
              <div class="h-dropdown">
                <router-link :to="{name : 'LoginRecord'}">
                  <i class="fa-regular fa-shield-check"></i> change Password
                </router-link>
                <router-link :to="{name : 'LoginRecord'}">
                  <i class="fa-regular fa-user"></i> registration record
                </router-link>
                <router-link :to="{name : 'AdminLogin'}">
                  <i class="fa-regular fa-power-off"></i> Exit system
                </router-link>
              </div>
            </div>
            <!-- h-dropdown-wrap end -->
          </div>
        </div>
      </div>
    </div>
    <!-- header end -->

    <!-- breadcrumbs start -->
    <div class="breadcrumbs-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="breadcrumbs-wrap">
              <ul>
                <li>
                  <router-link to="#">
                    <i class="fa-solid fa-house-chimney"></i> System Home
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumbs end -->
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  data() {
    return {}
  },
}
</script>

<style scoped>

</style>
