<template>
  <div class="widget-section">
    <!-- announcement-list-section start -->
    <div class="member-list-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">版本管理</h5>
              </div>
              <div class="admin-card-body">
                <div class="member-list-btn">
                  <button class="c-add-btn" v-b-modal:software-add>
                    <i class="fa-solid fa-plus"></i> 发布新版本
                  </button>
                </div>
                <div class="softer-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="os">上架市场</th>
                        <th scope="col" class="type">软件版本号</th>
                        <th scope="col" class="title">下载链接</th>
                        <th scope="col" class="content">版本说明</th>
                        <th scope="col" class="date">版本发布时间</th>
                        <th scope="col" class="publisher">发布人员</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="os">Windwos</td>
                        <td colspan="type">v1.2.16</td>
                        <td class="title">https://www.apple.com/app/1.0</td>
                        <td colspan="content">
                          Because apple's privacy rules in the US are more detailed, they need to be updated
                        </td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:software-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:software-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="os">Windwos</td>
                        <td colspan="type">v1.2.16</td>
                        <td class="title">https://www.apple.com/app/1.0</td>
                        <td colspan="content">
                          Because apple's privacy rules in the US are more detailed, they need to be updated
                        </td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:software-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:software-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="os">Windwos</td>
                        <td colspan="type">v1.2.16</td>
                        <td class="title">https://www.apple.com/app/1.0</td>
                        <td colspan="content">
                          Because apple's privacy rules in the US are more detailed, they need to be updated
                        </td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:software-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:software-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="os">Windwos</td>
                        <td colspan="type">v1.2.16</td>
                        <td class="title">https://www.apple.com/app/1.0</td>
                        <td colspan="content">
                          Because apple's privacy rules in the US are more detailed, they need to be updated
                        </td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:software-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:software-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="os">Windwos</td>
                        <td colspan="type">v1.2.16</td>
                        <td class="title">https://www.apple.com/app/1.0</td>
                        <td colspan="content">
                          Because apple's privacy rules in the US are more detailed, they need to be updated
                        </td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:software-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:software-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="os">Windwos</td>
                        <td colspan="type">v1.2.16</td>
                        <td class="title">https://www.apple.com/app/1.0</td>
                        <td colspan="content">
                          Because apple's privacy rules in the US are more detailed, they need to be updated
                        </td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:software-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:software-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="os">Windwos</td>
                        <td colspan="type">v1.2.16</td>
                        <td class="title">https://www.apple.com/app/1.0</td>
                        <td colspan="content">
                          Because apple's privacy rules in the US are more detailed, they need to be updated
                        </td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:software-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:software-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- announcement-list-section end -->

    <SoftwareAdd/>
    <SoftwareDelete/>
    <SoftwareDetails/>
  </div>
</template>

<script>
import SoftwareDelete from "@/components/Modals/SoftwareDelete";
import SoftwareDetails from "@/components/Modals/SoftwareDetails";
import SoftwareAdd from "@/components/Modals/SoftwareAdd";

export default {
  name: "SoftwareVersion",
  components: {SoftwareAdd, SoftwareDetails, SoftwareDelete}
}
</script>

<style scoped>

</style>
