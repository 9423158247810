<template>
  <div class="widget-section">
    <!-- member-order-section start -->
    <div class="member-order-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">代理会员订单</h5>
              </div>
              <div class="admin-card-body">
                <div class="member-order-btn">
                  <div class="select-wrap">
                    <select>
                      <option value="0">渠道名称1</option>
                      <option value="0">渠道名称2</option>
                      <option value="0">渠道名称3</option>
                      <option value="0">渠道名称4</option>
                    </select>
                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>
                  </div>
                  <div class="select-wrap">
                    <select>
                      <option value="0">已付款</option>
                      <option value="0">未付款</option>
                    </select>
                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>
                  </div>
                  <div class="select-wrap">
                    <select>
                      <option value="0">信用卡</option>
                      <option value="0">PayPal</option>
                      <option value="0">虚拟货币</option>
                      <option value="0">支付宝</option>
                    </select>
                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>
                  </div>
                  <div class="select-wrap">
                    <select>
                      <option value="0">iOS APP</option>
                      <option value="0">Android</option>
                      <option value="0">HTTP Web</option>
                    </select>
                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>
                  </div>

                  <div class="input-wrap">
                    <div class="date-picker">
                      <date-range-picker
                          v-model="dateRange"
                          :locale-data="{
                        direction: 'ltr',
                        format: 'yyyy/mm/dd',
                        separator: ' - ',
                        applyLabel: 'Apply',
                        cancelLabel: 'Cancel',
                        weekLabel: 'W',
                        customRangeLabel: 'Custom Range',
                        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 0,}"
                          :show-week-numbers="false"
                          :append-to-body="true"
                          :ranges="false">
                      </date-range-picker>
                    </div>
                  </div>

                  <button class="c-add-btn" v-b-modal:member-add>
                    <i class="fa-regular fa-magnifying-glass"></i> 查询
                  </button>
                  <button class="c-cancel-btn">
                    <i class="fa-solid fa-flip-horizontal fa-arrow-rotate-right"></i> 重置
                  </button>
                </div>
                <div class="member-order-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="check"><input type="checkbox"></th>
                        <th scope="col" class="serial">订单编号</th>
                        <th scope="col" class="price">订单金额</th>
                        <th scope="col" class="status">订单状态</th>
                        <th scope="col" class="method">支付方式</th>
                        <th scope="col" class="source">订单来源</th>
                        <th scope="col" class="email">用户账号</th>
                        <th scope="col" class="agent">代理商</th>
                        <th scope="col" class="s-title">订单提交时间</th>
                        <th scope="col" class="p-time">订单支付时间</th>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">20206297662100</td>
                        <td class="price">$9.9</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                        </td>
                        <td colspan="method">PayPal</td>
                        <td colspan="source">iOS APP</td>
                        <td colspan="email">Windir@gmail.com</td>
                        <td class="agent">youtube</td>
                        <td class="s-title">2020-11-25 23:26:08</td>
                        <td class="p-time">2020-11-25 23:26:08</td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">20206297662100</td>
                        <td class="price">$9.9</td>
                        <td class="status">
                          <div class="status-text warning"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                        </td>
                        <td colspan="method">PayPal</td>
                        <td colspan="source">iOS APP</td>
                        <td colspan="email">Windir@gmail.com</td>
                        <td class="agent">youtube</td>
                        <td class="s-title">2020-11-25 23:26:08</td>
                        <td class="p-time">2020-11-25 23:26:08</td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">20206297662100</td>
                        <td class="price">$9.9</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                        </td>
                        <td colspan="method">PayPal</td>
                        <td colspan="source">iOS APP</td>
                        <td colspan="email">Windir@gmail.com</td>
                        <td class="agent">youtube</td>
                        <td class="s-title">2020-11-25 23:26:08</td>
                        <td class="p-time">2020-11-25 23:26:08</td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">20206297662100</td>
                        <td class="price">$9.9</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                        </td>
                        <td colspan="method">PayPal</td>
                        <td colspan="source">iOS APP</td>
                        <td colspan="email">Windir@gmail.com</td>
                        <td class="agent">youtube</td>
                        <td class="s-title">2020-11-25 23:26:08</td>
                        <td class="p-time">2020-11-25 23:26:08</td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">20206297662100</td>
                        <td class="price">$9.9</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                        </td>
                        <td colspan="method">PayPal</td>
                        <td colspan="source">iOS APP</td>
                        <td colspan="email">Windir@gmail.com</td>
                        <td class="agent">youtube</td>
                        <td class="s-title">2020-11-25 23:26:08</td>
                        <td class="p-time">2020-11-25 23:26:08</td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">20206297662100</td>
                        <td class="price">$9.9</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                        </td>
                        <td colspan="method">PayPal</td>
                        <td colspan="source">iOS APP</td>
                        <td colspan="email">Windir@gmail.com</td>
                        <td class="agent">youtube</td>
                        <td class="s-title">2020-11-25 23:26:08</td>
                        <td class="p-time">2020-11-25 23:26:08</td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">20206297662100</td>
                        <td class="price">$9.9</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                        </td>
                        <td colspan="method">PayPal</td>
                        <td colspan="source">iOS APP</td>
                        <td colspan="email">Windir@gmail.com</td>
                        <td class="agent">youtube</td>
                        <td class="s-title">2020-11-25 23:26:08</td>
                        <td class="p-time">2020-11-25 23:26:08</td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">20206297662100</td>
                        <td class="price">$9.9</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                        </td>
                        <td colspan="method">PayPal</td>
                        <td colspan="source">iOS APP</td>
                        <td colspan="email">Windir@gmail.com</td>
                        <td class="agent">youtube</td>
                        <td class="s-title">2020-11-25 23:26:08</td>
                        <td class="p-time">2020-11-25 23:26:08</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- member-order-section end -->
  </div>
</template>

<script>
export default {
  name: "MemberOrder",
  data() {
    return {
      dateRange: {
        startDate: '',
        endDate: ''
      },
    }
  }
}
</script>

<style scoped>

</style>
