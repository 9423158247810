<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  data(){
    return {

    }
  },
  methods: {


  },
  components: {}
}
</script>


<style lang="scss">


</style>
