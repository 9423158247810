import { render, staticRenderFns } from "./TicketList.vue?vue&type=template&id=870d4426&scoped=true&"
import script from "./TicketList.vue?vue&type=script&lang=js&"
export * from "./TicketList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "870d4426",
  null
  
)

export default component.exports