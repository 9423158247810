<template>
  <transition>
    <b-modal
        id="blocking-rules-add"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal-large"
        centered
    >
      <template #modal-header="{ close }">
        <h5>编辑节点信息 <span><b>*</b> 为必填项</span></h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="blocking-rules-form-wrap">
          <form action="#">
            <div class="row">
              <div class="col-xl-12">
                <div class="c-input-group">
                  <label for="id"><span>*</span> 规则名称:</label>
                  <div class="input-wrap">
                    <input type="text" id="id" placeholder="规则名称">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="name"><span>*</span> 规则说明:</label>
                  <div class="input-wrap">
                    <input type="text" id="name" placeholder="规则说明">
                  </div>
                </div>
                <div class="c-input-group">
                  <label><span>*</span> 规则类型:</label>
                  <div class="select-wrap">
                    <select>
                      <option value="0">规则类型</option>
                      <option value="0">规则类型</option>
                      <option value="0">规则类型</option>
                      <option value="0">规则类型</option>
                    </select>
                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>
                  </div>
                </div>
                <div class="c-input-group align-items-start">
                  <label for="name"><span>*</span> 规则说明:</label>
                  <div class="input-wrap">
                    <textarea placeholder="规则内容"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <b-button variant="modal-f-primary" @click="hide();showSuccessMessage('服务器添加成功', '服务器添加成功')">
          确定
        </b-button>

        <b-button variant="modal-f-cancel mr-10" @click="cancel()">
          取消
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "BlockingRulesAdd",
  mixins: [toaster],
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
