<template>
  <div>
    <div class="sidebar-wrapper" :class="{active: isActive}">
      <!-- s-logo start -->
      <div class="s-logo">
        <router-link to="/">
          <img src="@/assets/img/logo.svg" alt="logo">
          <span>Admin Data Center</span>
        </router-link>
      </div>
      <!-- s-logo end -->

      <!-- sidebar-inner start -->
      <div class="sidebar-inner">
        <!-- main-menu start -->
        <div class="main-menu">
          <ul>
            <li class="has-dropdown" :class="{'active': isDashboardLinkActive || isWorkOrderProcessingLinkActive || isCloudServerLinkActive || isUserDistributionLinkActive || showShortCut}" >
              <a href="#" @click="toggleMenu">
                <img src="@/assets/img/icon/shorecut-menu.svg" alt="icon"> Shortcut menu
              </a>
              <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                <ul class="sub-menu" v-if="showShortCut">
                  <li>
                    <router-link :to="{name:'Dashboard'}" :class="{'active-class': isDashboardLinkActive}">Agent Admin</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'WorkOrderProcessing'}" :class="{'active-class': isWorkOrderProcessingLinkActive}">Work order processing</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'CloudServerOperationMonitoring'}" :class="{'active-class': isCloudServerLinkActive}">Cloud server operation monitoring
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'UserDistributionDataMonitoring'}" :class="{'active-class': isUserDistributionLinkActive}">User distribution data monitoring
                    </router-link>
                  </li>
                </ul>
              </transition>
            </li>
            <li class="has-dropdown" :class="{'active': isCountryListLinkActive || isNodeBlackListLinkActive || isNodeListLinkActive || isUserTriggeredLinkActive || isTrafficLogLinkActive || isBlockingRulesLinkActive ||isOnlineUserLinkActive}">
              <a href="#" @click="nodeToggleMenu">
                <img src="@/assets/img/icon/node-management.svg" alt="icon"> Node Management
              </a>
              <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                <ul class="sub-menu" v-if="showNode">
                  <li>
                    <router-link :to="{name:'CountryList'}" :class="{'active-class': isCountryListLinkActive}">Country list</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'NodeList'}" :class="{'active-class': isNodeListLinkActive}">Node list</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'OnlineUsersRealTime'}" :class="{'active-class': isOnlineUserLinkActive}">Online users - real time</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'TrafficLogToday'}" :class="{'active-class': isTrafficLogLinkActive}">Traffic Log - Today</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'BlockingRules'}" :class="{'active-class': isBlockingRulesLinkActive}">Blocking rules</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'NodeBlacklist'}" :class="{'active-class': isNodeBlackListLinkActive}">Node blacklist</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'UserWhoTriggeredBlockingRule'}" :class="{'active-class': isUserTriggeredLinkActive}">User who triggered blocking rule
                    </router-link>
                  </li>
                </ul>
              </transition>
            </li>
            <li class="has-dropdown" :class="{'active': isProductListLinkActive}">
              <a href="#" @click="toggleCommodityMenu">
                <img src="@/assets/img/icon/commndity-management.svg" alt="icon"> Commodity Management
              </a>
              <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                <ul class="sub-menu" v-if="showProductList">
                  <li>
                    <router-link :to="{name:'ProductList'}" :class="{'active-class': isProductListLinkActive}">Product List</router-link>
                  </li>
                </ul>
              </transition>
            </li>
            <li class="has-dropdown" :class="{'active': isGlobalStatLinkActive || isAgentListLinkActive || isAgentAddLinkActive || isRegisteredUserLinkActive || isMemberOrderLinkActive || isCommissionLinkActive || isAccountSettingLinkActive}">
              <a href="#" @click="toggleAgentManagementMenu">
                <img src="@/assets/img/icon/agent-management.svg" alt="icon"> Agent Management
              </a>
              <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                <ul class="sub-menu" v-if="showAgentManagement">
                  <li>
                    <router-link :to="{name:'GlobalStatistics'}" :class="{'active-class': isGlobalStatLinkActive}">Global Statistics</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'MemberOrder'}" :class="{'active-class': isMemberOrderLinkActive}">Member Order</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'RegisteredUser'}" :class="{'active-class': isRegisteredUserLinkActive}">registered User</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'CommissionSettlement'}" :class="{'active-class': isCommissionLinkActive}">Commission Settlement - To Be Improved
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'AgentList'}" :class="{'active-class': isAgentListLinkActive}">Agent List</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'AgentAdded'}" :class="{'active-class': isAgentAddLinkActive}">Agent Added</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'AccountSettings'}" :class="{'active-class': isAccountSettingLinkActive}">Account Settings</router-link>
                  </li>
                </ul>
              </transition>
            </li>
            <li class="has-dropdown" :class="{'active': isMemberListLinkActive || isOrderListLinkActive || isTicketListLinkActive || isAnnouncementListLinkActive}">
              <a href="#" @click="toggleOperationMenu">
                <img src="@/assets/img/icon/operation-management.svg" alt="icon"> Operation Management
              </a>
              <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                <ul class="sub-menu" v-if="showOperation">
                  <li>
                    <router-link :to="{name:'MemberList'}" :class="{'active-class': isMemberListLinkActive}">Member List</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'OrderList'}" :class="{'active-class': isOrderListLinkActive}">Order List</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'TicketList'}" :class="{'active-class': isTicketListLinkActive}">Ticket List</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'AnnouncementList'}" :class="{'active-class': isAnnouncementListLinkActive}">Announcement List</router-link>
                  </li>
                </ul>
              </transition>
            </li>
            <li class="has-dropdown" :class="{'active': isSoftwareVersionLinkActive || isGlobalConfigLinkActive || isWebOfficialLinkActive || isSuperAdminLinkActive}">
              <a href="#" @click="toggleSystemMenu">
                <img src="@/assets/img/icon/system-settings.svg" alt="icon"> System Settings
              </a>
              <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                <ul class="sub-menu" v-if="showSystem">
                  <li>
                    <router-link :to="{name:'SoftwareVersion'}" :class="{'active-class': isSoftwareVersionLinkActive}">Software version</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'GlobalConfiguration'}" :class="{'active-class': isGlobalConfigLinkActive}">Global configuration</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'WebOfficialConfiguration'}" :class="{'active-class': isWebOfficialLinkActive}">Web official configuration</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'SuperAdministratorAccount'}" :class="{'active-class': isSuperAdminLinkActive}">super administrator account</router-link>
                  </li>
                </ul>
              </transition>
            </li>
            <li class="has-dropdown" :class="{'active': isChangePasswordLinkActive || isLoginLogLinkActive}">
              <a href="#" @click="togglePasswordMenu">
                <img src="@/assets/img/icon/system-settings.svg" alt="icon"> Change password
              </a>
              <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
                <ul class="sub-menu" v-if="showChangePassword">
                  <li>
                    <router-link :to="{name : 'LoginRecord', params: {id: 'change-password'}}" :class="{'active-class': isChangePasswordLinkActive}">
                      <i class="fa-regular fa-shield-check"></i> change Password
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{name : 'LoginRecord', params: {id: 'login-log'}}" :class="{'active-class': isLoginLogLinkActive}">
                      <i class="fa-regular fa-user"></i> Login Log
                    </router-link>
                  </li>
                </ul>
              </transition>
            </li>
          </ul>
        </div>
        <!-- main-menu end -->
      </div>
      <!-- sidebar-inner end -->
    </div>
    <div class="sidebar-mask" :class="{active: isActive}" @click="$emit('closeSidebar')"></div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Sidebar",
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      menuOpen: '',
      showShortCut: false,
      showNode: false,
      showProductList: false,
      showAgentManagement: false,
      showOperation: false,
      showSystem: false,
      showChangePassword: false,
    }
  },
  computed:{
    isDashboardLinkActive(){
      if (this.$route.path === '/admin/dashboard'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showShortCut = true;
      }
      return this.$route.path === '/admin/dashboard';
    },
    isWorkOrderProcessingLinkActive(){
      if (this.$route.path === '/admin/work-order-processing'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showShortCut = true;
      }
      return this.$route.path === '/admin/work-order-processing';
    },
    isCloudServerLinkActive(){
      if (this.$route.path === '/admin/cloud-server-operation-monitoring'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showShortCut = true;
      }
      return this.$route.path === '/admin/cloud-server-operation-monitoring';
    },
    isUserDistributionLinkActive(){
      if (this.$route.path === '/admin/user-distribution-data-monitoringboardg'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showShortCut = true;
      }
      return this.$route.path === '/admin/user-distribution-data-monitoringboard';
    },
    isCountryListLinkActive(){
      if (this.$route.path === '/admin/country-list'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showNode = true;
      }
      return this.$route.path === '/admin/country-list';
    },
    isNodeListLinkActive(){
      if (this.$route.path === '/admin/node-list'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showNode = true;
      }
      return this.$route.path === '/admin/node-list';
    },
    isOnlineUserLinkActive(){
      if (this.$route.path === '/admin/online-users-real-time'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showNode = true;
      }
      return this.$route.path === '/admin/online-users-real-time';
    },
    isTrafficLogLinkActive(){
      if (this.$route.path === '/admin/traffic-log-today'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showNode = true;
      }
      return this.$route.path === '/admin/traffic-log-today';
    },
    isBlockingRulesLinkActive(){
      if (this.$route.path === '/admin/blocking-rules'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showNode = true;
      }
      return this.$route.path === '/admin/blocking-rules';
    },
    isNodeBlackListLinkActive(){
      if (this.$route.path === '/admin/node-blacklist'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showNode = true;
      }
      return this.$route.path === '/admin/node-blacklist';
    },
    isUserTriggeredLinkActive(){
      if (this.$route.path === '/admin/user-who-triggered-blocking-rule'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showNode = true;
      }
      return this.$route.path === '/admin/user-who-triggered-blocking-rule';
    },
    isProductListLinkActive(){
      if (this.$route.path === '/admin/product-list'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showProductList = true;
      }
      return this.$route.path === '/admin/product-list';
    },
    isGlobalStatLinkActive(){
      if (this.$route.path === '/admin/global-statistics'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showAgentManagement = true;
      }
      return this.$route.path === '/admin/global-statistics';
    },
    isAgentListLinkActive(){
      if (this.$route.path === '/admin/agent-list'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showAgentManagement = true;
      }
      return this.$route.path === '/admin/agent-list';
    },
    isMemberOrderLinkActive(){
      if (this.$route.path === '/admin/member-order'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showAgentManagement = true;
      }
      return this.$route.path === '/admin/member-order';
    },
    isRegisteredUserLinkActive(){
      if (this.$route.path === '/admin/registered-user'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showAgentManagement = true;
      }
      return this.$route.path === '/admin/registered-user';
    },
    isCommissionLinkActive(){
      if (this.$route.path === '/admin/commission-settlement'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showAgentManagement = true;
      }
      return this.$route.path === '/admin/commission-settlement';
    },
    isAgentAddLinkActive(){
      if (this.$route.path === '/admin/agent-added'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showAgentManagement = true;
      }
      return this.$route.path === '/admin/agent-added';
    },
    isAccountSettingLinkActive(){
      if (this.$route.path === '/admin/account-settings'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showAgentManagement = true;
      }
      return this.$route.path === '/admin/account-settings';
    },
    isMemberListLinkActive(){
      if (this.$route.path === '/admin/member-list'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showOperation = true;
      }
      return this.$route.path === '/admin/member-list';
    },
    isOrderListLinkActive(){
      if (this.$route.path === '/admin/order-list'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showOperation = true;
      }
      return this.$route.path === '/admin/order-list';
    },
    isTicketListLinkActive(){
      if (this.$route.path === '/admin/ticket-list'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showOperation = true;
      }
      return this.$route.path === '/admin/ticket-list';
    },
    isAnnouncementListLinkActive(){
      if (this.$route.path === '/admin/announcement-list'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showOperation = true;
      }
      return this.$route.path === '/admin/announcement-list';
    },
    isSoftwareVersionLinkActive(){
      if (this.$route.path === '/admin/software-version'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showSystem = true;
      }
      return this.$route.path === '/admin/software-version';
    },
    isGlobalConfigLinkActive(){
      if (this.$route.path === '/admin/global-configuration'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showSystem = true;
      }
      return this.$route.path === '/admin/global-configuration';
    },
    isWebOfficialLinkActive(){
      if (this.$route.path === '/admin/web-official-configuration'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showSystem = true;
      }
      return this.$route.path === '/admin/web-official-configuration';
    },
    isSuperAdminLinkActive(){
      if (this.$route.path === '/admin/super-administrator-account'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showSystem = true;
      }
      return this.$route.path === '/admin/super-administrator-account';
    },
    isChangePasswordLinkActive(){
      if (this.$route.path === '/admin/login-record/change-password'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showChangePassword = true;
      }
      return this.$route.path === '/admin/login-record/change-password';
    },
    isLoginLogLinkActive(){
      if (this.$route.path === '/admin/login-record/login-log'){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showChangePassword = true;
      }
      return this.$route.path === '/admin/login-record/login-log';
    }




  },
  methods: {
    // dropdown open close
    toggleMenu() {

      if (this.isUserDistributionLinkActive || this.isDashboardLinkActive || this.isCloudServerLinkActive || this.isWorkOrderProcessingLinkActive){
        this.showShortCut = true;


        this.showNode = false;
        this.showProductList = false;
        this.showAgentManagement = false;
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = false;
      }else{
        this.showNode = false;
        this.showProductList = false;
        this.showAgentManagement = false
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = false;

        this.showShortCut = !this.showShortCut;
      }
    },

    nodeToggleMenu(){
      if (this.isCountryListLinkActive || this.isNodeBlackListLinkActive || this.isTrafficLogLinkActive || this.isUserTriggeredLinkActive || this.isBlockingRulesLinkActive || this.isBlockingRulesLinkActive || this.isNodeListLinkActive){
        this.showNode = true;

        this.showShortCut = false;
        this.showProductList = false;
        this.showAgentManagement = false;
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = false;
      }else{
        this.showShortCut = false;
        this.showProductList = false;
        this.showAgentManagement = false;
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = false;

        this.showNode = !this.showNode;
      }
    },
    toggleCommodityMenu(){
      if (this.isProductListLinkActive){
        this.showNode = false;
        this.showShortCut = false;
        this.showAgentManagement = false;
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = false;
        this.showProductList = true;
      }else{
        this.showShortCut = false;
        this.showNode = false;
        this.showAgentManagement = false;
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = false;
        this.showProductList = !this.showProductList;
      }
    },
    toggleAgentManagementMenu(){
      if (this.isGlobalStatLinkActive || this.isMemberOrderLinkActive || this.isRegisteredUserLinkActive || this.isCommissionLinkActive || this.isAgentListLinkActive || this.isAgentAddLinkActive || this.isAccountSettingLinkActive){
        this.showNode = false;
        this.showShortCut = false;
        this.showProductList = false;
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = false;
        this.showAgentManagement = true;
      }else{
        this.showShortCut = false;
        this.showNode = false;
        this.showProductList = false;
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = false;
        this.showAgentManagement = !this.showAgentManagement;
      }
    },
    toggleOperationMenu(){
      if (this.isMemberListLinkActive || this.isOrderListLinkActive || this.isTicketListLinkActive || this.isAnnouncementListLinkActive){
        this.showNode = false;
        this.showShortCut = false;
        this.showProductList = false;
        this.showAgentManagement = false;
        this.showSystem = false;
        this.showChangePassword = false;
        this.showOperation = true;
      }else{
        this.showShortCut = false;
        this.showNode = false;
        this.showProductList = false;
        this.showAgentManagement = false;
        this.showAgentManagement = false;
        this.showSystem = false;
        this.showChangePassword = false;
        this.showOperation = !this.showOperation;
      }
    },
    toggleSystemMenu(){
      if (this.isSoftwareVersionLinkActive || this.isGlobalConfigLinkActive || this.isWebOfficialLinkActive || this.isSuperAdminLinkActive){
        this.showNode = false;
        this.showShortCut = false;
        this.showProductList = false;
        this.showAgentManagement = false;
        this.showOperation = false;
        this.showChangePassword = false;
        this.showSystem = true;
      }else{
        this.showShortCut = false;
        this.showNode = false;
        this.showProductList = false;
        this.showAgentManagement = false;
        this.showOperation = false;
        this.showChangePassword = false;
        this.showSystem = !this.showSystem;
      }
    },
    togglePasswordMenu(){
      if (this.isChangePasswordLinkActive || this.isLoginLogLinkActive){
        this.showNode = false;
        this.showShortCut = false;
        this.showProductList = false;
        this.showAgentManagement = false;
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = true;
      }else{
        this.showShortCut = false;
        this.showNode = false;
        this.showProductList = false;
        this.showAgentManagement = false;
        this.showOperation = false;
        this.showSystem = false;
        this.showChangePassword = !this.showChangePassword;
      }
    },



    // dropdown animation
    enter(el) {
      // set the element to his natural hight
      el.style.height = 'auto';
      /*
        get the calculated height.
        getComputedStyle returns an object containing all the CSS properties
        of the element after all active styles have been loaded and the basic
        calculations have been performed.
        We need the height after all basic calculations have been performed,
        then the height of each element here is dynamically,
        based on the number of items in the sublist.
      */
      const height = getComputedStyle(el).height;
      // set the height to zero for the opening animation
      el.style.height = 0;
      /*
        Force the repaint to make sure the animation is triggered correctly,
        then you can fire the method getComputedStyle again.
        This is not necessary, but sometimes the animation may not start depending on the case.
      */
      getComputedStyle(el);
      /*
        Set the height from the element to the calculated height.
        With setTimeout you make sure the browser has finished the painting
        after setting the height to zero.
      */
      setTimeout(() => {
        el.style.height = height;
      });
    },
    afterEnter(el) {
      el.style.height = 'auto';
    },
    leave(el) {
      /*
        Same as with the enter method, but only the other way around.
      */
      el.style.height = getComputedStyle(el).height;

      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = 0;
      });
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
