<template>
  <transition>
    <b-modal
        id="software-add"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal-large"
        centered
    >
      <template #modal-header="{ close }">
        <h5>发布新版本</h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="announcement-form-wrap">
          <form action="#">
            <div class="row">
              <div class="col-xl-12">
                <div class="c-input-group">
                  <label><span>*</span> 上架市场:</label>
                  <div class="select-wrap">
                    <select>
                      <option value="0">Windows</option>
                      <option value="0">MacOS</option>
                      <option value="0">Apple Store</option>
                      <option value="0">Google Play</option>
                    </select>
                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="input-2"><span>*</span> 软件版本号:</label>
                  <div class="input-wrap">
                    <input type="text" id="input-2" placeholder="英文公告标题">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="input-3"><span>*</span> 下载链接:</label>
                  <div class="input-wrap">
                    <input type="text" id="input-3" placeholder="公告类型">
                  </div>
                </div>
                <div class="c-input-group align-items-start">
                  <label><span>*</span> 版本说明:</label>
                  <div class="input-wrap">
                    <textarea placeholder="版本说明"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <b-button variant="modal-f-primary" @click="hide();showSuccessMessage('服务器添加成功', '服务器添加成功')">
          确定
        </b-button>

        <b-button variant="modal-f-cancel mr-10" @click="cancel()">
          取消
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "SoftwareAdd",
  mixins: [toaster],
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
