<template>
  <div class="widget-section">

    <!-- node-blacklist-section start -->
    <div class="today-traffic-log-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">节点黑名单（这里指的是：将某个用户的IP地址，拉进某个节点的黑名单，此列表永久有效，只要服务器存在）</h5>
              </div>
              <div class="admin-card-body">
                <div class="node-blacklist-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="email">用户账号</th>
                        <th scope="col" class="type">会员类型</th>
                        <th scope="col" class="name">节点名称</th>
                        <th scope="col" class="address">用户IP地址信息</th>
                        <th scope="col" class="device">设备版本</th>
                        <th scope="col" class="time">拉黑时间</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">Wi2121ndir@gmail.com</td>
                        <td class="type">免费会员</td>
                        <td class="name">United States01</td>
                        <td class="address">222.104.21.56 - South Korea</td>
                        <td class="device">iPone 12 Pro Max - iOS 8.5.1</td>
                        <td class="time">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:node-blacklist-success>
                              <i class="fa-solid fa-circle-chevron-right"></i> 解除黑名单
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">2</td>
                        <td class="email">Wi2121ndir@gmail.com</td>
                        <td class="type">免费会员</td>
                        <td class="name">United States01</td>
                        <td class="address">222.104.21.56 - South Korea</td>
                        <td class="device">iPone 12 Pro Max - iOS 8.5.1</td>
                        <td class="time">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:node-blacklist-success>
                              <i class="fa-solid fa-circle-chevron-right"></i> 解除黑名单
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">3</td>
                        <td class="email">Wi2121ndir@gmail.com</td>
                        <td class="type">免费会员</td>
                        <td class="name">United States01</td>
                        <td class="address">222.104.21.56 - South Korea</td>
                        <td class="device">iPone 12 Pro Max - iOS 8.5.1</td>
                        <td class="time">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:node-blacklist-success>
                              <i class="fa-solid fa-circle-chevron-right"></i> 解除黑名单
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">4</td>
                        <td class="email">Wi2121ndir@gmail.com</td>
                        <td class="type">免费会员</td>
                        <td class="name">United States01</td>
                        <td class="address">222.104.21.56 - South Korea</td>
                        <td class="device">iPone 12 Pro Max - iOS 8.5.1</td>
                        <td class="time">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:node-blacklist-success>
                              <i class="fa-solid fa-circle-chevron-right"></i> 解除黑名单
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">5</td>
                        <td class="email">Wi2121ndir@gmail.com</td>
                        <td class="type">免费会员</td>
                        <td class="name">United States01</td>
                        <td class="address">222.104.21.56 - South Korea</td>
                        <td class="device">iPone 12 Pro Max - iOS 8.5.1</td>
                        <td class="time">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:node-blacklist-success>
                              <i class="fa-solid fa-circle-chevron-right"></i> 解除黑名单
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">6</td>
                        <td class="email">Wi2121ndir@gmail.com</td>
                        <td class="type">免费会员</td>
                        <td class="name">United States01</td>
                        <td class="address">222.104.21.56 - South Korea</td>
                        <td class="device">iPone 12 Pro Max - iOS 8.5.1</td>
                        <td class="time">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:node-blacklist-success>
                              <i class="fa-solid fa-circle-chevron-right"></i> 解除黑名单
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">7</td>
                        <td class="email">Wi2121ndir@gmail.com</td>
                        <td class="type">免费会员</td>
                        <td class="name">United States01</td>
                        <td class="address">222.104.21.56 - South Korea</td>
                        <td class="device">iPone 12 Pro Max - iOS 8.5.1</td>
                        <td class="time">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:node-blacklist-success>
                              <i class="fa-solid fa-circle-chevron-right"></i> 解除黑名单
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">8</td>
                        <td class="email">Wi2121ndir@gmail.com</td>
                        <td class="type">免费会员</td>
                        <td class="name">United States01</td>
                        <td class="address">222.104.21.56 - South Korea</td>
                        <td class="device">iPone 12 Pro Max - iOS 8.5.1</td>
                        <td class="time">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:node-blacklist-success>
                              <i class="fa-solid fa-circle-chevron-right"></i> 解除黑名单
                            </button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="daily-table-pagination mt-30 mb-20">
                      <ul>
                        <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><span>...</span></li>
                        <li><a href="#">50</a></li>
                        <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                      </ul>
                      <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                        <select>
                          <option value="10">10 / Page</option>
                          <option value="10">20 / Page</option>
                          <option value="10">30 / Page</option>
                          <option value="10">40 / Page</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- today-traffic-log-section end -->
    <NodeBlackListBox/>
    <NodeBlackSuccessToast variant="success">
      <template #title><i class="fa-regular fa-circle-check"></i> 服务器添加成功</template>
      <p> 服务器添加成功</p>
    </NodeBlackSuccessToast>
  </div>
</template>

<script>
import NodeBlackListBox from "@/components/Modals/NodeBlackListBox";
import NodeBlackSuccessToast from "@/components/Toast/NodeBlackSuccessToast";

export default {
  name: "NodeBlacklist",
  components: {NodeBlackSuccessToast, NodeBlackListBox},
  methods: {

  }
}
</script>

<style scoped>

</style>
