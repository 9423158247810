<template>
  <div class="widget-section">


    <!-- rules-description-management-section start -->
    <div class="rules-description-management-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">规则说明管理</h5>
              </div>
              <div class="admin-card-body">
                <div class="rules-description-management-content mb-30">
                  <div class="title">规则列表说明</div>
                  <div class="row">
                    <div class="col-xl-8 col-lg-8">
                      <div class="text">
                        <p>
                          每一台节点加载规则，每台服务器规则都不一样，所以严格参照需求加载屏蔽规则，屏蔽规则有5种：纯字符串、正则表达式、子域名（推荐）、完整匹配、预定义域名列表，请参考配置规则示例进行配置。
                        </p>
                        <div class="content-btn">
                          <button class="content-btn-1" v-b-modal:blocking-rules-add>创建规则</button>
                          <button class="content-btn-2" v-b-modal:blocking-rules-details>示例查看</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 text-center text-lg-right mt-md-30 mt-xs-30">
                      <div class="thumb">
                        <img src="@/assets/img/thumb/thumb-1.svg" alt="thumb">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rules-description-management-content">
                  <div class="title">规则列表说明</div>
                  <div class="row">
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                      <div class="rules-management-card">
                        <div class="management-head">
                          <div class="num">1</div>
                          <div class="content">
                            <h4>禁止下载使用</h4>
                            <p>
                              为了防止下载软件，极度使用或者违规使用服务器
                            </p>
                          </div>
                        </div>
                        <div class="management-action">
                          <button v-b-modal:blocking-rules-add><i class="fa-regular fa-pencil"></i> 编辑规则</button>
                          <button v-b-modal:blocking-rules-details><i class="fa-solid fa-circle-chevron-right"></i> 规则详情
                          </button>
                          <button v-b-modal:blocking-rules-action-success><i class="fa-regular fa-trash-can"></i>删除
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="daily-table-pagination mb-20">
                        <ul>
                          <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                          <li><a href="#">1</a></li>
                          <li><a href="#">2</a></li>
                          <li><a href="#">3</a></li>
                          <li><a href="#">4</a></li>
                          <li><a href="#">5</a></li>
                          <li><span>...</span></li>
                          <li><a href="#">50</a></li>
                          <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                        </ul>
                        <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                          <select>
                            <option value="10">10 / Page</option>
                            <option value="10">20 / Page</option>
                            <option value="10">30 / Page</option>
                            <option value="10">40 / Page</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- rules-description-management-section end -->

    <BlockingRulesAdd/>
    <BlockingRulesDeleteBox/>
    <BlockingRulesDetailsBox/>
    <BlockingRulesSuccessToast variant="success">
      <template #title><i class="fa-regular fa-circle-check"></i> 服务器添加成功</template>
      <p> 服务器添加成功</p>
    </BlockingRulesSuccessToast>
  </div>
</template>

<script>
import BlockingRulesDeleteBox from "@/components/Modals/BlockingRulesDeleteBox";
import BlockingRulesSuccessToast from "@/components/Toast/BlockingRulesSuccessToast";
import BlockingRulesDetailsBox from "@/components/Modals/BlockingRulesDetailsBox";
import BlockingRulesAdd from "@/components/Modals/BlockingRulesAdd";

export default {
  name: "BlockingRules",
  components: {BlockingRulesAdd, BlockingRulesDetailsBox, BlockingRulesSuccessToast, BlockingRulesDeleteBox}
}
</script>

<style scoped>

</style>
