<template>
  <div class="widget-section">
    <!-- login-record-section start -->
    <div class="login-record-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-body">
                <b-tabs class="global-status-tab">
                  <b-tab active>
                    <template #title>修改密码</template>
                    <div class="tab-body">
                      <div class="login-record-form">
                        <div class="row justify-content-center mb-30">
                          <div class="col-xl-9">
                            <h3 class="title">修改密码</h3>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-6">
                            <div class="g-input-group">
                              <label for="input-1"><span>*</span> 原密码:</label>
                              <div class="input-wrap">
                                <input type="text" id="input-1" placeholder="Api接口域名">
                              </div>
                            </div>
                            <div class="g-input-group">
                              <label for="input-2"><span>*</span> 新密码:</label>
                              <div class="input-wrap">
                                <input type="text" id="input-2" placeholder="Aes加密模式">
                              </div>
                            </div>
                            <div class="g-input-group">
                              <label for="input-3"><span>*</span> 新密码:</label>
                              <div class="input-wrap">
                                <input type="text" id="input-3" placeholder="Aes填充">
                              </div>
                            </div>
                            <div class="g-button-group">
                              <button class="g-primary">保存</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab>
                    <template #title>登录记录</template>
                    <div class="tab-body">
                      <div class="login-log-section">
                        <div class="row justify-content-center">
                          <div class="col-xl-10">
                            <h3 class="login-log-title">Login Log</h3>
                            <div class="login-log-wrapper mb-30">
                              <div class="login-log-date">2020-12-20</div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                            </div>
                            <div class="login-log-wrapper">
                              <div class="login-log-date">2020-12-20</div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                              <div class="login-log-wrap">
                                <div class="login-log-left">
                                  <div class="thumb">
                                    <i class="fa-brands fa-windows"></i>
                                  </div>
                                  <div class="content">
                                    <h3>WindirPC</h3>
                                    <p>Ontario, Canada 14.127.100.242</p>
                                  </div>
                                </div>
                                <div class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-12-20 12:00
                                </div>
                              </div>
                            </div>
                            <div class="daily-table-pagination mt-30 mb-20">
                              <ul>
                                <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li><span>...</span></li>
                                <li><a href="#">50</a></li>
                                <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                              </ul>
                              <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                                <select>
                                  <option value="10">10 / Page</option>
                                  <option value="10">20 / Page</option>
                                  <option value="10">30 / Page</option>
                                  <option value="10">40 / Page</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- login-record-section end -->
  </div>
</template>

<script>
export default {
  name: "LoginRecord"
}
</script>

<style scoped>

</style>
