<template>
  <transition>
    <b-modal
        id="node-list-new-server"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal-large"
        centered
    >
      <template #modal-header="{ close }">
        <h5>编辑节点信息 <span>* 为必填项</span></h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="node-list-form-wrap">
          <form action="#">
            <div class="row">
              <div class="col-xl-6">
                <div class="c-input-group">
                  <label for="id"><span>*</span> ip:</label>
                  <div class="input-wrap">
                    <input type="text" id="id" placeholder="192.168.1.1">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="name"><span>*</span> 名称:</label>
                  <div class="input-wrap">
                    <input type="text" id="name" placeholder="United State 01">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="desc"><span>*</span> 说明:</label>
                  <div class="input-wrap">
                    <input type="text" id="desc" placeholder="Netflix Hulu">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="port"><span>*</span> 端口:</label>
                  <div class="input-wrap">
                    <input type="text" id="port" placeholder="8888">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="s-limit"><span>*</span> 限速(Mbps):</label>
                  <div class="input-wrap">
                    <input type="text" id="s-limit" placeholder="0">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="n-uuid"><span>*</span> UUID数量:</label>
                  <div class="input-wrap">
                    <input type="text" id="n-uuid" placeholder="2000">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="d-url"><span>*</span> URL域名:</label>
                  <div class="input-wrap">
                    <input type="text" id="d-url" placeholder="domain">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="c-input-group">
                  <label><span>*</span> 国家分类:</label>
                  <div class="select-wrap">
<!--                    <select>-->
<!--                      <option value="0">加拿大</option>-->
<!--                      <option value="0">日本</option>-->
<!--                      <option value="0">印度</option>-->
<!--                      <option value="0">韩国</option>-->
<!--                      <option value="0">美国</option>-->
<!--                      <option value="0">新加坡</option>-->
<!--                      <option value="0">香港</option>-->
<!--                      <option value="0">台湾</option>-->
<!--                    </select>-->
<!--                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>-->

                    <multiselect
                        v-model="selected3"
                        :options="options3"
                        :multiple="true"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="c-input-group">
                  <label><span>*</span> 开启方式:</label>
                  <div class="select-wrap">
<!--                    <select>-->
<!--                      <option value="0">只启用普通端口</option>-->
<!--                      <option value="0">只启用单端口多用户</option>-->
<!--                      <option value="0">单端口多用户与普通端口并存</option>-->
<!--                    </select>-->
<!--                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>-->
                    <multiselect
                        v-model="selected2"
                        :options="options2">
                    </multiselect>
                  </div>
                </div>
                <div class="c-input-group">
                  <label><span>*</span> 节点类型:</label>
                  <div class="select-wrap">
<!--                    <select>-->
<!--                      <option value="0">中转</option>-->
<!--                      <option value="0">中转节点</option>-->
<!--                    </select>-->
<!--                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>-->

                    <multiselect
                        v-model="selected1"
                        :options="options1">
                    </multiselect>
                  </div>
                </div>
                <div class="c-input-group">
                  <label><span>*</span> 协议类型:</label>
                  <div class="select-wrap">
                    <multiselect
                        v-model="selected"
                        :options="options">
                    </multiselect>
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="data"><span>*</span> 限流(GB):</label>
                  <div class="input-wrap">
                    <input type="text" id="data" placeholder="120">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="date"><span>*</span> 流量结算日:</label>
                  <div class="input-wrap">
                    <input type="text" id="date" placeholder="Every Number flow">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <b-button variant="modal-f-primary" @click="handleCOk(hide(),$bvToast.show('node-list-success-toast'))">
          确定
        </b-button>

        <b-button variant="modal-f-cancel mr-10" @click="cancel()">
          取消
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: "NodeListNewServer",
  data(){
    return {
      selected: null,
      selected1: '中转',
      selected2: '中转',
      selected3: null,
      options: ['V2ray', 'Trojan', 'Shadowsocks', 'Wireguard', 'L2TP', 'PPTP'],
      options1: ['中转', '中转节点'],
      options2: ['只启用单端口多用户', '只启用普通端口', '单端口多用户与普通端口并存'],
      options3: ['加拿大', '日本', '印度', '韩国', '美国', '新加坡', '香港', '台湾'],
    }
  },
  components: { Multiselect },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    },
    handleCOk($hide, $toster) {
      $hide();
      $toster();
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

</style>
