<template>
  <transition>
    <b-modal
        id="member-add"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal-large"
        centered
    >
      <template #modal-header="{ close }">
        <h5>编辑节点信息 <span><b>*</b> 为必填项</span></h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="member-form-wrap">
          <form action="#">
            <div class="row">
              <div class="col-xl-12">
                <div class="c-input-group">
                  <label for="input-1"><span>*</span> 用户账号:</label>
                  <div class="input-wrap">
                    <input type="text" id="input-1" placeholder="用户账号">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="input-2"><span>*</span> 登录密码:</label>
                  <div class="input-wrap">
                    <input type="text" id="input-2" placeholder="登录密码">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="input-3"><span>*</span> 确认密码:</label>
                  <div class="input-wrap">
                    <input type="text" id="input-3" placeholder="确认密码">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="input-4"><span>*</span> 可登录设备数:</label>
                  <div class="input-wrap">
                    <input type="text" id="input-4" placeholder="可登录设备数">
                  </div>
                </div>
                <div class="c-input-group">
                  <label for="radio-1"><span>*</span> 会员等级:</label>
                  <div class="radio-wrap">
                    <div class="radio-label">
                      <input type="radio" id="radio-1" name="radio" value="免费会员" checked>
                      <label for="radio-1">免费会员</label>
                    </div>
                    <div class="radio-label">
                      <input type="radio" id="radio-2" name="radio" value="付费会员">
                      <label for="radio-2">付费会员</label>
                    </div>

                  </div>
                </div>
                <div class="c-input-group">
                  <label for="input-6"><span>*</span> 过期时间:</label>
                  <div class="input-wrap">
                    <input type="text" id="input-6" placeholder="过期时间">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <b-button variant="modal-f-primary" @click="hide();showSuccessMessage('服务器添加成功', '服务器添加成功')">
          确定
        </b-button>

        <b-button variant="modal-f-cancel mr-10" @click="cancel()">
          取消
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "MemberAdd",
  mixins: [toaster],
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
