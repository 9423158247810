import Vue from 'vue'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

// import ECharts modules manually to reduce bundle size
// https://github.com/ecomfe/vue-echarts
// https://echarts.apache.org/examples/en/index.html
import {
    CanvasRenderer
} from 'echarts/renderers'
import {
    BarChart,
    LineChart,
    PieChart,
    MapChart,
    RadarChart,
    ScatterChart,
    EffectScatterChart,
    LinesChart,
} from 'echarts/charts'
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    PolarComponent,
    ToolboxComponent,
} from 'echarts/components'

use([
    CanvasRenderer,
    BarChart,
    LineChart,
    GridComponent,
    TooltipComponent,
    ToolboxComponent,
    TitleComponent,
    LegendComponent,
    PieChart,
    MapChart,
    RadarChart,
    ScatterChart,
    EffectScatterChart,
    LinesChart,
    PolarComponent
]);

// register globally (or you can do it locally)
Vue.component('v-chart', ECharts)