<template>
  <div class="widget-section">
    <!-- commission-settlement-section start -->
    <div class="commission-settlement-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">结算管理</h5>
              </div>
              <div class="admin-card-body">
                <div class="settlement-management-btn">
                  <div class="check-wrapper">
                    <div class="check-wrap">
                      <input type="radio" id="check-01" name="status">
                      <label for="check-01"><i class="fa-solid fa-circle-small"></i> 全部</label>
                    </div>
                    <div class="check-wrap danger">
                      <input type="radio" id="check-02" name="status">
                      <label for="check-02"><i class="fa-solid fa-circle-small"></i> 未申请</label>
                    </div>
                    <div class="check-wrap warning">
                      <input type="radio" id="check-03" name="status">
                      <label for="check-03"><i class="fa-solid fa-circle-small"></i> 待结算</label>
                    </div>
                    <div class="check-wrap success">
                      <input type="radio" id="check-04" name="status">
                      <label for="check-04"><i class="fa-solid fa-circle-small"></i> 已结算</label>
                    </div>
                  </div>
                  <div class="select-wrap">
                    <select>
                      <option value="0">youtube</option>
                      <option value="0">google</option>
                      <option value="0">facebook</option>
                      <option value="0">nide</option>
                      <option value="0">odenice</option>
                    </select>
                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>
                  </div>
                  <div class="input-wrap">
                    <div class="date-picker">
                      <date-range-picker
                          v-model="dateRange"
                          :locale-data="{
                        direction: 'ltr',
                        format: 'yyyy/mm/dd',
                        separator: ' - ',
                        applyLabel: 'Apply',
                        cancelLabel: 'Cancel',
                        weekLabel: 'W',
                        customRangeLabel: 'Custom Range',
                        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 0,}"
                          :show-week-numbers="false"
                          :append-to-body="true"
                          :ranges="false">
                      </date-range-picker>
                    </div>
                  </div>
                  <button class="c-add-btn">
                    <i class="fa-regular fa-magnifying-glass"></i> 查询
                  </button>
                </div>
                <div class="settlement-management-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="agent">代理商渠道</th>
                        <th scope="col" class="b-cycle">结算周期</th>
                        <th scope="col" class="sales">销售额($)</th>
                        <th scope="col" class="pending">待结算($)</th>
                        <th scope="col" class="status">状态</th>
                        <th scope="col" class="method">支付方式</th>
                        <th scope="col" class="address">汇款地址</th>
                        <th scope="col" class="withdrawal">申请提现时间</th>
                        <th scope="col" class="payment">支付时间</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">youtube</td>
                        <td colspan="b-cycle">2021/02/01 - 2021/02/29</td>
                        <td colspan="sales">10000</td>
                        <td colspan="pending">6000</td>
                        <td class="status">
                          <div class="status-text warning">● 待支付</div>
                        </td>
                        <td class="method">USD</td>
                        <td class="address"> 1234567812345678123456781234567812345678123456781234567812345678</td>
                        <td class="withdrawal">2020-11-25 23:26:08</td>
                        <td class="payment">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="delete-btn" v-b-modal:settlement-management-add>
                              <i class="fa-regular fa-trash-can"></i> 未申请
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">youtube</td>
                        <td colspan="b-cycle">2021/02/01 - 2021/02/29</td>
                        <td colspan="sales">10000</td>
                        <td colspan="pending">6000</td>
                        <td class="status">
                          <div class="status-text warning">● 待支付</div>
                        </td>
                        <td class="method">USD</td>
                        <td class="address"> 1234567812345678123456781234567812345678123456781234567812345678</td>
                        <td class="withdrawal">2020-11-25 23:26:08</td>
                        <td class="payment">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="info-btn">
                              <i class="fa-solid fa-xmark"></i> 未申请
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">youtube</td>
                        <td colspan="b-cycle">2021/02/01 - 2021/02/29</td>
                        <td colspan="sales">10000</td>
                        <td colspan="pending">6000</td>
                        <td class="status">
                          <div class="status-text warning">● 待支付</div>
                        </td>
                        <td class="method">USD</td>
                        <td class="address"> 1234567812345678123456781234567812345678123456781234567812345678</td>
                        <td class="withdrawal">2020-11-25 23:26:08</td>
                        <td class="payment">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="view-btn">
                              <i class="fa-solid fa-check"></i> 未申请
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">youtube</td>
                        <td colspan="b-cycle">2021/02/01 - 2021/02/29</td>
                        <td colspan="sales">10000</td>
                        <td colspan="pending">6000</td>
                        <td class="status">
                          <div class="status-text warning">● 待支付</div>
                        </td>
                        <td class="method">USD</td>
                        <td class="address"> 1234567812345678123456781234567812345678123456781234567812345678</td>
                        <td class="withdrawal">2020-11-25 23:26:08</td>
                        <td class="payment">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="info-btn">
                              <i class="fa-solid fa-xmark"></i> 未申请
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">youtube</td>
                        <td colspan="b-cycle">2021/02/01 - 2021/02/29</td>
                        <td colspan="sales">10000</td>
                        <td colspan="pending">6000</td>
                        <td class="status">
                          <div class="status-text warning">● 待支付</div>
                        </td>
                        <td class="method">USD</td>
                        <td class="address"> 1234567812345678123456781234567812345678123456781234567812345678</td>
                        <td class="withdrawal">2020-11-25 23:26:08</td>
                        <td class="payment">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="view-btn">
                              <i class="fa-solid fa-check"></i> 未申请
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">youtube</td>
                        <td colspan="b-cycle">2021/02/01 - 2021/02/29</td>
                        <td colspan="sales">10000</td>
                        <td colspan="pending">6000</td>
                        <td class="status">
                          <div class="status-text warning">● 待支付</div>
                        </td>
                        <td class="method">USD</td>
                        <td class="address"> 1234567812345678123456781234567812345678123456781234567812345678</td>
                        <td class="withdrawal">2020-11-25 23:26:08</td>
                        <td class="payment">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="info-btn">
                              <i class="fa-solid fa-xmark"></i> 未申请
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">youtube</td>
                        <td colspan="b-cycle">2021/02/01 - 2021/02/29</td>
                        <td colspan="sales">10000</td>
                        <td colspan="pending">6000</td>
                        <td class="status">
                          <div class="status-text warning">● 待支付</div>
                        </td>
                        <td class="method">USD</td>
                        <td class="address"> 1234567812345678123456781234567812345678123456781234567812345678</td>
                        <td class="withdrawal">2020-11-25 23:26:08</td>
                        <td class="payment">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="view-btn">
                              <i class="fa-solid fa-check"></i> 未申请
                            </button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- commission-settlement-section end -->
    <SettlementManagementAdd/>
  </div>
</template>

<script>
import SettlementManagementAdd from "@/components/Modals/SettlementManagementAddd";

export default {
  name: "CommissionSettlement",
  components: {SettlementManagementAdd},
  data() {
    return {
      dateRange: {
        startDate: '',
        endDate: ''
      },
    }
  }
}
</script>

<style scoped>

</style>
