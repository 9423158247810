import Vue from 'vue';
import axios from 'axios';

Vue.prototype.$axios = axios;

// export const HTTP = axios.create({
//     baseURL: `http://jsonplaceholder.typicode.com/`,
//     headers: {
//         Authorization: 'Bearer {token}'
//     }
// });