<template>
  <div class="widget-section">
    <!-- platform-announcement-section start -->
    <div class="today-traffic-log-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">Platform announcement</h5>
              </div>
              <div class="admin-card-body">
                <div class="platform-announcement-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">ID</th>
                        <th scope="col" class="email">announcement title</th>
                        <th scope="col" class="status">status</th>
                        <th scope="col" class="type">Type</th>
                        <th scope="col" class="date">release time</th>
                        <th scope="col" class="publisher">Publisher</th>
                        <th scope="col" class="personnel">Receiving personnel</th>
                        <th scope="col" class="action">operate</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">How to build good customer relationships</td>
                        <td class="status">
                          <div class="status-text warning">
                            <i class="fa-solid fa-circle-small"></i> Have read
                          </div>
                        </td>
                        <td class="type">type name</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">official</td>
                        <td class="personnel">All agents</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:platform-announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> Details
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">How to build good customer relationships</td>
                        <td class="status">
                          <div class="status-text warning">
                            <i class="fa-solid fa-circle-small"></i> Have read
                          </div>
                        </td>
                        <td class="type">type name</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">official</td>
                        <td class="personnel">All agents</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:platform-announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> Details
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">How to build good customer relationships</td>
                        <td class="status">
                          <div class="status-text danger">
                            <i class="fa-solid fa-circle-small"></i> Have read
                          </div>
                        </td>
                        <td class="type">type name</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">official</td>
                        <td class="personnel">All agents</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:platform-announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> Details
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">How to build good customer relationships</td>
                        <td class="status">
                          <div class="status-text warning">
                            <i class="fa-solid fa-circle-small"></i> Have read
                          </div>
                        </td>
                        <td class="type">type name</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">official</td>
                        <td class="personnel">All agents</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:platform-announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> Details
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">How to build good customer relationships</td>
                        <td class="status">
                          <div class="status-text success">
                            <i class="fa-solid fa-circle-small"></i> Have read
                          </div>
                        </td>
                        <td class="type">type name</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">official</td>
                        <td class="personnel">All agents</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:platform-announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> Details
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">How to build good customer relationships</td>
                        <td class="status">
                          <div class="status-text warning">
                            <i class="fa-solid fa-circle-small"></i> Have read
                          </div>
                        </td>
                        <td class="type">type name</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">official</td>
                        <td class="personnel">All agents</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:platform-announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> Details
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">How to build good customer relationships</td>
                        <td class="status">
                          <div class="status-text warning">
                            <i class="fa-solid fa-circle-small"></i> Have read
                          </div>
                        </td>
                        <td class="type">type name</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">official</td>
                        <td class="personnel">All agents</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:platform-announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> Details
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">How to build good customer relationships</td>
                        <td class="status">
                          <div class="status-text warning">
                            <i class="fa-solid fa-circle-small"></i> Have read
                          </div>
                        </td>
                        <td class="type">type name</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">official</td>
                        <td class="personnel">All agents</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="success-btn" v-b-modal:platform-announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> Details
                            </button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="daily-table-pagination mt-30 mb-20">
                      <ul>
                        <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><span>...</span></li>
                        <li><a href="#">50</a></li>
                        <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                      </ul>
                      <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                        <select>
                          <option value="10">10 / Page</option>
                          <option value="10">20 / Page</option>
                          <option value="10">30 / Page</option>
                          <option value="10">40 / Page</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- platform-announcement-section end -->
    <PlatformAnnouncementDetails/>
  </div>
</template>

<script>
import PlatformAnnouncementDetails from "@/components/Modals/PlatformAnnouncementDetails";

export default {
  name: "AgentPlatformAnnouncement",
  components: {PlatformAnnouncementDetails}
}
</script>

<style scoped>

</style>
