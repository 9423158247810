<template>
  <div class="widget-section">
    <!-- d-card-section start -->
    <div class="d-card-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 col-md-6">
            <div class="d-card-wrap mb-30">
              <div class="d-card-head">
                <span class="icon"><i class="fa-regular fa-user"></i></span> 新用户注册量
              </div>
              <div class="d-card-body">
                <h4><span>今日注册</span> 1560</h4>
                <p><span>昨日注册</span> 2326</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="d-card-wrap mb-30">
              <div class="d-card-head">
                <span class="icon warning"><i class="fa-regular fa-user-group"></i></span> 用户活跃量
              </div>
              <div class="d-card-body">
                <h4><span>今日活跃</span> 1560</h4>
                <p><span>昨日活跃</span> 2326</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="d-card-wrap mb-30">
              <div class="d-card-head">
                <span class="icon purple"><i class="fa-regular fa-download"></i></span> 软件下载量
              </div>
              <div class="d-card-body">
                <h4><span>今日下载</span> 1560</h4>
                <p><span>昨日下载</span> 2326</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="d-card-wrap mb-30">
              <div class="d-card-head">
                <span class="icon success"><i class="fa-regular fa-earth-asia"></i></span> 网站访问量(ip)
              </div>
              <div class="d-card-body">
                <h4><span>今日访问</span> 1560</h4>
                <p><span>昨日访问</span> 2326</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- d-card-section end -->

    <!-- d-card-section-2 start -->
    <div class="d-card-section-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 col-md-6">
            <div class="d-card-wrap-2 mb-30">
              <div class="d-card-icon">
                <i class="fa-regular fa-file-lines"></i>
              </div>
              <div class="d-card-body">
                <h3>今日订单(笔)</h3>
                <h4>2000</h4>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="d-card-wrap-2 mb-30">
              <div class="d-card-icon">
                <i class="fa-regular fa-yen-sign"></i>
              </div>
              <div class="d-card-body">
                <h3>今日收益($)</h3>
                <h4>2615.99</h4>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="d-card-wrap-2 purple mb-30">
              <div class="d-card-icon">
                <i class="fa-regular fa-user"></i>
              </div>
              <div class="d-card-body">
                <h3>昨日订单(笔)</h3>
                <h4>2000</h4>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="d-card-wrap-2 purple mb-30">
              <div class="d-card-icon">
                <i class="fa-regular fa-yen-sign"></i>
              </div>
              <div class="d-card-body">
                <h3>今日收益($)</h3>
                <h4>2615.99</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- d-card-section-2 end -->

    <!-- payment-trends-section start -->
    <div class="payment-trends-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h4 class="title">支付趋势</h4>
              </div>
              <div class="admin-card-body">
                <div class="payment-trends-chart">
                  <v-chart :option="chart1" class="chart"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- payment-trends-section end -->

    <!-- payment-overview-channels-section start -->
    <div class="payment-overview-channels-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h4 class="title">支付渠道占比总览</h4>
              </div>
              <div class="admin-card-body">
                <div class="payment-overview-wrapper">
                  <div class="payment-card-wrap">
                    <p class="title">总金额</p>
                    <h4 class="price">4289.23<span>$</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="salmon"></b-progress-bar>
                        <b-progress-bar :value="25" variant="goldenrod"></b-progress-bar>
                        <b-progress-bar :value="25" variant="dark-cornflowerblue"></b-progress-bar>
                        <b-progress-bar :value="25" variant="turquoise"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="payment-card-wrap">
                    <p class="title">PayPal</p>
                    <h4 class="price">869.48<span>$</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="salmon"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="payment-card-wrap">
                    <p class="title">Credit card</p>
                    <h4 class="price">869.48<span>$</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="goldenrod"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="payment-card-wrap">
                    <p class="title">Alipay</p>
                    <h4 class="price">869.48<span>$</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="dark-cornflowerblue"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="payment-card-wrap">
                    <p class="title">Virtual currency</p>
                    <h4 class="price">869.48<span>$</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="turquoise"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="payment-card-wrap">
                    <p class="title">总笔数</p>
                    <h4 class="price">20000<span>笔</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="light-turquoise"></b-progress-bar>
                        <b-progress-bar :value="25" variant="cornflowerblue"></b-progress-bar>
                        <b-progress-bar :value="25" variant="light-blue"></b-progress-bar>
                        <b-progress-bar :value="25" variant="light-green"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="payment-card-wrap">
                    <p class="title">PayPal</p>
                    <h4 class="price">869.48<span>笔</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="light-turquoise"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="payment-card-wrap">
                    <p class="title">Credit card</p>
                    <h4 class="price">869.48<span>笔</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="cornflowerblue"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="payment-card-wrap">
                    <p class="title">Alipay</p>
                    <h4 class="price">869.48<span>笔</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="light-blue"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                  <div class="payment-card-wrap">
                    <p class="title">Virtual currency</p>
                    <h4 class="price">869.48<span>笔</span></h4>
                    <div class="payment-progress">
                      <b-progress :max="100">
                        <b-progress-bar :value="25" variant="light-green"></b-progress-bar>
                      </b-progress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- payment-overview-channels-section end -->

    <!-- payment-overview-clients-section start -->
    <div class="payment-overview-clients-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h4 class="title">支付客户端占比总览</h4>
              </div>
              <div class="admin-card-body">
                <div class="payment-clients-wrapper">
                  <div class="row mt-20 justify-content-center">
                    <div class="col-xl-3 col-lg-5 col-md-6">
                      <div class="payment-clients-wrap mb-20">
                        <div class="progress-wrap">
                          <radial-progress-bar
                              :diameter="100" :completed-steps="completedStep40" :total-steps="100" :animateSpeed="1000"
                              :fps="30" :timingFunc="`linear`"
                              :startColor="`#FB6260`" :stopColor="`#FB6260`" :innerStrokeColor="`#E9E9E9`"
                              :strokeWidth="10" :inner-stroke-width="10" :strokeLinecap="`square`">
                            <h4>{{ completedStep40 }}%</h4>
                          </radial-progress-bar>
                        </div>
                        <div class="content">
                          <p>Windows</p>
                          <h4>565621 <span>$</span></h4>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-5 col-md-6">
                      <div class="payment-clients-wrap mb-20">
                        <div class="progress-wrap">
                          <radial-progress-bar
                              :diameter="100" :completed-steps="completedStep60" :total-steps="100"
                              :startColor="`#0099FF`" :stopColor="`#0099FF`" :innerStrokeColor="`#E9E9E9`"
                              :strokeWidth="10" :inner-stroke-width="10" :strokeLinecap="`square`">
                            <h4>{{ completedStep60 }}%</h4>
                          </radial-progress-bar>
                        </div>
                        <div class="content">
                          <p>iOS APP</p>
                          <h4>1235 <span>$</span></h4>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-5 col-md-6">
                      <div class="payment-clients-wrap mb-20">
                        <div class="progress-wrap">
                          <radial-progress-bar
                              :diameter="100" :completed-steps="completedStep25" :total-steps="100"
                              :startColor="`#8167F5`" :stopColor="`#8167F5`" :innerStrokeColor="`#E9E9E9`"
                              :strokeWidth="10" :inner-stroke-width="10" :strokeLinecap="`square`">
                            <h4>{{ completedStep25 }}%</h4>
                          </radial-progress-bar>
                        </div>
                        <div class="content">
                          <p>Android</p>
                          <h4>959 <span>$</span></h4>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-5 col-md-6">
                      <div class="payment-clients-wrap mb-20">
                        <div class="progress-wrap">
                          <radial-progress-bar
                              :diameter="100" :completed-steps="completedStep10" :total-steps="100"
                              :startColor="`#51D351`" :stopColor="`#51D351`" :innerStrokeColor="`#E9E9E9`"
                              :strokeWidth="10" :inner-stroke-width="10" :strokeLinecap="`square`">
                            <h4>{{ completedStep10 }}%</h4>
                          </radial-progress-bar>
                        </div>
                        <div class="content">
                          <p>HTTP Web</p>
                          <h4>591118.2 <span>$</span></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- payment-overview-clients-section end -->

    <!-- daily-details-section start -->
    <div class="daily-details-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h4 class="title">每日详情</h4>
              </div>
              <div class="admin-card-body">
                <div class="daily-details-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th scope="col">时间</th>
                        <th scope="col">银行卡（$）</th>
                        <th scope="col">现金（$）</th>
                        <th scope="col">支付宝（$）</th>
                        <th scope="col">虚拟货币（$）</th>
                        <th scope="col">累计充值（$）</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>2020-12-01</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      <tr>
                        <td>2020-12-02</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      <tr>
                        <td>2020-12-03</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      <tr>
                        <td>2020-12-04</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      <tr>
                        <td>2020-12-05</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      <tr>
                        <td>2020-12-06</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      <tr>
                        <td>2020-12-07</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      <tr>
                        <td>2020-12-08</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      <tr>
                        <td>2020-12-09</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      <tr>
                        <td>2020-12-10</td>
                        <td>6769.21</td>
                        <td>27890.00</td>
                        <td>38310.00</td>
                        <td>32131.00</td>
                        <td>645452.00</td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="daily-table-pagination mt-30 mb-20">
                      <ul>
                        <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><span>...</span></li>
                        <li><a href="#">50</a></li>
                        <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                      </ul>
                      <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                        <select>
                          <option value="10">10 / Page</option>
                          <option value="10">20 / Page</option>
                          <option value="10">30 / Page</option>
                          <option value="10">40 / Page</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daily-details-section end -->

  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dashboard",
  data() {
    return {
      step: 0,
      step60: 0,
      step25: 0,
      step10: 0,
      chart1: {
        // title: {
        //   text: 'Stacked Area Chart'
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['充值金额', '充值笔数']
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['2020-01', '2020-02', '2020-03', '2020-04', '2020-05', '2020-06', '2020-07', '2020-08', '2020-09', '2020-10', '2020-11', '2020-12']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '充值金额',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: [700, 1490, 950, 1250, 1840, 1750, 2720, 2350, 2330, 1830, 1390, 1800],
          },
          {
            name: '充值笔数',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: [900, 1520, 960, 1213, 2122, 1430, 1743, 2711, 1240, 1960, 1865, 1209],
          },
        ]
      },
    }
  },
  components: {
    RadialProgressBar
  },
  computed: {
    completedStep40() {
      if (this.step !== 40) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => {
          this.step++;
        }, 100);
      }
      return this.step;
    },
    completedStep60() {

      if (this.step60 !== 60) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => {
          this.step60++;
        }, 100);
      }
      return this.step60;
    },
    completedStep25() {
      if (this.step25 !== 25) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => {
          this.step25++;
        }, 100);
      }
      return this.step25;
    },
    completedStep10() {
      if (this.step10 !== 10) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => {
          this.step10++;
        }, 100);
      }
      return this.step10;
    }
  },
  methods: {
    // eslint-disable-next-line vue/return-in-computed-property
    completedStep() {
      if (this.step !== 40) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => {
          this.step++;
        }, 100);
      }
      return this.step;
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
