<template>
  <div class="widget-section">
    <!-- user-details-section start -->
    <div class="login-record-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-body">
                <div class="user-info-details-wrap">
                  <div class="user-info-details-left">
                    <div class="user-info-author-wrap">
                      <div class="icon">
                        <i class="fa-solid fa-circle-user"></i>
                      </div>
                      <div class="text">
                        <p class="email">Windir@gmail.com</p>
                        <p class="status success">付费会员</p>
                      </div>
                    </div>
                    <div class="user-info-meta">
                      <div class="meta-wrap">
                        <p>用户状态</p>
                        <p class="status success"><i class="fa-solid fa-circle-small"></i> 正常</p>
                      </div>
                      <div class="meta-wrap">
                        <p>总消费金额$</p>
                        <p><b>98.99</b></p>
                      </div>
                      <div class="meta-wrap">
                        <p>订单记录</p>
                        <p><b>5</b></p>
                      </div>
                      <div class="meta-wrap">
                        <p>可登录设备数</p>
                        <p><b>6</b></p>
                      </div>
                    </div>
                  </div>
                  <div class="user-info-details-right">
                    <div class="user-info-btn-wrap">
                      <button class="btn-primary" v-b-modal:member-modify>修改资料</button>
                      <button class="btn-primary-outline" v-b-modal:member-state>设置状态</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- user-details-section end -->

    <!-- user-details-section start -->
    <div class="login-record-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-body">
                <b-tabs class="global-status-tab">
                  <b-tab active>
                    <template #title>修改密码</template>
                    <div class="tab-body">
                      <div class="user-details-tab">
                        <div class="row mb-30">
                          <div class="col-xl-12">
                            <h3 class="user-details-tab-title">用户资料</h3>
                          </div>
                        </div>
                        <div class="change-password-table-wrapper">
                          <div class="table-responsive-wrapper">
                            <table class="table table-striped">
                              <tbody>
                              <tr>
                                <th scope="col" class="user">用户账号</th>
                                <th scope="col" class="expired">是否过期</th>
                                <th scope="col" class="ip">注册IP</th>
                                <th scope="col" class="registration">注册所在国家</th>
                              </tr>
                              <tr>
                                <td class="user">20208447466@gmail.com</td>
                                <td colspan="expired">否</td>
                                <td colspan="ip">192.168.222.168</td>
                                <td colspan="registration">智利圣地亚戈</td>
                              </tr>
                              <tr>
                                <th scope="col" class="user">注册渠道</th>
                                <th scope="col" class="expired">登录设备数量</th>
                                <th scope="col" class="ip">注册时间</th>
                                <th scope="col" class="registration">最后登录</th>
                              </tr>
                              <tr>
                                <td class="user">iOS APP</td>
                                <td colspan="expired">3/6</td>
                                <td colspan="ip">2017-07-24 17:25:38</td>
                                <td colspan="registration">2020-11-24 10:00:00</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab>
                    <template #title>订单记录</template>
                    <div class="tab-body">
                      <div class="order-record-table-wrapper">
                        <div class="table-responsive-wrapper">
                          <table class="table table-striped">
                            <tbody>
                            <tr>
                              <th scope="col" class="account">订单编号</th>
                              <th scope="col" class="price">订单金额</th>
                              <th scope="col" class="status">订单状态</th>
                              <th scope="col" class="method">支付方式</th>
                              <th scope="col" class="source">订单来源</th>
                              <th scope="col" class="o-time">订单提交时间</th>
                              <th scope="col" class="p-time">订单支付时间</th>
                            </tr>
                            <tr>
                              <td class="account">20206297662100</td>
                              <td class="price">$9.9</td>
                              <td class="status">
                                <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                              </td>
                              <td colspan="method">PayPal</td>
                              <td colspan="source">iOS APP</td>
                              <td class="o-time">2020-11-25 23:26:08</td>
                              <td class="p-time">2020-11-25 23:26:08</td>
                            </tr>
                            <tr>
                              <td class="account">20206297662100</td>
                              <td class="price">$9.9</td>
                              <td class="status">
                                <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                              </td>
                              <td colspan="method">PayPal</td>
                              <td colspan="source">iOS APP</td>
                              <td class="o-time">2020-11-25 23:26:08</td>
                              <td class="p-time">2020-11-25 23:26:08</td>
                            </tr>
                            <tr>
                              <td class="account">20206297662100</td>
                              <td class="price">$9.9</td>
                              <td class="status">
                                <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                              </td>
                              <td colspan="method">PayPal</td>
                              <td colspan="source">iOS APP</td>
                              <td class="o-time">2020-11-25 23:26:08</td>
                              <td class="p-time">2020-11-25 23:26:08</td>
                            </tr>
                            <tr>
                              <td class="account">20206297662100</td>
                              <td class="price">$9.9</td>
                              <td class="status">
                                <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                              </td>
                              <td colspan="method">PayPal</td>
                              <td colspan="source">iOS APP</td>
                              <td class="o-time">2020-11-25 23:26:08</td>
                              <td class="p-time">2020-11-25 23:26:08</td>
                            </tr>
                            <tr>
                              <td class="account">20206297662100</td>
                              <td class="price">$9.9</td>
                              <td class="status">
                                <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                              </td>
                              <td colspan="method">PayPal</td>
                              <td colspan="source">iOS APP</td>
                              <td class="o-time">2020-11-25 23:26:08</td>
                              <td class="p-time">2020-11-25 23:26:08</td>
                            </tr>
                            <tr>
                              <td class="account">20206297662100</td>
                              <td class="price">$9.9</td>
                              <td class="status">
                                <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 已支付</div>
                              </td>
                              <td colspan="method">PayPal</td>
                              <td colspan="source">iOS APP</td>
                              <td class="o-time">2020-11-25 23:26:08</td>
                              <td class="p-time">2020-11-25 23:26:08</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="daily-table-pagination mt-30 mb-20">
                          <ul>
                            <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                            <li><a href="#">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">4</a></li>
                            <li><a href="#">5</a></li>
                            <li><span>...</span></li>
                            <li><a href="#">50</a></li>
                            <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                          </ul>
                          <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                            <select>
                              <option value="10">10 / Page</option>
                              <option value="10">20 / Page</option>
                              <option value="10">30 / Page</option>
                              <option value="10">40 / Page</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab>
                    <template #title>已登录设备数</template>
                    <div class="tab-body">
                      <div class="device-login-table-wrapper">
                        <div class="table-responsive-wrapper">
                          <table class="table table-striped">
                            <tbody>
                            <tr>
                              <th scope="col" class="time">登录时间</th>
                              <th scope="col" class="ip">IP地址</th>
                              <th scope="col" class="address">地址</th>
                              <th scope="col" class="method">登录方式</th>
                            </tr>
                            <tr>
                              <td class="time">2020-11-25 23:26:08</td>
                              <td class="ip">183.14.135.1</td>
                              <td colspan="address">智利圣地亚戈</td>
                              <td colspan="method">iOS APP - iPhone 12 Pro Max</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="daily-table-pagination mt-30 mb-20">
                          <ul>
                            <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                            <li><a href="#">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">4</a></li>
                            <li><a href="#">5</a></li>
                            <li><span>...</span></li>
                            <li><a href="#">50</a></li>
                            <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                          </ul>
                          <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                            <select>
                              <option value="10">10 / Page</option>
                              <option value="10">20 / Page</option>
                              <option value="10">30 / Page</option>
                              <option value="10">40 / Page</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- user-details-section end -->

    <MemberModify/>
    <MemberState />
  </div>
</template>

<script>
import MemberModify from "@/components/Modals/MemberModify";
import MemberState from "@/components/Modals/MemberState";

export default {
  name: "UserDetails",
  components: {MemberState, MemberModify}
}
</script>

<style scoped>

</style>
