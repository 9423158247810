import Vue from "vue";
import VueRouter from "vue-router";
import FullLayout from "@/layouts/FullLayout";
import Dashboard from "@/views/admin/Dashboard";
import WorkOrderProcessing from "@/views/admin/WorkOrderProcessing";
import CloudServerOperationMonitoring from "@/views/admin/CloudServerOperationMonitoring";
import UserDistributionDataMonitoring from "@/views/admin/UserDistributionDataMonitoring";
import CountryList from "@/views/admin/CountryList";
import NodeList from "@/views/admin/NodeList";
import OnlineUsersRealTime from "@/views/admin/OnlineUsersRealTime";
import TrafficLogToday from "@/views/admin/TrafficLogToday";
import BlockingRules from "@/views/admin/BlockingRules";
import NodeBlacklist from "@/views/admin/NodeBlacklist";
import UserWhoTriggeredBlockingRule from "@/views/admin/UserWhoTriggeredBlockingRule";
import ProductList from "@/views/admin/ProductList";
import MemberList from "@/views/admin/MemberList";
import OrderList from "@/views/admin/OrderList";
import TicketList from "@/views/admin/TicketList";
import AnnouncementList from "@/views/admin/AnnouncementList";
import SoftwareVersion from "@/views/admin/SoftwareVersion";
import GlobalConfiguration from "@/views/admin/GlobalConfiguration";
import WebOfficialConfiguration from "@/views/admin/WebOfficialConfiguration";
import SuperAdministratorAccount from "@/views/admin/SuperAdministratorAccount";
import GlobalStatistics from "@/views/admin/GlobalStatistics";
import MemberOrder from "@/views/admin/MemberOrder";
import RegisteredUser from "@/views/admin/RegisteredUser";
import CommissionSettlement from "@/views/admin/CommissionSettlement";
import AgentList from "@/views/admin/AgentList";
import AgentAdded from "@/views/admin/AgentAdded";
import AgentDashboardLayout from "@/layouts/AgentDashboardLayout";
import SalesDashboard from "@/views/agent/SalesDashboard";
import SoftwareProfit from "@/views/agent/SoftwareProfit";
import AgentUserList from "@/views/agent/AgentUserList";
import AgentUserOrder from "@/views/agent/AgentUserOrder";
import AgentSettlementManagement from "@/views/agent/AgentSettlementManagement";
import AgentPlatformAnnouncement from "@/views/agent/AgentPlatformAnnouncement";
import AgentAccountSettings from "@/views/agent/AgentAccountSettings";
import LoginRecord from "@/views/admin/LoginRecord";
import UserDetails from "@/views/admin/UserDetails";
import AdminLogin from "@/views/admin/AdminLogin";
import AgentLogin from "@/views/agent/AgentLogin";
import AccountSettings from "@/views/admin/AccountSettings";

Vue.use(VueRouter);


const router = new VueRouter({
    mode: "history",


    routes: [
        {
            name: "AdminLogin",
            path: "/admin/login",
            component: AdminLogin,
        },
        {
            name: "AgentLogin",
            path: "/agent/login",
            component: AgentLogin,
        },
        {
            path: "/",
            component: FullLayout,
            children: [
                {
                    path: "/",
                    redirect: "/admin/login",
                },
                {
                    name: "AdminLogin",
                    path: "/admin",
                    redirect: "/admin/login"
                },
                {
                    name: "Dashboard",
                    path: "/admin/dashboard",
                    component: Dashboard
                },
                {
                    name: "WorkOrderProcessing",
                    path: "/admin/work-order-processing",
                    component: WorkOrderProcessing
                },
                {
                    name: "CloudServerOperationMonitoring",
                    path: "/admin/cloud-server-operation-monitoring",
                    component: CloudServerOperationMonitoring
                },
                {
                    name: "UserDistributionDataMonitoring",
                    path: "/admin/user-distribution-data-monitoring",
                    component: UserDistributionDataMonitoring
                },
                {
                    name: "CountryList",
                    path: "/admin/country-list",
                    component: CountryList
                },
                {
                    name: "NodeList",
                    path: "/admin/node-list",
                    component: NodeList
                },
                {
                    name: "OnlineUsersRealTime",
                    path: "/admin/online-users-real-time",
                    component: OnlineUsersRealTime
                },
                {
                    name: "TrafficLogToday",
                    path: "/admin/traffic-log-today",
                    component: TrafficLogToday
                },
                {
                    name: "BlockingRules",
                    path: "/admin/blocking-rules",
                    component: BlockingRules
                },
                {
                    name: "NodeBlacklist",
                    path: "/admin/node-blacklist",
                    component: NodeBlacklist
                },
                {
                    name: "UserWhoTriggeredBlockingRule",
                    path: "/admin/user-who-triggered-blocking-rule",
                    component: UserWhoTriggeredBlockingRule
                },
                {
                    name: "ProductList",
                    path: "/admin/product-list",
                    component: ProductList
                },
                {
                    name: "GlobalStatistics",
                    path: "/admin/global-statistics",
                    component: GlobalStatistics
                },
                {
                    name: "MemberOrder",
                    path: "/admin/member-order",
                    component: MemberOrder
                },
                {
                    name: "RegisteredUser",
                    path: "/admin/registered-user",
                    component: RegisteredUser
                },
                {
                    name: "CommissionSettlement",
                    path: "/admin/commission-settlement",
                    component: CommissionSettlement
                },
                {
                    name: "AgentList",
                    path: "/admin/agent-list",
                    component: AgentList
                },
                {
                    name: "AgentAdded",
                    path: "/admin/agent-added",
                    component: AgentAdded
                },
                {
                    name: "AccountSettings",
                    path: "/admin/account-settings",
                    component: AccountSettings
                },
                {
                    name: "MemberList",
                    path: "/admin/member-list",
                    component: MemberList
                },
                {
                    name: "OrderList",
                    path: "/admin/order-list",
                    component: OrderList
                },
                {
                    name: "TicketList",
                    path: "/admin/ticket-list",
                    component: TicketList
                },
                {
                    name: "AnnouncementList",
                    path: "/admin/announcement-list",
                    component: AnnouncementList
                },
                {
                    name: "SoftwareVersion",
                    path: "/admin/software-version",
                    component: SoftwareVersion
                },
                {
                    name: "GlobalConfiguration",
                    path: "/admin/global-configuration",
                    component: GlobalConfiguration
                },
                {
                    name: "WebOfficialConfiguration",
                    path: "/admin/web-official-configuration",
                    component: WebOfficialConfiguration
                },
                {
                    name: "SuperAdministratorAccount",
                    path: "/admin/super-administrator-account",
                    component: SuperAdministratorAccount
                },
                {
                    name: "LoginRecord",
                    path: "/admin/login-record/:id",
                    component: LoginRecord
                },
                {
                    name: "UserDetails",
                    path: "/admin/user-details",
                    component: UserDetails
                }
            ]
        },
        {
            path: "/agent",
            component: AgentDashboardLayout,
            children: [

                {
                    name: "SalesDashboard",
                    path: "/",
                    component: SalesDashboard
                },
                {
                    name: "AgentUserList",
                    path: "/agent/user-list",
                    component: AgentUserList
                },
                {
                    name: "AgentUserOrder",
                    path: "/agent/user-order",
                    component: AgentUserOrder
                },
                {
                    name: "AgentSettlementManagement",
                    path: "/agent/settlement-management",
                    component: AgentSettlementManagement
                },
                {
                    name: "AgentPlatformAnnouncement",
                    path: "/agent/platform-announcement",
                    component: AgentPlatformAnnouncement
                },
                {
                    name: "AgentAccountSettings",
                    path: "/agent/account-settings",
                    component: AgentAccountSettings
                },
                {
                    name: "SoftwareProfit",
                    path: "/agent/software-profit",
                    component: SoftwareProfit
                },
            ]
        },
        {
            path: "*",
            component: FullLayout,
        },

    ]
});

export default router;
