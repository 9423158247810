<template>
  <transition>
    <b-modal
        id="node-list-action-success"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal"
        centered
    >
      <template #modal-header="{ close }">
        <h5>解除黑名单</h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="country-box-info px-3 py-3">
          <div class="d-flex align-items-center">
            <div class="icon mr-3">
              <i class="fa-regular fa-circle-question warning"></i>
            </div>
            <div class="content">
              <h5 class="mb-2">确定删除该服务器</h5>
              <p>操作不可逆，谨慎操作</p>
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <b-button variant="modal-f-primary" @click="handleCOk(hide(),$bvToast.show('node-list-success-toast'))">
          确定
        </b-button>

        <b-button variant="modal-f-cancel mr-10" @click="cancel()">
          取消
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
export default {
  name: "NodeListActionBox",
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    },
    handleCOk($hide, $toster) {
      $hide();
      $toster();
    }
  }
}
</script>

<style scoped>

</style>
