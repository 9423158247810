<template>
  <b-toast :toast-class="`toast-delete`" id="node-list-success-toast" :variant="variant" toaster="b-toaster-bottom-right"
           solid>
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <slot name="title"></slot>
      </div>
    </template>
    <slot></slot>
  </b-toast>
</template>

<script>
export default {
  name: "NodeListSuccessToast",
  props: {
    variant: {
      type: String,
      required: true,
      default: 'success'
    }
  }
}
</script>

<style scoped>

</style>
