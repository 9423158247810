import { render, staticRenderFns } from "./AgentPlatformAnnouncement.vue?vue&type=template&id=0bce1e8e&scoped=true&"
import script from "./AgentPlatformAnnouncement.vue?vue&type=script&lang=js&"
export * from "./AgentPlatformAnnouncement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bce1e8e",
  null
  
)

export default component.exports