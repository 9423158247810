<template>
  <transition>
    <b-modal
        id="product-add"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="country-add-modal"
        centered
    >
      <template #modal-header="{ close }">
        <h5>新建商品</h5>
        <b-button variant="outline-danger" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="country-form-wrap">
          <form action="#">
            <div class="c-input-group">
              <label for="id"><span>*</span>名称:</label>
              <div class="input-wrap">
                <input type="text" id="id" placeholder="名称">
              </div>
            </div>
            <div class="c-input-group">
              <label for="name"><span>*</span>天数:</label>
              <div class="input-wrap">
                <input type="text" id="name" placeholder="天数">
              </div>
            </div>
            <div class="c-input-group">
              <label for="identifier"><span>*</span>原价:</label>
              <div class="input-wrap">
                <input type="text" id="identifier" placeholder="原价">
              </div>
            </div>
            <div class="c-input-group">
              <label for="price"><span>*</span>实际价格:</label>
              <div class="input-wrap">
                <input type="text" id="price" placeholder="实际价格">
              </div>
            </div>
            <div class="c-input-group">
              <label for="device"><span>*</span>登陆设备数量:</label>
              <div class="input-wrap">
                <input type="text" id="device" placeholder="登陆设备数量">
              </div>
            </div>
            <div class="c-input-group c-switch-group">
              <label for="c-check-2"><span>*</span>是否上架:</label>
              <div class="input-switch">
                <b-form-checkbox name="check-button" switch id="c-check-2" button-variant="success"></b-form-checkbox>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <div class="country-btn-wrap">
          <b-button variant="cancel" @click="cancel()">
            确定
          </b-button>

          <b-button variant="theme" @click="hide();showSuccessMessage('服务器添加成功', '服务器添加成功')">
            取消
          </b-button>
        </div>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "ProductAddBox",
  mixins: [toaster],
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
