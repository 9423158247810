<template>
  <div>
    <div class="sidebar-wrapper" :class="{active: isActive}">
      <!-- s-logo start -->
      <div class="s-logo">
        <router-link to="/">
          <img src="@/assets/img/logo.svg" alt="logo">
          <span>Sales Data Center</span>
        </router-link>
      </div>
      <!-- s-logo end -->

      <!-- sidebar-inner start -->
      <div class="sidebar-inner">
        <!-- main-menu start -->
        <div class="main-menu">
          <ul>
            <li>
              <router-link :to="{name: 'SalesDashboard'}">
                <img src="@/assets/img/icon/shorecut-menu.svg" alt="icon"> Software Profit
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'AgentUserList'}">
                <img src="@/assets/img/icon/node-management.svg" alt="icon"> user list
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'AgentUserOrder'}">
                <img src="@/assets/img/icon/commndity-management.svg" alt="icon"> User order
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'AgentSettlementManagement'}">
                <img src="@/assets/img/icon/agent-management.svg" alt="icon"> settlement management
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'AgentPlatformAnnouncement'}">
                <img src="@/assets/img/icon/operation-management.svg" alt="icon"> Platform announcement
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'AgentAccountSettings'}">
                <img src="@/assets/img/icon/system-settings.svg" alt="icon"> Account Settings
              </router-link>
            </li>
          </ul>
        </div>
        <!-- main-menu end -->
      </div>
      <!-- sidebar-inner end -->
    </div>
    <div class="sidebar-mask" :class="{active: isActive}" @click="$emit('closeSidebar')"></div>
  </div>
</template>

<script>
export default {
  name: "AgentSidebar",
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      menuOpen: '',
    }
  },
  methods: {
    // dropdown open close
    toggleMenu(data) {
      if (this.menuOpen === data) {
        this.menuOpen = ''
      } else {
        this.menuOpen = data
      }
    },

    // dropdown animation
    enter(el) {
      // set the element to his natural hight
      el.style.height = 'auto';
      /*
        get the calculated height.
        getComputedStyle returns an object containing all the CSS properties
        of the element after all active styles have been loaded and the basic
        calculations have been performed.
        We need the height after all basic calculations have been performed,
        then the height of each element here is dynamically,
        based on the number of items in the sublist.
      */
      const height = getComputedStyle(el).height;
      // set the height to zero for the opening animation
      el.style.height = 0;
      /*
        Force the repaint to make sure the animation is triggered correctly,
        then you can fire the method getComputedStyle again.
        This is not necessary, but sometimes the animation may not start depending on the case.
      */
      getComputedStyle(el);
      /*
        Set the height from the element to the calculated height.
        With setTimeout you make sure the browser has finished the painting
        after setting the height to zero.
      */
      setTimeout(() => {
        el.style.height = height;
      });
    },
    afterEnter(el) {
      el.style.height = 'auto';
    },
    leave(el) {
      /*
        Same as with the enter method, but only the other way around.
      */
      el.style.height = getComputedStyle(el).height;

      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = 0;
      });
    }
  }
}
</script>

<style scoped>

</style>
