<template>
  <transition>
    <b-modal
        id="member-state"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal-large"
        centered
    >
      <template #modal-header="{ close }">
        <h5>设置状态</h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="member-form-wrap">
          <form action="#">
            <div class="row">
              <div class="col-xl-12">
                <div class="c-input-group">
                  <label for="radio-1"><span>*</span> 选择状态:</label>
                  <div class="radio-wrap">
                    <div class="radio-label">
                      <input type="radio" id="radio-1" name="radio" value="免费会员" checked>
                      <label for="radio-1">启用</label>
                    </div>
                    <div class="radio-label">
                      <input type="radio" id="radio-2" name="radio" value="付费会员">
                      <label for="radio-2">禁用</label>
                    </div>
                  </div>
                </div>
                <div class="c-input-group align-items-start">
                  <label for="input-6"><span>*</span> 备注内容:</label>
                  <div class="input-wrap">
                    <textarea id="input-6" placeholder="备注内容"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <b-button variant="modal-f-primary" @click="hide();showSuccessMessage('服务器添加成功', '服务器添加成功')">
          确定
        </b-button>

        <b-button variant="modal-f-cancel mr-10" @click="cancel()">
          取消
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "MemberState",
  mixins: [toaster],
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
