<template>
  <div class="widget-section">
    <!-- commission-settlement-section start -->
    <div class="commission-settlement-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading settlement-heading">
                <h5 class="title">
                  结算管理 <span>每月1号结算上月收入，由于结算订单量大，结算时间会较长，建议合作伙伴耐心等待，2号再进行提现操作 <button v-b-tooltip.hover title="等待出具报表后,再进行提现操作"><i class="fa-solid fa-circle-info"></i></button></span>
                </h5>
              </div>
              <div class="admin-card-body">
                <div class="settlement-management-btn">
                  <div class="check-wrapper">
                    <div class="check-wrap">
                      <input type="radio" id="check-01" name="status">
                      <label for="check-01"><i class="fa-solid fa-circle-small"></i> 全部</label>
                    </div>
                    <div class="check-wrap danger">
                      <input type="radio" id="check-02" name="status">
                      <label for="check-02"><i class="fa-solid fa-circle-small"></i> 未申请结算</label>
                    </div>
                    <div class="check-wrap warning">
                      <input type="radio" id="check-03" name="status">
                      <label for="check-03"><i class="fa-solid fa-circle-small"></i> 等待支付中</label>
                    </div>
                    <div class="check-wrap success">
                      <input type="radio" id="check-04" name="status">
                      <label for="check-04"><i class="fa-solid fa-circle-small"></i> 已经支付</label>
                    </div>
                  </div>
                </div>
                <div class="agent-settlement-management-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="agent">日期</th>
                        <th scope="col" class="b-cycle">结算金额</th>
                        <th scope="col" class="status">状态</th>
                        <th scope="col" class="sales">支付方式</th>
                        <th scope="col" class="pending">汇款地址</th>
                        <th scope="col" class="method">申请提现时间</th>
                        <th scope="col" class="address">支付时间</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">2021/03/01 - 2021/03/31</td>
                        <td colspan="b-cycle">2500.99</td>
                        <td class="status">
                          <div class="status-text danger"><i class="fa-solid fa-circle-small"></i> 未申请结算</div>
                        </td>
                        <td colspan="sales">USD</td>
                        <td colspan="pending">12345678123456781234567812345678</td>
                        <td class="method">2020-11-25 23:26:08</td>
                        <td class="address">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="delete-btn" v-b-modal:settlement-management-add>
                              <i class="fa-regular fa-trash-can"></i> 申请提现
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">2021/03/01 - 2021/03/31</td>
                        <td colspan="b-cycle">2500.99</td>
                        <td class="status">
                          <div class="status-text warning"><i class="fa-solid fa-circle-small"></i> 未申请结算</div>
                        </td>
                        <td colspan="sales">USD</td>
                        <td colspan="pending">12345678123456781234567812345678</td>
                        <td class="method">2020-11-25 23:26:08</td>
                        <td class="address">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="info-btn">
                              <i class="fa-solid fa-xmark"></i> 待支付中
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">2021/03/01 - 2021/03/31</td>
                        <td colspan="b-cycle">2500.99</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 未申请结算</div>
                        </td>
                        <td colspan="sales">USD</td>
                        <td colspan="pending">12345678123456781234567812345678</td>
                        <td class="method">2020-11-25 23:26:08</td>
                        <td class="address">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="view-btn">
                              <i class="fa-solid fa-check"></i> 支付成功
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">2021/03/01 - 2021/03/31</td>
                        <td colspan="b-cycle">2500.99</td>
                        <td class="status">
                          <div class="status-text danger"><i class="fa-solid fa-circle-small"></i> 未申请结算</div>
                        </td>
                        <td colspan="sales">USD</td>
                        <td colspan="pending">12345678123456781234567812345678</td>
                        <td class="method">2020-11-25 23:26:08</td>
                        <td class="address">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="delete-btn">
                              <i class="fa-regular fa-trash-can"></i> 申请提现
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">2021/03/01 - 2021/03/31</td>
                        <td colspan="b-cycle">2500.99</td>
                        <td class="status">
                          <div class="status-text warning"><i class="fa-solid fa-circle-small"></i> 未申请结算</div>
                        </td>
                        <td colspan="sales">USD</td>
                        <td colspan="pending">12345678123456781234567812345678</td>
                        <td class="method">2020-11-25 23:26:08</td>
                        <td class="address">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="info-btn">
                              <i class="fa-solid fa-xmark"></i> 待支付中
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">2021/03/01 - 2021/03/31</td>
                        <td colspan="b-cycle">2500.99</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 未申请结算</div>
                        </td>
                        <td colspan="sales">USD</td>
                        <td colspan="pending">12345678123456781234567812345678</td>
                        <td class="method">2020-11-25 23:26:08</td>
                        <td class="address">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="view-btn">
                              <i class="fa-solid fa-check"></i> 支付成功
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">2021/03/01 - 2021/03/31</td>
                        <td colspan="b-cycle">2500.99</td>
                        <td class="status">
                          <div class="status-text danger"><i class="fa-solid fa-circle-small"></i> 未申请结算</div>
                        </td>
                        <td colspan="sales">USD</td>
                        <td colspan="pending">12345678123456781234567812345678</td>
                        <td class="method">2020-11-25 23:26:08</td>
                        <td class="address">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="delete-btn">
                              <i class="fa-regular fa-trash-can"></i> 申请提现
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">2021/03/01 - 2021/03/31</td>
                        <td colspan="b-cycle">2500.99</td>
                        <td class="status">
                          <div class="status-text warning"><i class="fa-solid fa-circle-small"></i> 未申请结算</div>
                        </td>
                        <td colspan="sales">USD</td>
                        <td colspan="pending">12345678123456781234567812345678</td>
                        <td class="method">2020-11-25 23:26:08</td>
                        <td class="address">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="info-btn">
                              <i class="fa-solid fa-xmark"></i> 待支付中
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="agent">2021/03/01 - 2021/03/31</td>
                        <td colspan="b-cycle">2500.99</td>
                        <td class="status">
                          <div class="status-text success"><i class="fa-solid fa-circle-small"></i> 未申请结算</div>
                        </td>
                        <td colspan="sales">USD</td>
                        <td colspan="pending">12345678123456781234567812345678</td>
                        <td class="method">2020-11-25 23:26:08</td>
                        <td class="address">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="view-btn">
                              <i class="fa-solid fa-check"></i> 支付成功
                            </button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- commission-settlement-section end -->
    <SettlementManagementAdd />
  </div>
</template>

<script>
import SettlementManagementAdd from "@/components/Modals/SettlementManagementAddd";
export default {
  name: "AgentSettlementManagement",
  components: {SettlementManagementAdd}
}
</script>

<style scoped>

</style>
