<template>
  <div class="widget-section">

    <!-- country-list-section start -->
    <div class="country-list-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-body">
                <div class="country-list-btn">
                  <button class="c-add-btn" v-b-modal:country-add><i class="fa-solid fa-plus"></i> 新建国家
                  </button>
                  <button class="c-cancel-btn"><i class="fa-solid fa-flip-horizontal fa-arrow-rotate-right"></i> 刷新
                  </button>
                </div>
                <div class="country-list-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="check"><input type="checkbox"></th>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="c-name">国家名称</th>
                        <th scope="col" class="c-mark">标记</th>
                        <th scope="col" class="c-icon">国家图标</th>
                        <th scope="col" class="status">前端是否显示</th>
                        <th scope="col" class="date">创建时间</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">1</td>
                        <td class="c-name">美国</td>
                        <td class="c-mark">United States</td>
                        <td class="c-icon"><img src="@/assets/img/icon/flug-1.png" alt=""></td>
                        <td class="status">
                          <div class="status-text warning">● 未显示</div>
                        </td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link to="/node-list" class="view-btn">
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </router-link>
                            <a href="#" class="edit-btn" v-b-modal:country-add><i class="fa-solid fa-plus"></i> 编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:country-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">1</td>
                        <td class="c-name">美国</td>
                        <td class="c-mark">United States</td>
                        <td class="c-icon"><img src="@/assets/img/icon/flug-2.png" alt=""></td>
                        <td class="status">
                          <div class="status-text success">● 未显示</div>
                        </td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link to="/node-list" class="view-btn">
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </router-link>
                            <a href="#" class="edit-btn" v-b-modal:country-add><i class="fa-solid fa-plus"></i> 编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:country-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">1</td>
                        <td class="c-name">美国</td>
                        <td class="c-mark">United States</td>
                        <td class="c-icon"><img src="@/assets/img/icon/flug-3.png" alt=""></td>
                        <td class="status">
                          <div class="status-text success">● 未显示</div>
                        </td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link to="/node-list" class="view-btn">
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </router-link>
                            <a href="#" class="edit-btn" v-b-modal:country-add><i class="fa-solid fa-plus"></i> 编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:country-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>

                    </table>
                    <div class="daily-table-pagination mt-30 mb-20">
                      <ul>
                        <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><span>...</span></li>
                        <li><a href="#">50</a></li>
                        <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                      </ul>
                      <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                        <select>
                          <option value="10">10 / Page</option>
                          <option value="10">20 / Page</option>
                          <option value="10">30 / Page</option>
                          <option value="10">40 / Page</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- country-list-section end -->

    <CountryAdd/>
    <CountryDelete/>

    <CountryDangerToast variant="danger">
      <template #title><i class="fa-regular fa-circle-exclamation"></i> 服务器添加成功</template>
      <p> 服务器添加成功</p>
    </CountryDangerToast>

    <CountrySuccessToast variant="success">
      <template #title><i class="fa-regular fa-circle-check"></i> 服务器添加成功</template>
      <p> 服务器添加成功</p>
    </CountrySuccessToast>
  </div>
</template>

<script>
import CountryDelete from "@/components/Modals/CountryDelete";
import CountrySuccessToast from "@/components/Toast/CountrySuccessToast";
import CountryDangerToast from "@/components/Toast/CountryDangerToast";
import CountryAdd from "@/components/Modals/CountryAdd";

export default {
  name: "SoftwareProfit",
  components: {CountryDelete, CountrySuccessToast, CountryDangerToast, CountryAdd}
}
</script>

<style scoped>

</style>