<template>
  <transition>
    <b-modal
        id="order-message-replay"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        hide-header
        dialog-class="order-message-modal"
    >
      <template #default="">
        <div class="order-message-wrap">
          <div class="order-head">
            <div class="icon">
              <i class="fa-solid fa-file-lines"></i>
            </div>
            <div class="content">
              <h3>处理客户反馈问题</h3>
              <p>
                20208447578
                <span>
                  <i class="fa-solid fa-circle-small"></i> 待处理(已等待12小时30分钟)
                </span>
              </p>
            </div>
          </div>
          <div class="message-top mb-50">
            <h4 class="title">工单描述</h4>
            <div class="description">
              <p>
                Customers have reported problems encountered in the use of the product and hoped for technical
                assistance. Customers have reported problems encountered in the use of the product and hoped for
                technical assistance. Customers have reported problems encountered in the use of the product and hoped
                for technical assistance. Customers have reported problems encountered in the use of the product and
                hoped for technical assistance. Customers have reported problems encountered in the use of the product
                and hoped for technical assistance. Customers have reported problems encountered in the use of the
                product and hoped for technical assistance. Customers have reported problems encountered in the use of
                the product and hoped for technical assistance. Customers have reported problems encountered in the use
                of the product and hoped for technical assistance. Customers have reported problems encountered in the
                use of the product and hoped for technical assistance.
              </p>
            </div>
          </div>
          <div class="message-top">
            <h4 class="title">工单回复处理意见（直发内容至客户邮箱）</h4>
            <div class="order-message-form">
              <div class="input-wrap">
                <wysiwyg v-model="description"/>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer="{ hide, cancel }">
        <b-button variant="order-f-primary" @click="hide();">
          确定
        </b-button>

        <b-button variant="order-f-cancel mr-10" @click="cancel()">
          取消
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
export default {
  name: "OrderMessageReplay",
  data() {
    return {
      description: ''
    }
  },

  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
