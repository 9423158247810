<template>
  <div class="widget-section">
    <!-- global-configuration-section start -->
    <div class="global-configuration-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading config-heading">
                <h5 class="title">管理员账号 <span><b>*</b> 为必填项</span></h5>
              </div>
              <div class="admin-card-body">
                <div class="global-configuration-form">
                  <div class="row justify-content-center">
                    <div class="col-xl-6">
                      <div class="g-input-group">
                        <label for="input-1"><span>*</span> Username:</label>
                        <div class="input-wrap">
                          <input type="text" id="input-1" placeholder="username">
                        </div>
                      </div>
                      <div class="g-input-group">
                        <label for="input-2"><span>*</span> Password:</label>
                        <div class="input-wrap">
                          <input type="password" id="input-2" placeholder="password">
                        </div>
                      </div>
                      <div class="g-input-group">
                        <label for="input-3"><span>*</span> Aes填充:</label>
                        <div class="select-wrap">
                          <select id="input-3">
                            <option value="">Admin</option>
                            <option value="">User</option>
                            <option value="">Agent</option>
                          </select>
                          <div class="icon"><i class="fa-solid fa-angle-down"></i></div>
                        </div>
                      </div>
                      <div class="g-button-group">
                        <button class="g-primary">提交</button>
                        <button class="g-danger">重置</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- global-configuration-section end -->
  </div>
</template>

<script>
export default {
  name: "SuperAdministratorAccount"
}
</script>

<style scoped>

</style>
