import { render, staticRenderFns } from "./OrderMessageReplay.vue?vue&type=template&id=1d72a572&scoped=true&"
import script from "./OrderMessageReplay.vue?vue&type=script&lang=js&"
export * from "./OrderMessageReplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d72a572",
  null
  
)

export default component.exports