<template>
  <div class="widget-section">
    <!-- registered-user-section start -->
    <div class="registered-user-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">用户管理</h5>
              </div>
              <div class="admin-card-body">
                <div class="member-order-btn">
                  <div class="input-wrap">
                    <input type="text">
                  </div>
                  <div class="input-wrap">
                    <div class="date-picker">
                      <date-range-picker
                          v-model="dateRange"
                          :locale-data="{
                        direction: 'ltr',
                        format: 'yyyy/mm/dd',
                        separator: ' - ',
                        applyLabel: 'Apply',
                        cancelLabel: 'Cancel',
                        weekLabel: 'W',
                        customRangeLabel: 'Custom Range',
                        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 0,}"
                          :show-week-numbers="false"
                          :append-to-body="true"
                          :ranges="false">
                      </date-range-picker>
                    </div>
                  </div>
                  <button class="c-add-btn">
                    <i class="fa-regular fa-magnifying-glass"></i> 查询
                  </button>
                  <button class="c-cancel-btn">
                    <i class="fa-solid fa-flip-horizontal fa-arrow-rotate-right"></i> 重置
                  </button>
                </div>
                <div class="agent-user-list-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="email">邮箱</th>
                        <th scope="col" class="status">会员类型</th>
                        <th scope="col" class="ip">注册IP</th>
                        <th scope="col" class="country">注册国家</th>
                        <th scope="col" class="device">注册设备ID</th>
                        <th scope="col" class="r-time">注册时间</th>
                        <th scope="col" class="e-date">会员到期时间</th>
                        <th scope="col" class="agent">代理商</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td colspan="status">
                          <div class="status-text success">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">巴基斯坦</td>
                        <td class="device">iPhone 12 Pro Max</td>
                        <td class="r-time">2020-11-25 23:26:08</td>
                        <td class="e-date">2020-11-25 23:26:08</td>
                        <td class="agent">youtube</td>
                      </tr>
                      <tr>
                        <td class="serial">2</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td colspan="status">
                          <div class="status-text danger">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">美国</td>
                        <td class="device">HuaWei Android</td>
                        <td class="r-time">2020-11-25 23:26:08</td>
                        <td class="e-date">2020-11-25 23:26:08</td>
                        <td class="agent">youtube</td>
                      </tr>
                      <tr>
                        <td class="serial">2</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td colspan="status">
                          <div class="status-text danger">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">美国</td>
                        <td class="device">HuaWei Android</td>
                        <td class="r-time">2020-11-25 23:26:08</td>
                        <td class="e-date">2020-11-25 23:26:08</td>
                        <td class="agent">youtube</td>
                      </tr>
                      <tr>
                        <td class="serial">2</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td colspan="status">
                          <div class="status-text danger">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">美国</td>
                        <td class="device">HuaWei Android</td>
                        <td class="r-time">2020-11-25 23:26:08</td>
                        <td class="e-date">2020-11-25 23:26:08</td>
                        <td class="agent">youtube</td>
                      </tr>
                      <tr>
                        <td class="serial">2</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td colspan="status">
                          <div class="status-text danger">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">美国</td>
                        <td class="device">HuaWei Android</td>
                        <td class="r-time">2020-11-25 23:26:08</td>
                        <td class="e-date">2020-11-25 23:26:08</td>
                        <td class="agent">youtube</td>
                      </tr>
                      <tr>
                        <td class="serial">2</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td colspan="status">
                          <div class="status-text danger">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">美国</td>
                        <td class="device">HuaWei Android</td>
                        <td class="r-time">2020-11-25 23:26:08</td>
                        <td class="e-date">2020-11-25 23:26:08</td>
                        <td class="agent">youtube</td>
                      </tr>
                      <tr>
                        <td class="serial">2</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td colspan="status">
                          <div class="status-text danger">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">美国</td>
                        <td class="device">HuaWei Android</td>
                        <td class="r-time">2020-11-25 23:26:08</td>
                        <td class="e-date">2020-11-25 23:26:08</td>
                        <td class="agent">youtube</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- registered-user-section end -->
  </div>
</template>

<script>
export default {
  name: "AgentUserList",
  data() {
    return {
      dateRange: {
        startDate: '',
        endDate: ''
      },
    }
  }
}
</script>

<style scoped>

</style>
