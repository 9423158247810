<template>
  <transition>
    <b-modal
        id="node-edit"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal-large"
        centered
    >
      <template #modal-header="{ close }">
        <h5>编辑节点屏蔽规则 <span><b>*</b> 为必填项</span></h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="member-form-wrap">
          <form action="#">
            <div class="row pt-20 pb-50">
              <div class="col-xl-10">
                <div class="c-input-group">
                  <label for="input-6"><span>*</span> 选择屏蔽规则:</label>
                  <div class="select-wrap">
                    <select id="input-6">
                      <option value="">选择屏蔽规则1</option>
                      <option value="">选择屏蔽规则2</option>
                      <option value="">选择屏蔽规则3</option>
                      <option value="">选择屏蔽规则4</option>
                      <option value="">选择屏蔽规则5</option>
                      <option value="">选择屏蔽规则6</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <b-button variant="modal-f-primary" @click="hide();showSuccessMessage('服务器添加成功', '服务器添加成功')">
          确定
        </b-button>

        <b-button variant="modal-f-cancel mr-10" @click="cancel()">
          取消
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "NodeEdit",
  mixins: [toaster],
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
