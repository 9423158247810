<template>
  <div class="widget-section">

    <!-- d-card-section-3 start -->
    <div class="d-card-section-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-25 col-lg-4 col-md-6">
            <div class="d-card-wrap-3 mb-30">
              <div class="d-card-icon">
                <img src="@/assets/img/icon/icon-1.svg" alt="icon">
              </div>
              <div class="d-card-body">
                <h4>200000</h4>
                <p>会员总人数</p>
              </div>
            </div>
          </div>
          <div class="col-xl-25 col-lg-4 col-md-6">
            <div class="d-card-wrap-3 mb-30">
              <div class="d-card-icon success">
                <img src="@/assets/img/icon/icon-2.svg" alt="icon">
              </div>
              <div class="d-card-body">
                <h4>65656<span>.10$</span></h4>
                <p>会员充值总金额</p>
              </div>
            </div>
          </div>
          <div class="col-xl-25 col-lg-4 col-md-6">
            <div class="d-card-wrap-3 mb-30">
              <div class="d-card-icon warning">
                <img src="@/assets/img/icon/icon-3.svg" alt="icon">
              </div>
              <div class="d-card-body">
                <h4>19532</h4>
                <p>今日注册会员数量</p>
              </div>
            </div>
          </div>
          <div class="col-xl-25 col-lg-4 col-md-6">
            <div class="d-card-wrap-3 mb-30">
              <div class="d-card-icon light-blue">
                <img src="@/assets/img/icon/icon-4.svg" alt="icon">
              </div>
              <div class="d-card-body">
                <h4>2000</h4>
                <p>今日消费笔数</p>
              </div>
            </div>
          </div>
          <div class="col-xl-25 col-lg-4 col-md-6">
            <div class="d-card-wrap-3 mb-30">
              <div class="d-card-icon light-pink">
                <img src="@/assets/img/icon/icon-5.svg" alt="icon">
              </div>
              <div class="d-card-body">
                <h4>2000<span>.00</span></h4>
                <p>今日充值金额</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- d-card-section-3 end -->

    <!-- member-area-ranking-section start -->
    <div class="member-area-ranking-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-7">
            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <div class="member-ranking-heading">
                  <h4 class="title">会员地区排行</h4>

                  <div class="member-ranking-heading-right">
                    <b-tabs class="member-ranking-tabs">
                      <b-tab active>
                        <template #title>本周</template>
                      </b-tab>
                      <b-tab>
                        <template #title>本月</template>
                      </b-tab>
                      <b-tab>
                        <template #title>今年</template>
                      </b-tab>
                    </b-tabs>
                    <div class="date-picker">
                      <date-range-picker
                          v-model="dateRange"
                          :locale-data="{
                        direction: 'ltr',
                        format: 'yyyy/mm/dd',
                        separator: ' - ',
                        applyLabel: 'Apply',
                        cancelLabel: 'Cancel',
                        weekLabel: 'W',
                        customRangeLabel: 'Custom Range',
                        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 0,}"
                          :show-week-numbers="false"
                          :append-to-body="true"
                          :ranges="false">
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="admin-card-body">
                <div class="member-area-ranking">
                  <div class="map-wrap">
                    <GoogleMap/>
                  </div>
                  <div class="table-responsive-wrapper">
                    <table class="table">
                      <tbody>
                      <tr>
                        <th scope="col" class="ranking">排名</th>
                        <th scope="col" class="area">地区</th>
                        <th scope="col" class="growth">增长人数</th>
                        <th scope="col" class="rate">增长占比</th>
                        <th scope="col" class="members">会员人数</th>
                        <th scope="col" class="people">人数占比</th>
                      </tr>
                      <tr>
                        <td class="ranking">01</td>
                        <td class="area">俄罗斯</td>
                        <td class="growth">9000</td>
                        <td class="rate">60%</td>
                        <td class="members">90000</td>
                        <td class="people">
                          <div class="people-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="60"></b-progress-bar>
                            </b-progress>
                            <span>60%</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="ranking">02</td>
                        <td class="area">日本</td>
                        <td class="growth">9000</td>
                        <td class="rate">50%</td>
                        <td class="members">90000</td>
                        <td class="people">
                          <div class="people-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="50"></b-progress-bar>
                            </b-progress>
                            <span>50%</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="ranking">03</td>
                        <td class="area">阿联酋</td>
                        <td class="growth">9000</td>
                        <td class="rate">80%</td>
                        <td class="members">90000</td>
                        <td class="people">
                          <div class="people-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="80"></b-progress-bar>
                            </b-progress>
                            <span>80%</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="ranking">04</td>
                        <td class="area">加拿大</td>
                        <td class="growth">9000</td>
                        <td class="rate">60%</td>
                        <td class="members">90000</td>
                        <td class="people">
                          <div class="people-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="60"></b-progress-bar>
                            </b-progress>
                            <span>60%</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="ranking">05</td>
                        <td class="area">巴基斯坦</td>
                        <td class="growth">9000</td>
                        <td class="rate">90%</td>
                        <td class="members">6000</td>
                        <td class="people">
                          <div class="people-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="90"></b-progress-bar>
                            </b-progress>
                            <span>90%</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="ranking">06</td>
                        <td class="area">俄罗斯</td>
                        <td class="growth">9000</td>
                        <td class="rate">60%</td>
                        <td class="members">90000</td>
                        <td class="people">
                          <div class="people-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="60"></b-progress-bar>
                            </b-progress>
                            <span>60%</span>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5">
            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <h4 class="title">会员等级统计</h4>
              </div>
              <div class="admin-card-body">
                <div class="member-client-distribution-statistics">
                  <div class="chart-wrap">
                    <v-chart :option="sChart1" class="chart"/>
                  </div>
                  <div class="table-responsive-wrapper">
                    <table class="table">
                      <tbody>
                      <tr>
                        <th scope="col" class="grade">等级</th>
                        <th scope="col" class="quantity">数量</th>
                        <th scope="col" class="proportion">占比</th>
                      </tr>
                      <tr>
                        <td class="grade light-pink"><i class="fa-solid fa-circle-small"></i> 免费会员</td>
                        <td class="quantity">40000</td>
                        <td class="proportion">40%</td>
                      </tr>
                      <tr>
                        <td class="grade light-blue"><i class="fa-solid fa-circle-small"></i> 包月会员</td>
                        <td class="quantity">25000</td>
                        <td class="proportion">25%</td>
                      </tr>
                      <tr>
                        <td class="grade warning"><i class="fa-solid fa-circle-small"></i> 季度会员</td>
                        <td class="quantity">15000</td>
                        <td class="proportion">15%</td>
                      </tr>
                      <tr>
                        <td class="grade success"><i class="fa-solid fa-circle-small"></i> 半年会员</td>
                        <td class="quantity">10000</td>
                        <td class="proportion">10%</td>
                      </tr>
                      <tr>
                        <td class="grade blue"><i class="fa-solid fa-circle-small"></i> 年度会员</td>
                        <td class="quantity">10000</td>
                        <td class="proportion">10%</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <h4 class="title">会员客户端分布统计</h4>
              </div>
              <div class="admin-card-body">
                <div class="member-client-distribution-statistics">
                  <div class="chart-wrap">
                    <v-chart :option="sChart2" class="chart"/>
                  </div>
                  <div class="table-responsive-wrapper">
                    <table class="table">
                      <tbody>
                      <tr>
                        <th scope="col" class="grade">类型</th>
                        <th scope="col" class="quantity">数量</th>
                        <th scope="col" class="proportion">占比</th>
                      </tr>
                      <tr>
                        <td class="grade purple"><i class="fa-solid fa-circle-small"></i> Windows PC</td>
                        <td class="quantity">40000</td>
                        <td class="proportion">40%</td>
                      </tr>
                      <tr>
                        <td class="grade green"><i class="fa-solid fa-circle-small"></i> iOS APP</td>
                        <td class="quantity">25000</td>
                        <td class="proportion">25%</td>
                      </tr>
                      <tr>
                        <td class="grade app"><i class="fa-solid fa-circle-small"></i> Android APP</td>
                        <td class="quantity">15000</td>
                        <td class="proportion">15%</td>
                      </tr>
                      <tr>
                        <td class="grade light-pink"><i class="fa-solid fa-circle-small"></i> Mac OS</td>
                        <td class="quantity">10000</td>
                        <td class="proportion">10%</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- member-area-ranking-section end -->

    <!-- daily-consumption-trend-section start -->
    <div class="daily-consumption-trend-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <h4 class="title">
                  会员每日消费趋势
                  <span class="text-danger">（注释：开发过程中，用订单笔数和每日收入2组数据，取每日数据，取12天的每日数据）</span>
                </h4>
              </div>
              <div class="admin-card-body">
                <div class="daily-consumption-trend">
                  <div class="chart-wrap">
                    <v-chart :option="bChart1" class="chart"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daily-consumption-trend-section end -->

    <!-- daily-consumption-trend-section start -->
    <div class="daily-consumption-trend-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <h4 class="title">
                  会员注册量增长趋势
                  <span class="text-danger">
                    （注释：如果方便，可将会员增长趋势放在
                    <span class="text-primary">“会员每日消费趋势”</span>
                    用3柱图展示，也无所谓）
                  </span>
                </h4>
              </div>
              <div class="admin-card-body">
                <div class="daily-consumption-trend">
                  <div class="chart-wrap">
                    <v-chart :option="bChart2" class="chart"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- daily-consumption-trend-section end -->

  </div>
</template>

<script>
import GoogleMap from "@/components/GooleMap";

export default {
  name: "UserDistributionDataMonitoring",
  components: {GoogleMap},
  data() {
    return {
      dateRange: {
        startDate: '',
        endDate: ''
      },
      sChart1: {
        title: {
          // text: "会员等级统计",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: " {b} : {c} ({d}%)"
        },
        // legend: {
        //   orient: "vertical",
        //   left: "left",
        //   data: [
        //     "免费会员",
        //     "包月会员",
        //     "季度会员",
        //     "半年会员",
        //     "年度会员"
        //   ]
        // },
        colorBy: "series",
        color: [
          '#FF7A8C',
          '#52C1F5',
          '#FFC542',
          '#24D2D3',
          '#73A0FA',
        ],
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['55%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '12',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 40000, name: '免费会员'},
              {value: 25000, name: '包月会员'},
              {value: 15000, name: '季度会员'},
              {value: 10000, name: '半年会员'},
              {value: 10000, name: '年度会员'},
            ]
          }
        ]
      },
      sChart2: {
        title: {
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: " {b} : {c} ({d}%)"
        },
        colorBy: "series",
        color: [
          '#8167F5',
          '#51D351',
          '#52C1F5',
          '#24D2D3',
          '#FF7A8C',
        ],
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['55%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '12',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 40000, name: 'Windows PC'},
              {value: 25000, name: 'iOS APP'},
              {value: 15000, name: 'Android APP'},
              {value: 10000, name: 'Mac OS'},
            ]
          }
        ]
      },
      bChart1: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['2020-01', '2020-02', '2020-03', '2020-04', '2020-05', '2020-06', '2020-07', '2020-08', '2020-09', '2020-10', '2020-11', '2020-12']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        colorBy: "series",
        color: [
          '#43D8DA',
          '#FD8D9D',
        ],
        series: [
          {
            name: '消费金额',
            type: 'bar',
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            data: [390000, 420000, 570000, 850000, 1190000, 1520000, 1700000, 1660000, 1420000, 1030000, 660000, 480000]
          },
          {
            name: '充值金额',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: [700000, 690000, 950000, 1450000, 185000, 2150000, 2520000, 2650000, 2450000, 1840000, 1490000, 690000]
          },
        ]
      },
      bChart2: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['2020-01', '2020-02', '2020-03', '2020-04', '2020-05', '2020-06', '2020-07', '2020-08', '2020-09', '2020-10', '2020-11', '2020-12']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        colorBy: "series",
        color: [
          '#FD8D9D',
        ],
        series: [
          {
            name: '充值金额',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: [700, 1490, 1290, 1060, 2010, 1990, 2402, 1890, 2640, 1490, 970, 620],
          },
        ]
      },
    }
  }
}
</script>

<style scoped>

</style>
