<template>
  <transition>
    <b-modal
        id="software-details"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal"
        hide-footer
        centered
    >
      <template #modal-header="{ close }">
        <h5>版本信息</h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="software-box-info px-3 py-3">
          <p>上架市场:Windows</p>
          <p>软件版本号:v1.2.16</p>
          <p>下载链接:https://www.apple.com/app/1.0</p>
          <p>版本说明:Because apple's privacy rules in the US are more detailed, they need to be updated</p>
          <p>版本发布时间:2020-12-23 22:31</p>
        </div>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "SoftwareDetails",
  mixins: [toaster],
  methods: {
    handleOk() {
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
