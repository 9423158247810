<template>
  <div class="widget-section">
    <!-- member-list-section start -->
    <div class="member-list-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">用户管理</h5>
              </div>
              <div class="admin-card-body">
                <div class="member-list-btn">
                  <div class="input-wrap">
                    <input type="text" placeholder="免费会员">
                  </div>
                  <div class="select-wrap">
                    <select>
                      <option value="0">免费会员</option>
                      <option value="0">免费会员</option>
                      <option value="0">免费会员</option>
                    </select>
                    <div class="icon"><i data-v-548a87ca="" class="fa-solid fa-angle-down"></i></div>
                  </div>
                  <div class="select-wrap">
                    <select>
                      <option value="0">免费会员</option>
                      <option value="0">免费会员</option>
                      <option value="0">免费会员</option>
                    </select>
                    <div class="icon"><i class="fa-solid fa-angle-down"></i></div>
                  </div>
                  <div class="input-wrap">
                    <input type="text" placeholder="免费会员">
                  </div>
                  <div class="search-wrap">
                    <input type="text" placeholder="搜索节点列表">
                    <button><i class="fa-regular fa-magnifying-glass"></i> 查询</button>
                  </div>
                  <button class="c-add-btn" v-b-modal:member-add>
                    <i class="fa-solid fa-plus"></i> 新建商品
                  </button>
                  <button class="c-cancel-btn">
                    <i class="fa-solid fa-flip-horizontal fa-arrow-rotate-right"></i> 刷新
                  </button>
                </div>
                <div class="member-list-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="email">邮箱</th>
                        <th scope="col" class="status">会员类型</th>
                        <th scope="col" class="ip">注册IP</th>
                        <th scope="col" class="country">注册国家</th>
                        <th scope="col" class="device">注册设备ID</th>
                        <th scope="col" class="date">会员到期时间</th>
                        <th scope="col" class="agent">代理商</th>
                        <th scope="col" class="d-login">设备登录</th>
                        <th scope="col" class="up-stream">总上行(GB)</th>
                        <th scope="col" class="down-link">总下行(GB)</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td class="status">
                          <div class="status-text success">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">巴基斯坦</td>
                        <td colspan="device">iPhone 12 Pro Max</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="date">无代理</td>
                        <td class="agent">3/6</td>
                        <td class="up-stream">12</td>
                        <td class="down-link">12</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link :to="{name: 'UserDetails'}" class="edit-btn">
                              <i class="fa-regular fa-pencil"></i> 编辑
                            </router-link>
                            <a href="#" class="delete-btn" v-b-modal:member-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td class="status">
                          <div class="status-text success">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">巴基斯坦</td>
                        <td colspan="device">iPhone 12 Pro Max</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="date">无代理</td>
                        <td class="agent">3/6</td>
                        <td class="up-stream">12</td>
                        <td class="down-link">12</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link :to="{name: 'UserDetails'}" class="edit-btn">
                              <i class="fa-regular fa-pencil"></i> 编辑
                            </router-link>
                            <a href="#" class="delete-btn" v-b-modal:member-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td class="status">
                          <div class="status-text success">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">巴基斯坦</td>
                        <td colspan="device">iPhone 12 Pro Max</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="date">无代理</td>
                        <td class="agent">3/6</td>
                        <td class="up-stream">12</td>
                        <td class="down-link">12</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link :to="{name: 'UserDetails'}" class="edit-btn">
                              <i class="fa-regular fa-pencil"></i> 编辑
                            </router-link>
                            <a href="#" class="delete-btn" v-b-modal:member-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td class="status">
                          <div class="status-text success">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">巴基斯坦</td>
                        <td colspan="device">iPhone 12 Pro Max</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="date">无代理</td>
                        <td class="agent">3/6</td>
                        <td class="up-stream">12</td>
                        <td class="down-link">12</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link :to="{name: 'UserDetails'}" class="edit-btn">
                              <i class="fa-regular fa-pencil"></i> 编辑
                            </router-link>
                            <a href="#" class="delete-btn" v-b-modal:member-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td class="status">
                          <div class="status-text success">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">巴基斯坦</td>
                        <td colspan="device">iPhone 12 Pro Max</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="date">无代理</td>
                        <td class="agent">3/6</td>
                        <td class="up-stream">12</td>
                        <td class="down-link">12</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link :to="{name: 'UserDetails'}" class="edit-btn">
                              <i class="fa-regular fa-pencil"></i> 编辑
                            </router-link>
                            <a href="#" class="delete-btn" v-b-modal:member-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td class="status">
                          <div class="status-text success">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">巴基斯坦</td>
                        <td colspan="device">iPhone 12 Pro Max</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="date">无代理</td>
                        <td class="agent">3/6</td>
                        <td class="up-stream">12</td>
                        <td class="down-link">12</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link :to="{name: 'UserDetails'}" class="edit-btn">
                              <i class="fa-regular fa-pencil"></i> 编辑
                            </router-link>
                            <a href="#" class="delete-btn" v-b-modal:member-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td class="status">
                          <div class="status-text success">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">巴基斯坦</td>
                        <td colspan="device">iPhone 12 Pro Max</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="date">无代理</td>
                        <td class="agent">3/6</td>
                        <td class="up-stream">12</td>
                        <td class="down-link">12</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link :to="{name: 'UserDetails'}" class="edit-btn">
                              <i class="fa-regular fa-pencil"></i> 编辑
                            </router-link>
                            <a href="#" class="delete-btn" v-b-modal:member-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="email">sadsafg@gmail.com</td>
                        <td class="status">
                          <div class="status-text success">付费会员</div>
                        </td>
                        <td colspan="ip">192.168.222.102</td>
                        <td colspan="country">巴基斯坦</td>
                        <td colspan="device">iPhone 12 Pro Max</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="date">无代理</td>
                        <td class="agent">3/6</td>
                        <td class="up-stream">12</td>
                        <td class="down-link">12</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <router-link :to="{name: 'UserDetails'}" class="edit-btn">
                              <i class="fa-regular fa-pencil"></i> 编辑
                            </router-link>
                            <a href="#" class="delete-btn" v-b-modal:member-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- member-list-section end -->
    <MemberAdd/>
    <MemberDelete/>
  </div>
</template>

<script>
import MemberAdd from "@/components/Modals/MemberAdd";
import MemberDelete from "@/components/Modals/MemberDelete";

export default {
  name: "MemberList",
  components: {MemberDelete, MemberAdd}
}
</script>

<style scoped>

</style>
