<template>
  <div class="widget-section">

    <!-- d-card-section start -->
    <div class="d-card-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-25 col-md-6">
            <div class="d-card-wrap-4 mb-30">
              <div class="d-card-head">
                <p>本月销售金额($)</p>
                <h4>1200</h4>
              </div>
              <div class="d-card-body">
                <p>上月销售金额($) <span class="ml-20">3600</span></p>
              </div>
            </div>
          </div>
          <div class="col-xl-25 col-md-6">
            <div class="d-card-wrap-4 mb-30">
              <div class="d-card-head">
                <p>本月总分润($)</p>
                <h4>600</h4>
              </div>
              <div class="d-card-body">
                <p>上月销售金额($) <span class="ml-20">3600</span></p>
              </div>
            </div>
          </div>
          <div class="col-xl-25 col-md-6">
            <div class="d-card-wrap-4 d-card-bar-chart mb-30">
              <div class="d-card-head">
                <p>本月待结算($)</p>
                <h4>320</h4>
              </div>
              <div class="d-card-body">
                <div class="chart-wrap">
                  <v-chart :option="sChart2" class="chart"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-25 col-md-6">
            <div class="d-card-wrap-4 d-card-chart mb-30">
              <div class="d-card-head">
                <p>本月已结算($)</p>
                <h4>280</h4>
              </div>
              <div class="d-card-body">
                <div class="chart-wrap">
                  <v-chart :option="sChart1" class="chart"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-25 col-md-6">
            <div class="d-card-wrap-4 d-card-progress mb-30">
              <div class="d-card-head">
                <p>代理商总会员数</p>
                <h4>1623</h4>
              </div>
              <div class="d-card-body">
                <div class="progress-wrap">
                  <b-progress :max="100">
                    <b-progress-bar :value="75" variant="primary"></b-progress-bar>
                  </b-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- d-card-section end -->

    <div class="global-statistics-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-8">
            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <h4 class="title">
                  数据简报(同比上月)
                </h4>
              </div>
              <div class="admin-card-body">
                <div class="data-briefing-wrapper">
                  <div class="data-briefing">
                    <p>本月注册用户</p>
                    <h3>500</h3>
                  </div>
                  <div class="data-briefing">
                    <p>本月总订单</p>
                    <h3>1200</h3>
                  </div>
                  <div class="data-briefing">
                    <p>本月已付款订单</p>
                    <h3>500</h3>
                  </div>
                  <div class="data-briefing">
                    <p>本月未付款订单</p>
                    <h3>500</h3>
                  </div>
                  <div class="data-briefing">
                    <p>本月软件下载量</p>
                    <h3>500</h3>
                  </div>
                  <div class="data-briefing">
                    <p>本月Web访问量</p>
                    <h3>1200</h3>
                  </div>
                  <div class="data-briefing">
                    <p>代理销售额占总销售份额</p>
                    <h3>50%</h3>
                  </div>
                  <div class="data-briefing">
                    <p>处理工单</p>
                    <h3>500</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <h4 class="title">
                  代理注册用户趋势
                  <span class="text-danger">
                    （取<span class="text-primary">所有代理的注册用户数量 -  每月总和</span>，用单柱图展示）
                  </span>
                </h4>
              </div>
              <div class="admin-card-body">
                <div class="daily-consumption-trend">
                  <div class="chart-wrap">
                    <v-chart :option="bChart1" class="chart"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <h4 class="title">
                  代理销售额业绩趋势
                  <span class="text-danger">
                    （取<span class="text-primary">所有代理的 付费金额、订单笔数 - 每月总和</span>，用双柱图展示
                  </span>
                </h4>
              </div>
              <div class="admin-card-body">
                <div class="daily-consumption-trend">
                  <div class="chart-wrap">
                    <v-chart :option="bChart2" class="chart"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <h4 class="title">
                  代理销售额排名
                </h4>
              </div>
              <div class="admin-card-body">
                <div class="global-statistics-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">排名</th>
                        <th scope="col" class="channel">渠道</th>
                        <th scope="col" class="tm-amount">本月销售金额</th>
                        <th scope="col" class="lm-amount">上月销售金额</th>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span class="warning">1</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span class="warning">2</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span class="warning">3</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>4</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>5</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>6</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>7</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>8</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>9</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>10</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="admin-card-wrap mb-30">
              <div class="admin-card-heading">
                <h4 class="title">
                  代理注册用户排名
                </h4>
              </div>
              <div class="admin-card-body">
                <div class="global-statistics-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">排名</th>
                        <th scope="col" class="channel">渠道</th>
                        <th scope="col" class="tm-amount">本月销售金额</th>
                        <th scope="col" class="lm-amount">上月销售金额</th>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span class="warning">1</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span class="warning">2</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span class="warning">3</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>4</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>5</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>6</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>7</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>8</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>9</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      <tr>
                        <td class="serial">
                          <span>10</span>
                        </td>
                        <td class="channel">youtube</td>
                        <td class="tm-amount">100.00万</td>
                        <td class="lm-amount">100.00万</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalStatistics",
  data() {
    return {
      sChart1: {
        title: {
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: " {b} : {c} ({d}%)"
        },
        colorBy: "series",
        color: [
          '#007BFF',
          '#E9E9E9'
        ],
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['55%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '12',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 75, name: 'Bill'},
              {value: 25, name: 'none'}
            ]
          }
        ]
      },
      sChart2: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '0',
          containLabel: false
        },
        xAxis: [
          {
            splitLine: {show: false},
            type: 'category',
            boundaryGap: false,
            data: ['2020-01', '2020-02', '2020-03', '2020-04', '2020-05', '2020-06', '2020-07', '2020-08', '2020-09', '2020-10', '2020-11', '2020-12']
          }
        ],
        yAxis: [
          {
            splitLine: {show: false},
            type: 'value'
          }
        ],
        colorBy: "series",
        color: [
          '#52C1F5',
        ],
        series: [
          {
            name: '消费金额',
            type: 'bar',
            barGap: '20%',
            barCategoryGap: '50%',
            emphasis: {
              focus: 'series'
            },
            data: [120, 280, 320, 150, 200, 180, 120, 140, 300, 250, 200, 240, 310, 280, 320]
          }
        ]
      },
      bChart1: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        colorBy: "series",
        color: [
          '#6AC9F6',
          '#93DA9A',
        ],
        series: [
          {
            name: '消费金额',
            type: 'bar',
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            data: [1700000, 1660000, 1420000, 1520000, 1190000, 1030000, 1050000, 870000, 690000, 620000, 1320000, 1220000]
          },
          {
            name: '充值金额',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: [2520000, 2650000, 2330000, 2150000, 1840000, 1830000, 1450000, 950000, 700000, 690000, 1230000, 1000000]
          },
        ]
      },
      bChart2: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        colorBy: "series",
        color: [
          '#FD8D9D',
          '#86ADFB',
        ],
        series: [
          {
            name: '消费金额',
            type: 'bar',
            barGap: 0,
            emphasis: {
              focus: 'series'
            },
            data: [700000, 690000, 950000, 1450000, 1840000, 2150000, 2520000, 2650000, 2330000, 1830000, 1390000, 960000]
          },
          {
            name: '充值金额',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: [390000, 420000, 570000, 850000, 1190000, 1520000, 1700000, 1660000, 1420000, 1030000, 660000, 480000]
          },
        ]
      },
    }
  }
}
</script>

<style scoped>

</style>
