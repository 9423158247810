<template>
  <transition>
    <b-modal
        id="blocking-rules-details"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal-large"
        hide-footer
        centered
    >
      <template #modal-header="{ close }">
        <h5>规则列表示例代码</h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="blocking-rules-details px-3 py-3">
          <p>
            <span class="text-warning">纯字符串:</span>
            当此字符串匹配目标域名中任意部分，该规则生效。比如"sina.com"可以匹配"sina.com"、"sina.com.cn"和"www.sina.com"，但不匹配"sina.cn"
          </p>
          <p>
            <span class="text-warning">·正则表达式:</span>由"regexp:"开始，余下部分是一个正则表达式。当此正则表达式匹配目标域名时，该规则生效。例如"regexp:\\.goo.*\\.com$"匹配"www.google.com"、"fonts.googleapis.com",但不匹配"google.com"
          </p>
          <p>
            ·子域名(推荐):由"domain:"开始，余下部分是一个域名。当此域名是目标域名或其子域名时，该规则生效。例如"domain:v2ray.com"匹配"www．v2ray.com"、"v2ray.com"，但不匹配"xv2ray.com"
          </p>
          <p>
            <span class="text-success">·完整匹配:</span>由 "full:"
            开始，余下部分是一个域名。当此域名完整匹配目标域名时，该规则生效。例如"full:v2ray.com"匹配"v2ray.com"但不匹配"www.v2ray.com"
          </p>
          <p>
            ·预定义域名列表:由"geosite:"开头，余下部分是一个名称，如 geosite:google 或者geosite:cn 。名称及域名列表参考 预定义域名列表。
          </p>
          <p>以下是示例：</p>
          <p>google ＃屏蔽包含 ＂google＂字符串的域名</p>
          <p>regexp:.*googlel.com ＃ 屏蔽以 google.com 结尾的任何域名</p>
          <p>domain:google.com ＃屏蔽 google.com及 google.com的任何子域名</p>
          <p>full:google.com ＃只屏蔽 google.com 域名</p>
          <p>geosite:google ＃屏蔽 google 相关的所有域名，需参考 geosite.dat</p>
        </div>
      </template>
    </b-modal>
  </transition>
</template>

<script>
export default {
  name: "BlockingRulesDetailsBox",
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
