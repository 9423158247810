<template>
  <div class="widget-section">

    <!-- server-status-section start -->
    <div class="server-status-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="server-status-wrapper">
              <div class="row">
                <div class="col-xl-3 col-md-6">
                  <div class="server-status-wrap mb-30">
                    <p>服务器节点总数</p>
                    <h4 class="title">200</h4>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6">
                  <div class="server-status-wrap mb-30">
                    <p>在线服务器数量</p>
                    <h4 class="title success">50</h4>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6">
                  <div class="server-status-wrap mb-30">
                    <p>离线服务器数量</p>
                    <h4 class="title danger">50</h4>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6">
                  <div class="server-status-wrap mb-30">
                    <p>流量到期下架服务器数量</p>
                    <h4 class="title warning">50</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- server-status-section end -->

    <!-- server-status-tab-section start -->
    <div class="server-status-tab-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="server-status-wrapper">
              <div class="row">
                <div class="col-xl-12">
                  <b-tabs class="server-status-tab">
                    <b-tab active>
                      <template #title>香港</template>
                      <div class="tab-body">
                        <div class="server-status-table-wrapper">
                          <div class="table-responsive-wrapper">
                            <table class="table">
                              <tbody>
                              <tr>
                                <th scope="col" class="information">基本信息</th>
                                <th scope="col" class="condition">状态</th>
                                <th scope="col" class="traffic">流量使用情况</th>
                                <th scope="col" class="server">服务器负载情况</th>
                                <th scope="col" class="user">在线用户数量</th>
                                <th scope="col" class="date">最后更新时间</th>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <div class="daily-table-pagination mt-30 mb-20">
                              <ul>
                                <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li><span>...</span></li>
                                <li><a href="#">50</a></li>
                                <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                              </ul>
                              <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                                <select>
                                  <option value="10">10 / Page</option>
                                  <option value="10">20 / Page</option>
                                  <option value="10">30 / Page</option>
                                  <option value="10">40 / Page</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>美国</template>
                      <div class="tab-body">
                        <div class="server-status-table-wrapper">
                          <div class="table-responsive-wrapper">
                            <table class="table">
                              <tbody>
                              <tr>
                                <th scope="col" class="information">基本信息</th>
                                <th scope="col" class="condition">状态</th>
                                <th scope="col" class="traffic">流量使用情况</th>
                                <th scope="col" class="server">服务器负载情况</th>
                                <th scope="col" class="user">在线用户数量</th>
                                <th scope="col" class="date">最后更新时间</th>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <div class="daily-table-pagination mt-30 mb-20">
                              <ul>
                                <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li><span>...</span></li>
                                <li><a href="#">50</a></li>
                                <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                              </ul>
                              <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                                <select>
                                  <option value="10">10 / Page</option>
                                  <option value="10">20 / Page</option>
                                  <option value="10">30 / Page</option>
                                  <option value="10">40 / Page</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>韩国</template>
                      <div class="tab-body">
                        <div class="server-status-table-wrapper">
                          <div class="table-responsive-wrapper">
                            <table class="table">
                              <tbody>
                              <tr>
                                <th scope="col" class="information">基本信息</th>
                                <th scope="col" class="condition">状态</th>
                                <th scope="col" class="traffic">流量使用情况</th>
                                <th scope="col" class="server">服务器负载情况</th>
                                <th scope="col" class="user">在线用户数量</th>
                                <th scope="col" class="date">最后更新时间</th>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <div class="daily-table-pagination mt-30 mb-20">
                              <ul>
                                <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li><span>...</span></li>
                                <li><a href="#">50</a></li>
                                <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                              </ul>
                              <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                                <select>
                                  <option value="10">10 / Page</option>
                                  <option value="10">20 / Page</option>
                                  <option value="10">30 / Page</option>
                                  <option value="10">40 / Page</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>日本</template>
                      <div class="tab-body">
                        <div class="server-status-table-wrapper">
                          <div class="table-responsive-wrapper">
                            <table class="table">
                              <tbody>
                              <tr>
                                <th scope="col" class="information">基本信息</th>
                                <th scope="col" class="condition">状态</th>
                                <th scope="col" class="traffic">流量使用情况</th>
                                <th scope="col" class="server">服务器负载情况</th>
                                <th scope="col" class="user">在线用户数量</th>
                                <th scope="col" class="date">最后更新时间</th>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <div class="daily-table-pagination mt-30 mb-20">
                              <ul>
                                <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li><span>...</span></li>
                                <li><a href="#">50</a></li>
                                <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                              </ul>
                              <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                                <select>
                                  <option value="10">10 / Page</option>
                                  <option value="10">20 / Page</option>
                                  <option value="10">30 / Page</option>
                                  <option value="10">40 / Page</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>俄罗斯</template>
                      <div class="tab-body">
                        <div class="server-status-table-wrapper">
                          <div class="table-responsive-wrapper">
                            <table class="table">
                              <tbody>
                              <tr>
                                <th scope="col" class="information">基本信息</th>
                                <th scope="col" class="condition">状态</th>
                                <th scope="col" class="traffic">流量使用情况</th>
                                <th scope="col" class="server">服务器负载情况</th>
                                <th scope="col" class="user">在线用户数量</th>
                                <th scope="col" class="date">最后更新时间</th>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              <tr>
                                <td class="information">
                                  <div class="server-info">
                                    <div class="icon">
                                      <i class="fa-brands fa-react"></i>
                                    </div>
                                    <div class="content">
                                      <h4>香港游戏服务器3 - 192.168.222.163</h4>
                                      <p>服务器备注内容</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="condition">
                                  <div class="status">正常</div>
                                </td>
                                <td class="traffic">
                                  <div class="traffic-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="50"></b-progress-bar>
                                    </b-progress>
                                    <span>50%</span>
                                  </div>
                                </td>
                                <td class="server">
                                  <div class="server-progress">
                                    <b-progress :max="100">
                                      <b-progress-bar :value="90" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    <span>90%</span>
                                  </div>
                                </td>
                                <td class="user">
                                  25
                                </td>
                                <td class="date">
                                  <i class="fa-regular fa-clock"></i> 2020-11-25 23:26:08
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <div class="daily-table-pagination mt-30 mb-20">
                              <ul>
                                <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                                <li><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li><span>...</span></li>
                                <li><a href="#">50</a></li>
                                <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                              </ul>
                              <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                                <select>
                                  <option value="10">10 / Page</option>
                                  <option value="10">20 / Page</option>
                                  <option value="10">30 / Page</option>
                                  <option value="10">40 / Page</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- server-status-tab-section end -->

  </div>
</template>

<script>
export default {
  name: "CloudServerOperationMonitoring",
  data() {
    return {};
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
