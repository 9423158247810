<template>
  <div class="widget-section">

    <!-- node-list-section start -->
    <div class="node-list-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h4 class="title">服务器节点列表</h4>
              </div>
              <div class="admin-card-body">
                <div class="node-list-btn">
                  <div class="search-wrap">
                    <input type="text" placeholder="搜索节点列表">
                    <button><i class="fa-regular fa-magnifying-glass"></i> 查询</button>
                  </div>
                  <button class="c-cancel-btn">
                    <i class="fa-solid fa-flip-horizontal fa-arrow-rotate-right"></i> 重置
                  </button>
                  <button class="c-add-btn" v-b-modal:node-list-new-server>
                    <i class="fa-solid fa-plus"></i> 新建服务器
                  </button>
                </div>
                <div class="node-list-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号ID</th>
                        <th scope="col" class="name">节点名称</th>
                        <th scope="col" class="ip">节点IP</th>
                        <th scope="col" class="key">节点key</th>
                        <th scope="col" class="status">状态</th>
                        <th scope="col" class="nation">国家</th>
                        <th scope="col" class="type">协议类型</th>
                        <th scope="col" class="total">UUID总数</th>
                        <th scope="col" class="number">UUID使用数</th>
                        <th scope="col" class="traffic">流量使用率</th>
                        <th scope="col" class="wether">是否开启</th>
                        <th scope="col" class="h-time">心跳时间</th>
                        <th scope="col" class="c-time">创建时</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="name">United States01</td>
                        <td class="ip">192.168.222.163</td>
                        <td class="key">
                          <div class="key-info success" v-b-modal:node-list-key-success><i
                              class="fa-solid fa-circle-check"></i> 查看key
                          </div>
                        </td>
                        <td class="status">
                          <div class="status-text success">● 在线</div>
                        </td>
                        <td class="nation">香港</td>
                        <td class="type">PPTP</td>
                        <td class="total">3000</td>
                        <td class="number">200</td>
                        <td class="traffic">
                          <div class="traffic-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="50" variant="success"></b-progress-bar>
                            </b-progress>
                            <span>50%</span>
                          </div>
                        </td>
                        <td class="wether">
                          <div class="input-switch">
                            <b-form-checkbox name="check-button" switch id="c-check-1" button-variant="success"
                                             checked="true"></b-form-checkbox>
                          </div>
                        </td>
                        <td class="h-time">11-25 23:26:08</td>
                        <td class="c-time">2020-11-25 23</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="view-btn" v-b-modal:node-edit>
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </a>
                            <a href="#" class="edit-btn" v-b-modal:node-list-new-server><i class="fa-solid fa-plus"></i>
                              编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:node-list-action-success>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">2</td>
                        <td class="name">United States01</td>
                        <td class="ip">192.168.222.163</td>
                        <td class="key">
                          <div class="key-info success" v-b-modal:node-list-key-success><i
                              class="fa-solid fa-circle-check"></i> 查看key
                          </div>
                        </td>
                        <td class="status">
                          <div class="status-text success">● 在线</div>
                        </td>
                        <td class="nation">香港</td>
                        <td class="type">PPTP</td>
                        <td class="total">3000</td>
                        <td class="number">200</td>
                        <td class="traffic">
                          <div class="traffic-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="80" variant="danger"></b-progress-bar>
                            </b-progress>
                            <span>80%</span>
                          </div>
                        </td>
                        <td class="wether">
                          <div class="input-switch">
                            <b-form-checkbox name="check-button" switch id="c-check-2"
                                             button-variant="success"></b-form-checkbox>
                          </div>
                        </td>
                        <td class="h-time">11-25 23:26:08</td>
                        <td class="c-time">2020-11-25 23</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="view-btn" v-b-modal:node-edit>
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </a>
                            <a href="#" class="edit-btn" v-b-modal:node-list-new-server><i class="fa-solid fa-plus"></i>
                              编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:node-list-action-success>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">3</td>
                        <td class="name">United States01</td>
                        <td class="ip">192.168.222.163</td>
                        <td class="key">
                          <div class="key-info success" v-b-modal:node-list-key-success><i
                              class="fa-solid fa-circle-check"></i> 查看key
                          </div>
                        </td>
                        <td class="status">
                          <div class="status-text success">● 在线</div>
                        </td>
                        <td class="nation">香港</td>
                        <td class="type">PPTP</td>
                        <td class="total">3000</td>
                        <td class="number">200</td>
                        <td class="traffic">
                          <div class="traffic-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="50" variant="success"></b-progress-bar>
                            </b-progress>
                            <span>50%</span>
                          </div>
                        </td>
                        <td class="wether">
                          <div class="input-switch">
                            <b-form-checkbox name="check-button" switch id="c-check-3"
                                             button-variant="success"></b-form-checkbox>
                          </div>
                        </td>
                        <td class="h-time">11-25 23:26:08</td>
                        <td class="c-time">2020-11-25 23</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="view-btn" v-b-modal:node-edit>
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </a>
                            <a href="#" class="edit-btn" v-b-modal:node-list-new-server><i class="fa-solid fa-plus"></i>
                              编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:node-list-action-success>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">4</td>
                        <td class="name">United States01</td>
                        <td class="ip">192.168.222.163</td>
                        <td class="key">
                          <div class="key-info success" v-b-modal:node-list-key-success><i
                              class="fa-solid fa-circle-check"></i> 查看key
                          </div>
                        </td>
                        <td class="status">
                          <div class="status-text success">● 在线</div>
                        </td>
                        <td class="nation">香港</td>
                        <td class="type">PPTP</td>
                        <td class="total">3000</td>
                        <td class="number">200</td>
                        <td class="traffic">
                          <div class="traffic-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="60" variant="warning"></b-progress-bar>
                            </b-progress>
                            <span>60%</span>
                          </div>
                        </td>
                        <td class="wether">
                          <div class="input-switch">
                            <b-form-checkbox name="check-button" switch id="c-check-4"
                                             button-variant="success"></b-form-checkbox>
                          </div>
                        </td>
                        <td class="h-time">11-25 23:26:08</td>
                        <td class="c-time">2020-11-25 23</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="view-btn" v-b-modal:node-edit>
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </a>
                            <a href="#" class="edit-btn" v-b-modal:node-list-new-server><i class="fa-solid fa-plus"></i>
                              编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:node-list-action-success>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">5</td>
                        <td class="name">United States01</td>
                        <td class="ip">192.168.222.163</td>
                        <td class="key">
                          <div class="key-info success" v-b-modal:node-list-key-success><i
                              class="fa-solid fa-circle-check"></i> 查看key
                          </div>
                        </td>
                        <td class="status">
                          <div class="status-text success">● 在线</div>
                        </td>
                        <td class="nation">香港</td>
                        <td class="type">PPTP</td>
                        <td class="total">3000</td>
                        <td class="number">200</td>
                        <td class="traffic">
                          <div class="traffic-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="50" variant="success"></b-progress-bar>
                            </b-progress>
                            <span>50%</span>
                          </div>
                        </td>
                        <td class="wether">
                          <div class="input-switch">
                            <b-form-checkbox name="check-button" switch id="c-check-5"
                                             button-variant="success"></b-form-checkbox>
                          </div>
                        </td>
                        <td class="h-time">11-25 23:26:08</td>
                        <td class="c-time">2020-11-25 23</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="view-btn" v-b-modal:node-edit>
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </a>
                            <a href="#" class="edit-btn" v-b-modal:node-list-new-server><i class="fa-solid fa-plus"></i>
                              编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:node-list-action-success>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">6</td>
                        <td class="name">United States01</td>
                        <td class="ip">192.168.222.163</td>
                        <td class="key">
                          <div class="key-info success" v-b-modal:node-list-key-success><i
                              class="fa-solid fa-circle-check"></i> 查看key
                          </div>
                        </td>
                        <td class="status">
                          <div class="status-text success">● 在线</div>
                        </td>
                        <td class="nation">香港</td>
                        <td class="type">PPTP</td>
                        <td class="total">3000</td>
                        <td class="number">200</td>
                        <td class="traffic">
                          <div class="traffic-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="50" variant="success"></b-progress-bar>
                            </b-progress>
                            <span>50%</span>
                          </div>
                        </td>
                        <td class="wether">
                          <div class="input-switch">
                            <b-form-checkbox name="check-button" switch id="c-check-6"
                                             button-variant="success"></b-form-checkbox>
                          </div>
                        </td>
                        <td class="h-time">11-25 23:26:08</td>
                        <td class="c-time">2020-11-25 23</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="view-btn" v-b-modal:node-edit>
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </a>
                            <a href="#" class="edit-btn" v-b-modal:node-list-new-server><i class="fa-solid fa-plus"></i>
                              编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:node-list-action-success>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">7</td>
                        <td class="name">United States01</td>
                        <td class="ip">192.168.222.163</td>
                        <td class="key">
                          <div class="key-info success" v-b-modal:node-list-key-success><i
                              class="fa-solid fa-circle-check"></i> 查看key
                          </div>
                        </td>
                        <td class="status">
                          <div class="status-text success">● 在线</div>
                        </td>
                        <td class="nation">香港</td>
                        <td class="type">PPTP</td>
                        <td class="total">3000</td>
                        <td class="number">200</td>
                        <td class="traffic">
                          <div class="traffic-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="50" variant="success"></b-progress-bar>
                            </b-progress>
                            <span>50%</span>
                          </div>
                        </td>
                        <td class="wether">
                          <div class="input-switch">
                            <b-form-checkbox name="check-button" switch id="c-check-7"
                                             button-variant="success"></b-form-checkbox>
                          </div>
                        </td>
                        <td class="h-time">11-25 23:26:08</td>
                        <td class="c-time">2020-11-25 23</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="view-btn" v-b-modal:node-edit>
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </a>
                            <a href="#" class="edit-btn" v-b-modal:node-list-new-server><i class="fa-solid fa-plus"></i>
                              编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:node-list-action-success>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">8</td>
                        <td class="name">United States01</td>
                        <td class="ip">192.168.222.163</td>
                        <td class="key">
                          <div class="key-info success" v-b-modal:node-list-key-success><i
                              class="fa-solid fa-circle-check"></i> 查看key
                          </div>
                        </td>
                        <td class="status">
                          <div class="status-text success">● 在线</div>
                        </td>
                        <td class="nation">香港</td>
                        <td class="type">PPTP</td>
                        <td class="total">3000</td>
                        <td class="number">200</td>
                        <td class="traffic">
                          <div class="traffic-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="50" variant="success"></b-progress-bar>
                            </b-progress>
                            <span>50%</span>
                          </div>
                        </td>
                        <td class="wether">
                          <div class="input-switch">
                            <b-form-checkbox name="check-button" switch id="c-check-8"
                                             button-variant="success"></b-form-checkbox>
                          </div>
                        </td>
                        <td class="h-time">11-25 23:26:08</td>
                        <td class="c-time">2020-11-25 23</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="view-btn" v-b-modal:node-edit>
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </a>
                            <a href="#" class="edit-btn" v-b-modal:node-list-new-server><i class="fa-solid fa-plus"></i>
                              编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:node-list-action-success>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">9</td>
                        <td class="name">United States01</td>
                        <td class="ip">192.168.222.163</td>
                        <td class="key">
                          <div class="key-info success" v-b-modal:node-list-key-success><i
                              class="fa-solid fa-circle-check"></i> 查看key
                          </div>
                        </td>
                        <td class="status">
                          <div class="status-text success">● 在线</div>
                        </td>
                        <td class="nation">香港</td>
                        <td class="type">PPTP</td>
                        <td class="total">3000</td>
                        <td class="number">200</td>
                        <td class="traffic">
                          <div class="traffic-progress">
                            <b-progress :max="100">
                              <b-progress-bar :value="50" variant="success"></b-progress-bar>
                            </b-progress>
                            <span>50%</span>
                          </div>
                        </td>
                        <td class="wether">
                          <div class="input-switch">
                            <b-form-checkbox name="check-button" switch id="c-check-9"
                                             button-variant="success"></b-form-checkbox>
                          </div>
                        </td>
                        <td class="h-time">11-25 23:26:08</td>
                        <td class="c-time">2020-11-25 23</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="view-btn" v-b-modal:node-edit>
                              <i class="fa-solid fa-circle-chevron-right"></i> 查看节点
                            </a>
                            <a href="#" class="edit-btn" v-b-modal:node-list-new-server><i class="fa-solid fa-plus"></i>
                              编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:node-list-action-success>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- node-list-section end -->

    <NodeListKeyBox/>
    <NodeListActionBox/>
    <NodeListNewServer/>
    <NodeListSuccessToast variant="success">
      <template #title><i class="fa-regular fa-circle-check"></i> 服务器添加成功</template>
      <p> 服务器添加成功</p>
    </NodeListSuccessToast>
    <NodeEdit/>
  </div>
</template>

<script>
import NodeListSuccessToast from "@/components/Toast/NodeListSuccessToast";
import NodeListKeyBox from "@/components/Modals/NodeListKeyBox";
import NodeListActionBox from "@/components/Modals/NodeListActionBox";
import NodeListNewServer from "@/components/Modals/NodeListNewServer";
import NodeEdit from "@/components/Modals/NodeEdit";

export default {
  name: "NodeList",
  components: {NodeEdit, NodeListNewServer, NodeListActionBox, NodeListKeyBox, NodeListSuccessToast}
}
</script>

<style scoped>

</style>
