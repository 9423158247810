export default {
    methods: {
        showSuccessMessage(title, body){
            this.$bvToast.toast(body, {
                title: title,
                toaster: 'b-toaster-bottom-right',
                solid: true,
                variant: 'success',
                appendToast: true
            })
        },
        showWarningMessage(title, body){
            this.$bvToast.toast(body, {
                title: title,
                toaster: 'b-toaster-bottom-right',
                solid: true,
                variant: 'warning',
                appendToast: true
            })
        },
        showErrorMessage(title, body){
            this.$bvToast.toast(body, {
                title: title,
                toaster: 'b-toaster-bottom-right',
                solid: true,
                variant: 'error',
                appendToast: true
            })
        }
    }
}