<template>
  <transition>
    <b-modal
        id="settlement-management-add"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="settlement-add-modal"
        centered
    >
      <template #modal-header="">
        <h5>申请提现 <span>请核实您的金额以及收款地址</span></h5>
        <span class="right-info"><b>*</b> 为必填项</span>
      </template>

      <template #default="">
        <div class="settlement-form-wrap">
          <form action="#">
            <div class="c-input-group">
              <label>提现金额:</label>
              <div class="input-wrap">
                <input type="text" placeholder="2500.99">
              </div>
            </div>
            <div class="c-input-group">
              <label><span>*</span>收款地址:</label>
              <div class="input-wrap">
                <input type="text" placeholder="收款地址" value="12345678123456781234567812345678" disabled>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <div class="country-btn-wrap">
          <b-button variant="cancel" @click="cancel()">
            取消
          </b-button>

          <b-button variant="theme" @click="hide();showSuccessMessage('服务器添加成功', '服务器添加成功')">
            确定提现
          </b-button>
        </div>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "SettlementManagementAdd",
  mixins: [toaster],
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
