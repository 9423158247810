import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/axios";
import "./plugins/echarts";
import "./plugins/date-range-picker";
import "./plugins/gmap";
import "./plugins/wysiwyg-editor";
import "./plugins/particles";
import "./plugins/vuelidate";


import store from "./store/store";
import router from "./router/router";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/css/fontawesome.min.css";
import "./assets/css/style.min.css";

import App from "./App.vue";

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount("#app")
