<template>
  <div class="widget-section">
    <!-- announcement-list-section start -->
    <div class="member-list-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">用户管理</h5>
              </div>
              <div class="admin-card-body">
                <div class="member-list-btn">
                  <div class="search-wrap">
                    <input type="text" placeholder="搜索节点列表">
                    <button><i class="fa-regular fa-magnifying-glass"></i> 查询</button>
                  </div>
                  <button class="c-cancel-btn">
                    <i class="fa-solid fa-flip-horizontal fa-arrow-rotate-right"></i> 重置
                  </button>
                  <button class="c-add-btn" v-b-modal:announcement-add>
                    <i class="fa-solid fa-plus"></i> 发布公告
                  </button>
                </div>
                <div class="announcement-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="title">公告标题</th>
                        <th scope="col" class="content">公告内容</th>
                        <th scope="col" class="type">公告面向用户类型</th>
                        <th scope="col" class="date">发布时间</th>
                        <th scope="col" class="publisher">发布人员</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="title">announcement title</td>
                        <td colspan="content">Announcement content in seconds......</td>
                        <td colspan="type">免费用户</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:announcement-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="title">announcement title</td>
                        <td colspan="content">Announcement content in seconds......</td>
                        <td colspan="type">免费用户</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:announcement-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="title">announcement title</td>
                        <td colspan="content">Announcement content in seconds......</td>
                        <td colspan="type">免费用户</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:announcement-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="title">announcement title</td>
                        <td colspan="content">Announcement content in seconds......</td>
                        <td colspan="type">免费用户</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:announcement-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="title">announcement title</td>
                        <td colspan="content">Announcement content in seconds......</td>
                        <td colspan="type">免费用户</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:announcement-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="title">announcement title</td>
                        <td colspan="content">Announcement content in seconds......</td>
                        <td colspan="type">免费用户</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:announcement-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="title">announcement title</td>
                        <td colspan="content">Announcement content in seconds......</td>
                        <td colspan="type">免费用户</td>
                        <td class="date">2020-12-23 22:31</td>
                        <td class="publisher">admin</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:announcement-details>
                              <i class="fa-solid fa-circle-chevron-right"></i> 编辑
                            </a>
                            <a href="#" class="delete-btn" v-b-modal:announcement-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- announcement-list-section end -->
    <AnnouncementAdd/>
    <AnnouncementDelete/>
    <AnnouncementDetails/>
  </div>
</template>

<script>
import AnnouncementAdd from "@/components/Modals/AnnouncementAdd";
import AnnouncementDelete from "@/components/Modals/AnnouncementDelete";
import AnnouncementDetails from "@/components/Modals/AnnouncementDetails";

export default {
  name: "AnnouncementList",
  components: {AnnouncementDetails, AnnouncementDelete, AnnouncementAdd}
}
</script>

<style scoped>

</style>
