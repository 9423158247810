<template>
  <transition>
    <b-modal
        id="message-edit-modal"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
    >
      <template #modal-header="">
        <h5>Edit Reply Message</h5>
      </template>

      <template #default="">
        <p>Modal Body with button</p>

      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="ok()">
          OK
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>

      </template>
    </b-modal>
  </transition>
</template>

<script>
export default {
  name: "MessageEditModal",
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal(){

    }

  }
}
</script>

<style scoped>

</style>