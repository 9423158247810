<template>
  <transition>
    <b-modal
        id="member-delete"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="dialog-box-modal"
        centered
    >
      <template #modal-header="{ close }">
        <h5>操作确认</h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="country-box-info px-3 py-3">
          <div class="d-flex align-items-center">
            <div class="icon mr-3">
              <i class="fa-regular fa-triangle-exclamation warning"></i>
            </div>
            <div class="content">
              <h5 class="mb-2">是否删除该会员账号</h5>
              <p>此操作不可逆</p>
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer="{ hide, cancel }">
        <b-button variant="modal-f-primary" @click="hide();showSuccessMessage('服务器添加成功', '服务器添加成功')">
          确定
        </b-button>

        <b-button variant="modal-f-cancel mr-10" @click="cancel()">
          取消
        </b-button>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "MemberDelete",
  mixins: [toaster],
  methods: {
    handleOk() {
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
