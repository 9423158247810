<template>
  <div class="widget-section">
    <!-- agent-list-section start -->
    <div class="agent-list-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">代理商用户</h5>
              </div>
              <div class="admin-card-body">
                <div class="agent-list-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="account">代理账号</th>
                        <th scope="col" class="channel">渠道名称</th>
                        <th scope="col" class="ratio">结算比例</th>
                        <th scope="col" class="total">总结算($)</th>
                        <th scope="col" class="unsettled">未结算($)</th>
                        <th scope="col" class="invitation">代理邀请码</th>
                        <th scope="col" class="address">代理收款地址</th>
                        <th scope="col" class="date">创建时间</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="account">Wi2121ndir@gmail.com</td>
                        <td colspan="channel">google</td>
                        <td colspan="ratio">80%</td>
                        <td colspan="total">659563</td>
                        <td class="unsettled">26266</td>
                        <td class="invitation">GKHEW41</td>
                        <td class="address"> 12345678123456781234567812345678</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="edit-btn" v-b-modal:promotion-success>
                              复制推广链接
                            </button>
                            <router-link :to="{name: 'MemberOrder'}" class="info-btn">
                              查看代理数据
                            </router-link>
                            <button class="delete-btn" v-b-modal:promotion-delete>
                              删除
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="account">Wi2121ndir@gmail.com</td>
                        <td colspan="channel">google</td>
                        <td colspan="ratio">80%</td>
                        <td colspan="total">659563</td>
                        <td class="unsettled">26266</td>
                        <td class="invitation">GKHEW41</td>
                        <td class="address"> 12345678123456781234567812345678</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="edit-btn" v-b-modal:promotion-success>
                              复制推广链接
                            </button>
                            <router-link :to="{name: 'MemberOrder'}" class="info-btn">
                              查看代理数据
                            </router-link>
                            <button class="delete-btn" v-b-modal:promotion-delete>
                              删除
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="account">Wi2121ndir@gmail.com</td>
                        <td colspan="channel">google</td>
                        <td colspan="ratio">80%</td>
                        <td colspan="total">659563</td>
                        <td class="unsettled">26266</td>
                        <td class="invitation">GKHEW41</td>
                        <td class="address"> 12345678123456781234567812345678</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="edit-btn" v-b-modal:promotion-success>
                              复制推广链接
                            </button>
                            <router-link :to="{name: 'MemberOrder'}" class="info-btn">
                              查看代理数据
                            </router-link>
                            <button class="delete-btn" v-b-modal:promotion-delete>
                              删除
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="account">Wi2121ndir@gmail.com</td>
                        <td colspan="channel">google</td>
                        <td colspan="ratio">80%</td>
                        <td colspan="total">659563</td>
                        <td class="unsettled">26266</td>
                        <td class="invitation">GKHEW41</td>
                        <td class="address"> 12345678123456781234567812345678</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="edit-btn" v-b-modal:promotion-success>
                              复制推广链接
                            </button>
                            <router-link :to="{name: 'MemberOrder'}" class="info-btn">
                              查看代理数据
                            </router-link>
                            <button class="delete-btn" v-b-modal:promotion-delete>
                              删除
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="account">Wi2121ndir@gmail.com</td>
                        <td colspan="channel">google</td>
                        <td colspan="ratio">80%</td>
                        <td colspan="total">659563</td>
                        <td class="unsettled">26266</td>
                        <td class="invitation">GKHEW41</td>
                        <td class="address"> 12345678123456781234567812345678</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="edit-btn" v-b-modal:promotion-success>
                              复制推广链接
                            </button>
                            <router-link :to="{name: 'MemberOrder'}" class="info-btn">
                              查看代理数据
                            </router-link>
                            <button class="delete-btn" v-b-modal:promotion-delete>
                              删除
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="account">Wi2121ndir@gmail.com</td>
                        <td colspan="channel">google</td>
                        <td colspan="ratio">80%</td>
                        <td colspan="total">659563</td>
                        <td class="unsettled">26266</td>
                        <td class="invitation">GKHEW41</td>
                        <td class="address"> 12345678123456781234567812345678</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="edit-btn" v-b-modal:promotion-success>
                              复制推广链接
                            </button>
                            <router-link :to="{name: 'MemberOrder'}" class="info-btn">
                              查看代理数据
                            </router-link>
                            <button class="delete-btn" v-b-modal:promotion-delete>
                              删除
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="account">Wi2121ndir@gmail.com</td>
                        <td colspan="channel">google</td>
                        <td colspan="ratio">80%</td>
                        <td colspan="total">659563</td>
                        <td class="unsettled">26266</td>
                        <td class="invitation">GKHEW41</td>
                        <td class="address"> 12345678123456781234567812345678</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="edit-btn" v-b-modal:promotion-success>
                              复制推广链接
                            </button>
                            <router-link :to="{name: 'MemberOrder'}" class="info-btn">
                              查看代理数据
                            </router-link>
                            <button class="delete-btn" v-b-modal:promotion-delete>
                              删除
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="account">Wi2121ndir@gmail.com</td>
                        <td colspan="channel">google</td>
                        <td colspan="ratio">80%</td>
                        <td colspan="total">659563</td>
                        <td class="unsettled">26266</td>
                        <td class="invitation">GKHEW41</td>
                        <td class="address"> 12345678123456781234567812345678</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="edit-btn" v-b-modal:promotion-success>
                              复制推广链接
                            </button>
                            <router-link :to="{name: 'MemberOrder'}" class="info-btn">
                              查看代理数据
                            </router-link>
                            <button class="delete-btn" v-b-modal:promotion-delete>
                              删除
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="serial">1</td>
                        <td class="account">Wi2121ndir@gmail.com</td>
                        <td colspan="channel">google</td>
                        <td colspan="ratio">80%</td>
                        <td colspan="total">659563</td>
                        <td class="unsettled">26266</td>
                        <td class="invitation">GKHEW41</td>
                        <td class="address"> 12345678123456781234567812345678</td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <button class="edit-btn" v-b-modal:promotion-success>
                              复制推广链接
                            </button>
                            <router-link :to="{name: 'MemberOrder'}" class="info-btn">
                              查看代理数据
                            </router-link>
                            <button class="delete-btn" v-b-modal:promotion-delete>
                              删除
                            </button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- agent-list-section end -->

    <PromotionSuccessBox/>
    <PromotionDeleteBox/>
  </div>
</template>

<script>
import PromotionSuccessBox from "@/components/Modals/PromotionSuccessBox";
import PromotionDeleteBox from "@/components/Modals/PromotionDeleteBox";

export default {
  name: "AgentList",
  components: {PromotionDeleteBox, PromotionSuccessBox}
}
</script>

<style scoped>

</style>
