<template>
  <div class="widget-section">
    <!-- global-configuration-section start -->
    <div class="global-configuration-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading config-heading">
                <h5 class="title">全局配置 <span><b>*</b> 为必填项</span></h5>
              </div>
              <div class="admin-card-body">
                <b-tabs class="global-status-tab">
                  <b-tab active>
                    <template #title>域名加密配置</template>
                    <div class="tab-body">
                      <div class="global-configuration-form">
                        <div class="row justify-content-center mb-30">
                          <div class="col-xl-10">
                            <h3 class="title">基本信息 <span>Aes加密在线地址:http://tool.chacuo.net/cryptaes</span></h3>
                          </div>
                        </div>
                        <div class="row justify-content-center">
                          <div class="col-xl-6">
                            <div class="g-input-group">
                              <label for="input-1"><span>*</span> Api接口域名:</label>
                              <div class="input-wrap">
                                <input type="text" id="input-1" placeholder="Api接口域名">
                              </div>
                            </div>
                            <div class="g-input-group">
                              <label for="input-2"><span>*</span> Aes加密模式:</label>
                              <div class="input-wrap">
                                <input type="text" id="input-2" placeholder="Aes加密模式">
                              </div>
                            </div>
                            <div class="g-input-group">
                              <label for="input-3"><span>*</span> Aes填充:</label>
                              <div class="input-wrap">
                                <input type="text" id="input-3" placeholder="Aes填充">
                              </div>
                            </div>
                            <div class="g-input-group">
                              <label for="input-4"><span>*</span> 数据块:</label>
                              <div class="input-wrap">
                                <input type="text" id="input-4" placeholder="数据块">
                              </div>
                            </div>
                            <div class="g-input-group">
                              <label for="input-5"><span>*</span> 密码:</label>
                              <div class="input-wrap">
                                <input type="text" id="input-5" placeholder="密码">
                              </div>
                            </div>
                            <div class="g-input-group">
                              <label for="input-6"><span>*</span> 加密密文:</label>
                              <div class="input-wrap">
                                <input type="text" id="input-6" placeholder="加密密文">
                              </div>
                            </div>
                            <div class="g-button-group">
                              <button class="g-primary">提交</button>
                              <button class="g-danger">重置</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab>
                    <template #title>邮箱配置</template>
                    <div class="tab-body">
                      <div class="global-config-email">
                        <div class="global-config-email-heading mb-30">
                          <a href="#" class="title">mailgun.com</a>
                          <button class="edit" v-b-modal:global-config-edit>编辑</button>
                        </div>
                        <div class="global-config-table">
                          <div class="table-responsive-wrapper">
                            <table class="table table-striped">
                              <tbody>
                              <tr>
                                <td class="key">apikey：</td>
                                <td class="data">live_dasdsadsada1321312321321</td>
                              </tr>
                              <tr>
                                <td class="key">apisecret：</td>
                                <td class="data">kN8gAvBFbCEhzdYpQPFMgUYsJS5oAr3U</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="g-button-group mt-30">
                          <button class="g-primary">提交</button>
                          <button class="g-danger">重置</button>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab>
                    <template #title>支付配置</template>
                    <div class="global-config-payment">
                      <div class="global-config-payment-list mb-50">
                        <div class="global-config-payment-heading mb-20">
                          <div class="payment-heading-left">
                            <p class="title">Payssion</p>
                            <button class="edit" v-b-modal:global-config-edit>编辑</button>
                          </div>
                          <div class="payment-heading-right">
                            <span>是否开启该支付渠道</span>
                            <b-form-checkbox name="check-button" switch button-variant="success" checked="true"/>
                          </div>
                        </div>
                        <div class="global-config-table">
                          <div class="table-responsive-wrapper">
                            <table class="table table-striped">
                              <tbody>
                              <tr>
                                <td class="key">apikey：</td>
                                <td class="data">live_dasdsadsada1321312321321</td>
                              </tr>
                              <tr>
                                <td class="key">apisecret：</td>
                                <td class="data">kN8gAvBFbCEhzdYpQPFMgUYsJS5oAr3U</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="global-config-payment-list mb-50">
                        <div class="global-config-payment-heading mb-20">
                          <div class="payment-heading-left">
                            <p class="title">Payssion</p>
                            <button class="edit" v-b-modal:global-config-edit>编辑</button>
                          </div>
                          <div class="payment-heading-right">
                            <span>是否开启该支付渠道</span>
                            <b-form-checkbox name="check-button" switch button-variant="success" checked="true"/>
                          </div>
                        </div>
                        <div class="global-config-table">
                          <div class="table-responsive-wrapper">
                            <table class="table table-striped">
                              <tbody>
                              <tr>
                                <td class="key">apikey：</td>
                                <td class="data">live_dasdsadsada1321312321321</td>
                              </tr>
                              <tr>
                                <td class="key">apisecret：</td>
                                <td class="data">kN8gAvBFbCEhzdYpQPFMgUYsJS5oAr3U</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="global-config-payment-list mb-50">
                        <div class="global-config-payment-heading mb-20">
                          <div class="payment-heading-left">
                            <p class="title">PayPal</p>
                            <button class="edit" v-b-modal:global-config-edit>编辑</button>
                          </div>
                          <div class="payment-heading-right">
                            <span>是否开启该支付渠道</span>
                            <b-form-checkbox name="check-button" switch button-variant="success" checked="true"/>
                          </div>
                        </div>
                        <div class="global-config-table">
                          <div class="table-responsive-wrapper">
                            <table class="table table-striped">
                              <tbody>
                              <tr>
                                <td class="key">apikey：</td>
                                <td class="data">live_dasdsadsada1321312321321</td>
                              </tr>
                              <tr>
                                <td class="key">apisecret：</td>
                                <td class="data">kN8gAvBFbCEhzdYpQPFMgUYsJS5oAr3U</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="global-config-payment-list mb-50">
                        <div class="global-config-payment-heading mb-20">
                          <div class="payment-heading-left">
                            <p class="title">Paymentwall</p>
                            <button class="edit" v-b-modal:global-config-edit>编辑</button>
                          </div>
                          <div class="payment-heading-right">
                            <span>是否开启该支付渠道</span>
                            <b-form-checkbox name="check-button" switch button-variant="success" checked="true"/>
                          </div>
                        </div>
                        <div class="global-config-table">
                          <div class="table-responsive-wrapper">
                            <table class="table table-striped">
                              <tbody>
                              <tr>
                                <td class="key">apikey：</td>
                                <td class="data">live_dasdsadsada1321312321321</td>
                              </tr>
                              <tr>
                                <td class="key">apisecret：</td>
                                <td class="data">kN8gAvBFbCEhzdYpQPFMgUYsJS5oAr3U</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="global-config-payment-list mb-50">
                        <div class="global-config-payment-heading mb-20">
                          <div class="payment-heading-left">
                            <p class="title">Stripe</p>
                            <button class="edit" v-b-modal:global-config-edit>编辑</button>
                          </div>
                          <div class="payment-heading-right">
                            <span>是否开启该支付渠道</span>
                            <b-form-checkbox name="check-button" switch button-variant="success" checked="true"/>
                          </div>
                        </div>
                        <div class="global-config-table">
                          <div class="table-responsive-wrapper">
                            <table class="table table-striped">
                              <tbody>
                              <tr>
                                <td class="key">apikey：</td>
                                <td class="data">live_dasdsadsada1321312321321</td>
                              </tr>
                              <tr>
                                <td class="key">apisecret：</td>
                                <td class="data">kN8gAvBFbCEhzdYpQPFMgUYsJS5oAr3U</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="g-button-group mt-30">
                        <button class="g-primary">提交</button>
                        <button class="g-danger">重置</button>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template #title>APP配置</template>
                    <div class="global-configuration-form">
                      <div class="row justify-content-center mb-30">
                        <div class="col-xl-10">
                          <h3 class="title"> 基本信息</h3>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-xl-6">
                          <div class="g-input-group">
                            <label for="input-11"><span>*</span> 新用户注册赠送时间（单位:小时）:</label>
                            <div class="input-wrap">
                              <input type="text" id="input-11" placeholder="1h">
                            </div>
                          </div>
                          <div class="g-input-group">
                            <label><span>*</span>iOS商店支付失败是否开启跳转:</label>
                            <div class="input-switch">
                              <b-form-checkbox name="check-button" switch button-variant="success"></b-form-checkbox>
                            </div>
                          </div>
                          <div class="g-input-group">
                            <label><span>*</span>Google Play商店是否开启跳转:</label>
                            <div class="input-switch">
                              <b-form-checkbox name="check-button" switch button-variant="success"></b-form-checkbox>
                            </div>
                          </div>
                          <div class="g-input-group">
                            <label><span>*</span>是否屏蔽中国大陆用户注册:</label>
                            <div class="input-switch">
                              <b-form-checkbox name="check-button" switch button-variant="success"></b-form-checkbox>
                            </div>
                          </div>
                          <div class="g-button-group">
                            <button class="g-primary">提交</button>
                            <button class="g-danger">重置</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- global-configuration-section end -->

    <GlobalConfigEdit/>
  </div>
</template>

<script>
import GlobalConfigEdit from "@/components/Modals/GlobalConfigEdit";

export default {
  name: "GlobalConfiguration",
  components: {GlobalConfigEdit}
}
</script>

<style scoped>

</style>
