<template>
  <transition>
    <b-modal
        id="platform-announcement-details"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="order-message-modal"
        hide-footer
        centered
    >
      <template #modal-header="{ close }">
        <h5>公告详情</h5>
        <b-button variant="modal-x" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="announcement-details">
          <div class="title">如何建立良好的客户关系</div>
          <div class="meta">
            <span>发布人员：<b>官方</b></span>
            <span>发布时间：<b>2020-12-23 22:31</b></span>
            <span>接收人员：<b>全体代理成员</b></span>
          </div>
          <div class="content">
            <p>
              客户关系长期持久，源于让客户感受到价值和重要，是非常有利可图的。满足并保留您的客户群，确保未来获得丰厚的收入和利润。口碑也是强大的力量，忠诚的顾客会把你推荐给别人。通过提供优质的服务，保持联系并建立客户关系，改善和维护客户关系。
            </p>
            <p>
              让顾客感到自己受到了重视从而建立持久的客户关系，能为你带来丰厚的回报。让顾客满意并维持好客户基数能为将来的收入和利润提供重要的保障。口碑也是一个杀手锏，忠诚的顾客会向其他人推荐你。所以你需要通过提供优质的服务，持续的客户沟通及建立良好的客户关系来改善和维持客户关系。
            </p>
            <p>
              把重点放在质量上。赢得忠实客户和商业的最好方式是提供比竞争对手更高质量的产品或服务。其他策略只是为了保留客户已经对您的产品质量印象深刻。不断优化您的产品，确保它们尽可能接近完美。关注质量将导致更多的建议，重复销售和品牌的忠诚度比任何其他战略。因此，首先要确保在这方面的客户保留。
            </p>
            <p>
              重视产品和服务质量。赢得忠诚的顾客和订单的最佳方式就是提供比竞争对手更加优质的产品质量和服务。其他的策略只是帮助维持已经被产品和服务吸引的现有客户。要不断改善服务，确保它们总在向完美靠近。
            </p>
          </div>
        </div>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import toaster from "@/mixins/toaster";

export default {
  name: "PlatformAnnouncementDetails",
  mixins: [toaster],
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    }
  }
}
</script>

<style scoped>

</style>
