<template>
  <!-- dashboard wrapper start -->
  <div class="dashboard-wrapper">
    <!-- sidebar start -->
    <Sidebar :isActive="activeClass" @closeSidebar="activeClass = false"/>
    <!-- sidebar end -->

    <!-- content start -->
    <div class="content-wrapper">

      <!-- inner-header start -->
      <Header @openSidebar="activeClass = true"/>
      <!-- inner-header end -->

      <!-- inner-content start -->
      <div class="inner-content">
        <router-view></router-view>
      </div>
      <!-- inner-content end -->

      <Footer/>

    </div>
    <!-- content end -->

  </div>
  <!-- dashboard wrapper end -->
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "@/layouts/sidebar/Sidebar";
import Header from "@/layouts/header/Header";
import Footer from "@/layouts/footer/Footer";
export default {
  name: "FullLayout",
  data(){
    return {
      activeClass: false,
    }
  },
  components: {
    Footer,
    Header,
    Sidebar

  },
  computed: {
    ...mapState(["LayoutType"]),
  },
  watch: {},
  methods: {

  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">

</style>
