<template>
  <div class="dashboard-wrapper">
    <particles
        id="tsparticles"
        :options="particleOptions"
    />
    <div class="login-wrapper">
      <div class="login-wrap">
        <div class="login-left">
          <div class="login-left-content">
            <h3 class="title">Canada</h3>
            <p>Software business platform</p>
          </div>
        </div>
        <div class="login-right">
          <div class="login-right-content">
            <h3 class="title">
              Admnistrator <span>User Login</span>
            </h3>
            <form @submit.prevent="submit">
              <div class="input-wrap" >
                <div class="icon"><i class="fa-solid fa-circle-user"></i></div>
                <input type="text" placeholder="username" v-model.trim="$v.username.$model">
              </div>
              <div class="input-wrap" >
                <div class="icon"><i class="fa-solid fa-key"></i></div>
                <input type="text" placeholder="password" v-model.trim="$v.password.$model">
              </div>
              <div class="input-auth-wrap" >
                <div class="input-wrap">
                  <div class="icon"><i class="fa-solid fa-shield-check"></i></div>
                  <input type="text" placeholder="Authentication Code" v-model.trim="$v.authCode.$model">
                </div>
                <div class="input-code">
                  <input type="text" placeholder="code" value="k61z" readonly>
                </div>
              </div>
              <div class="input-forget-wrap">
                <div class="input-checkbox">
                  <b-form-checkbox
                      id="checkbox-1"
                      name="checkbox-1"
                      v-model="rememberPassword"
                      unchecked-value="not_accepted"
                  >
                    remember password
                  </b-form-checkbox>
                </div>
                <div class="forget-wrap">
                  <router-link to="/">Forgot password?</router-link>
                </div>
              </div>
              <div class="input-btn-wrap">
                <button type="submit"> Log in</button>
              </div>
              <div class="error-message" v-if="submitStatus === 'ERROR'">
<!--                <b-alert show variant="success">Success Alert</b-alert>-->
<!--                <b-alert show variant="danger">Danger Alert</b-alert>-->
                <b-alert show variant="warning" v-if="!$v.username.required">Username is required!</b-alert>
<!--                <b-alert show variant="warning" v-if="!$v.password.required">Password is required!</b-alert>-->
<!--                <b-alert show variant="warning" v-if="!$v.authCode.required">Auth Code is required!</b-alert>-->
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from "@/layouts/footer/Footer";
import {required} from "vuelidate/lib/validators";

export default {
  name: "AdminLogin",
  components: {Footer},
  data() {
    return {
      username: '',
      password: '',
      authCode: '',
      authCodeData: '',
      submitStatus: '',
      rememberPassword: false,
      particleOptions: {
        fpsLimit: 60,
        particles: {
          number: {
            value: 0,
            density: {
              enable: false,
              value_area: 800
            }
          },
          color: {
            value: "#000"
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000"
            },
            polygon: {
              nb_sides: 5
            },
            image: {
              src: "https://cdn.matteobruni.it/images/particles/github.svg",
              width: 100,
              height: 100
            }
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false
            }
          },
          size: {
            value: 5,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false
            }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#000",
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200
            }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "repulse",
              parallax: {
                enable: false,
                force: 60,
                smooth: 10
              }
            },
            onclick: {
              enable: false,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 0.8,
              speed: 3
            },
            repulse: {
              distance: 200
            },
            push: {
              particles_nb: 4
            },
            remove: {
              particles_nb: 2
            }
          }
        },
        retina_detect: true,
        background: {
          color: "#fff",
          image: "",
          position: "50% 50%",
          repeat: "no-repeat",
          size: "cover"
        },
        absorbers: [
          {
            position: {x: 50, y: 50},
            size: {
              value: 50,
              limit: 150,
              random: {
                enable: true,
                minimumValue: 30
              }
            }
          }
        ],
        emitters: [
          {
            direction: "center-center",
            position: {
              x: 30,
              y: 50,
            },
            particles: {
              shape: {
                type: "circle"
              },
              color: {
                value: "random"
              },
              lineLinked: {
                enable: false,
                distance: 300,
                color: "#000",
                opacity: 0.4,
                width: 1
              },
              opacity: {
                value: 0.3
              },
              rotate: {
                value: 0,
                random: true,
                direction: "counter-clockwise",
                animation: {
                  enable: true,
                  speed: 15,
                  sync: false
                }
              },
              size: {
                value: 10,
                random: {
                  enable: true,
                  minimumValue: 5
                }
              },
              move: {
                speed: 5,
                random: false,
                outMode: "bounce"
              }
            }
          }
        ]
      }
    }
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
    authCode: {
      required,
    },
  },
  methods: {
    submit() {
      console.log('submit!')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.$router.push('/admin/dashboard')
      }
    }
  }
}
</script>

<style scoped>

</style>
