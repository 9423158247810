<template>
  <transition>
    <b-modal
        id="country-add"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        dialog-class="country-add-modal"
        centered
    >
      <template #modal-header="{ close }">
        <h5>新建国家分组</h5>
        <b-button variant="outline-danger" @click="close()">
          <i class="fa-solid fa-xmark"></i>
        </b-button>
      </template>

      <template #default="">
        <div class="country-form-wrap">
          <form action="#">
            <div class="c-input-group">
              <label for="id">序1号:</label>
              <div class="input-wrap">
                <input type="text" id="id" placeholder="1">
              </div>
            </div>
            <div class="c-input-group">
              <label for="name"><span>*</span>国家名称:</label>
              <div class="input-wrap">
                <input type="text" id="name" placeholder="国家">
              </div>
            </div>
            <div class="c-input-group">
              <label for="identifier"><span>*</span>国家标识符:</label>
              <div class="input-wrap">
                <input type="text" id="identifier" placeholder="国家">
              </div>
            </div>
            <div class="c-input-group">
              <label for="c-file"><span>*</span>国家图标ico:</label>
              <div class="input-file">
                <label for="c-file">
                  <i class="fa-solid fa-cloud-arrow-up"></i>
                  <input type="file" id="c-file">
                </label>
              </div>
            </div>
            <div class="c-input-group c-switch-group">
              <label for="c-check-2"><span>*</span>客户端是否展示:</label>
              <div class="input-switch">
                <b-form-checkbox name="check-button" switch id="c-check-2" button-variant="success"></b-form-checkbox>
              </div>
            </div>
          </form>
        </div>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="country-btn-wrap">
          <b-button variant="cancel" @click="handleClose(cancel(),$bvToast.show('country-danger-toast'))">
            取消
          </b-button>

          <b-button variant="theme" @click="ok()">
            确定
          </b-button>
        </div>
      </template>
    </b-modal>
  </transition>
</template>

<script>
export default {
  name: "CountryAdd",
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    resetModal() {
    },
    handleClose($close,$toster){
      $close();
      $toster();
    }
  }
}
</script>

<style scoped>

</style>
