<template>
  <div class="widget-section">

    <!-- product-list-section start -->
    <div class="product-list-section mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="admin-card-wrap">
              <div class="admin-card-heading">
                <h5 class="title">商品套餐列表</h5>
              </div>
              <div class="admin-card-body">
                <div class="product-list-btn">
                  <button class="c-add-btn" v-b-modal:product-add><i class="fa-solid fa-plus"></i> 新建商品
                  </button>
                  <button class="c-cancel-btn"><i class="fa-solid fa-flip-horizontal fa-arrow-rotate-right"></i> 刷新
                  </button>
                </div>
                <div class="product-list-table-wrapper">
                  <div class="table-responsive-wrapper">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <th scope="col" class="check"><input type="checkbox"></th>
                        <th scope="col" class="serial">序号</th>
                        <th scope="col" class="name">商品名称</th>
                        <th scope="col" class="device">登陆设备数</th>
                        <th scope="col" class="o-price">原价（USD）</th>
                        <th scope="col" class="s-price">销售价（USD）</th>
                        <th scope="col" class="discount">折扣</th>
                        <th scope="col" class="status">状态</th>
                        <th scope="col" class="date">创建时间</th>
                        <th scope="col" class="action">操作</th>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">1</td>
                        <td class="name">月付会员</td>
                        <td class="device">3</td>
                        <td colspan="o-price">9.9</td>
                        <td colspan="s-price">9.9</td>
                        <td colspan="discount">100%</td>
                        <td class="status">
                          <div class="status-text warning">● 未上架</div>
                        </td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:product-add><i class="fa-solid fa-plus"></i> 编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:product-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">1</td>
                        <td class="name">月付会员</td>
                        <td class="device">3</td>
                        <td colspan="o-price">9.9</td>
                        <td colspan="s-price">9.9</td>
                        <td colspan="discount">100%</td>
                        <td class="status">
                          <div class="status-text warning">● 未上架</div>
                        </td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:product-add><i class="fa-solid fa-plus"></i> 编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:product-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">1</td>
                        <td class="name">月付会员</td>
                        <td class="device">3</td>
                        <td colspan="o-price">9.9</td>
                        <td colspan="s-price">9.9</td>
                        <td colspan="discount">100%</td>
                        <td class="status">
                          <div class="status-text warning">● 未上架</div>
                        </td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:product-add><i class="fa-solid fa-plus"></i> 编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:product-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">1</td>
                        <td class="name">月付会员</td>
                        <td class="device">3</td>
                        <td colspan="o-price">9.9</td>
                        <td colspan="s-price">9.9</td>
                        <td colspan="discount">100%</td>
                        <td class="status">
                          <div class="status-text warning">● 未上架</div>
                        </td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:product-add><i class="fa-solid fa-plus"></i> 编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:product-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">1</td>
                        <td class="name">月付会员</td>
                        <td class="device">3</td>
                        <td colspan="o-price">9.9</td>
                        <td colspan="s-price">9.9</td>
                        <td colspan="discount">100%</td>
                        <td class="status">
                          <div class="status-text warning">● 未上架</div>
                        </td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:product-add><i class="fa-solid fa-plus"></i> 编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:product-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="check"><input type="checkbox"></td>
                        <td class="serial">1</td>
                        <td class="name">月付会员</td>
                        <td class="device">3</td>
                        <td colspan="o-price">9.9</td>
                        <td colspan="s-price">9.9</td>
                        <td colspan="discount">100%</td>
                        <td class="status">
                          <div class="status-text warning">● 未上架</div>
                        </td>
                        <td class="date">2020-11-25 23:26:08</td>
                        <td class="action">
                          <div class="action-btn-wrap">
                            <a href="#" class="edit-btn" v-b-modal:product-add><i class="fa-solid fa-plus"></i> 编辑</a>
                            <a href="#" class="delete-btn" v-b-modal:product-delete>
                              <i class="fa-regular fa-trash-can"></i> 删除
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="daily-table-pagination mt-30 mb-20">
                    <ul>
                      <li><a href="#"><i class="fa-regular fa-angle-left"></i></a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><span>...</span></li>
                      <li><a href="#">50</a></li>
                      <li><a href="#"><i class="fa-regular fa-angle-right"></i></a></li>
                    </ul>
                    <div class="select-wrap">
                      <span class="icon">
                        <i class="fa-regular fa-angle-down"></i>
                      </span>
                      <select>
                        <option value="10">10 / Page</option>
                        <option value="10">20 / Page</option>
                        <option value="10">30 / Page</option>
                        <option value="10">40 / Page</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- product-list-section end -->

    <ProductAddBox/>
    <ProductDeleteBox/>
  </div>
</template>

<script>
import ProductAddBox from "@/components/Modals/ProductAddBox";
import ProductDeleteBox from "@/components/Modals/ProductDeleteBox";

export default {
  name: "ProductList",
  components: {ProductDeleteBox, ProductAddBox}
}
</script>

<style scoped>

</style>
